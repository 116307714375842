import React from 'react'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import { IconPdf } from '../../images/IconPdf'

interface Props {
  images: any[]
}

export const PhotoUploaded: React.FC<Props> = ({ images }) => {
  const { t } = useTranslation()

  return (
    <div className='flex text-center flex-col justify-center items-center gap-1'>
      <h1 id={'mobile_upload-success_title'} className='text-green-700 font-semibold text-3xl font-serif'>
        {t('Success!')}
      </h1>
      <h2 id={'mobile_upload-success_subtitle'} className='mt-1 text-zinc-700 font-sans text-lg'>
        {parse(t('Your photo/s has/have been added <br /> on your computer.'))}
      </h2>

      <div id={'mobile_upload-success_images'} className='flex flex-wrap justify-center gap-3 w-72 mt-5'>
        {images.map((item, index) => {
          if (index === 5 && images.length > 6)
            return (
              <div className='h-[60px] w-[60px] flex justify-center items-center border-solid border-2 border-gray-800'>
                + {images.length - 5} {t('images')}
              </div>
            )
          if (index > 5) return null
          if (item?.filename?.search('.pdf') !== -1)
            return <IconPdf classname='object-cover h-[60px] w-[60px]' key={item.filename + index} />
          return (
            <img
              className='object-cover h-[60px] w-[60px]'
              src={item.url}
              alt={item.filename}
              key={item.filename + index}
            />
          )
        })}
      </div>
      <p className='text-zinc-700 font-sans text-base mt-24'>{t('You may now close this window.')}</p>
    </div>
  )
}
