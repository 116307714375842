import React from 'react'

export const IconCopy = ({ classname = 'text-zinc-500' }) => {
  return (
    <svg
      className={`${classname}`}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.75 13.5C6.3375 13.5 5.98438 13.3531 5.69063 13.0594C5.39687 12.7656 5.25 12.4125 5.25 12V3C5.25 2.5875 5.39687 2.23438 5.69063 1.94063C5.98438 1.64687 6.3375 1.5 6.75 1.5H13.5C13.9125 1.5 14.2656 1.64687 14.5594 1.94063C14.8531 2.23438 15 2.5875 15 3V12C15 12.4125 14.8531 12.7656 14.5594 13.0594C14.2656 13.3531 13.9125 13.5 13.5 13.5H6.75ZM6.75 12H13.5V3H6.75V12ZM3.75 16.5C3.3375 16.5 2.98438 16.3531 2.69063 16.0594C2.39687 15.7656 2.25 15.4125 2.25 15V5.25C2.25 5.0375 2.32187 4.85938 2.46562 4.71562C2.60938 4.57188 2.7875 4.5 3 4.5C3.2125 4.5 3.39062 4.57188 3.53437 4.71562C3.67813 4.85938 3.75 5.0375 3.75 5.25V15H11.25C11.4625 15 11.6406 15.0719 11.7844 15.2156C11.9281 15.3594 12 15.5375 12 15.75C12 15.9625 11.9281 16.1406 11.7844 16.2844C11.6406 16.4281 11.4625 16.5 11.25 16.5H3.75Z'
        fill='currentColor'
      />
      <path
        d='M8.25 9.75C8.25 9.33579 8.58579 9 9 9H11.25C11.6642 9 12 9.33579 12 9.75C12 10.1642 11.6642 10.5 11.25 10.5H9C8.58579 10.5 8.25 10.1642 8.25 9.75Z'
        fill='currentColor'
      />
    </svg>
  )
}
