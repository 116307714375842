import React from 'react'

export const IconDone = ({ classname = '', white = false, black = false }) => {
  return (
    <svg
      className={classname}
      width='26'
      height='26'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12' cy='12' r='12' fill={white ? '#fff' : black ? '#000' : '#15803D'} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.6864 8.08752C16.9403 7.83411 17.3514 7.83411 17.6052 8.08752C17.8596 8.34154 17.8596 8.75383 17.6052 9.00785L10.785 15.8166C10.7038 15.8976 10.6065 15.9528 10.5034 15.982C10.2588 16.1087 9.95047 16.0695 9.74511 15.8645L6.51554 12.6404C6.2611 12.3864 6.2611 11.9741 6.51554 11.7201C6.76938 11.4666 7.18048 11.4666 7.43432 11.7201L10.241 14.522L16.6864 8.08752Z'
        fill={white ? '#15803D' : '#fff'}
      />
    </svg>
  )
}
