import React, { useEffect, useMemo, useState } from 'react'
import { IOfferItem } from '../../../types/offerTypes'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IStore } from '../../../types/storeTypes'
import { Modal } from '../../modal'
import { IconClose } from '../../../images/IconClose'
import { AddItemForm } from '../../addItemForm'
import { MultiSelect } from '../../multiSelect'
import { currentYear, minWatchYear } from '../../../utils/consts'

interface Props {
  showed: boolean
  closeModal: () => void
  offerItem: IOfferItem | null
  onItemSave: (items: IOfferItem, index: number) => void
  itemIndex: number
  formErrors: string[]
  setFormErrors: (value: string[]) => void
  requiredFields: string[]
  setOpenErrorModal: (value: boolean) => void
}

const defaultItem = {
  type: '',
  offer_made: true,
}

export const EditItemModal: React.FC<Props> = ({
  showed,
  closeModal,
  offerItem,
  onItemSave,
  itemIndex,
  formErrors,
  setFormErrors,
  requiredFields,
  setOpenErrorModal,
}) => {
  const { t } = useTranslation()
  const [currentItem, setCurrentItem] = useState<IOfferItem>(defaultItem)
  const offerDicts = useSelector((store: IStore) => store.offerDicts)
  const { offerDictsData } = offerDicts

  useEffect(() => {
    if (offerItem && showed) setCurrentItem(offerItem)
  }, [offerItem, showed])

  const editField = (name: keyof IOfferItem, value: any) => {
    setFormErrors([])
    if (value === 'watch') {
      const subproduct = subproductsList.find((type) => type.id === 3)
      setCurrentItem({ ...defaultItem, [name]: value, subproduct: subproduct ? subproduct.id : undefined })
    } else {
      setCurrentItem({ ...defaultItem, [name]: value })
    }
  }

  const subproductsList = useMemo(
    () =>
      offerDictsData?.subproducts
        ? offerDictsData.subproducts.map((subproduct) => {
            return { label: subproduct.name, id: subproduct.id, value: subproduct.id }
          })
        : [],
    [offerDictsData]
  )

  const itemTypesList = useMemo(
    () =>
      offerDictsData?.item_types
        ? Object.entries(offerDictsData.item_types)
            .map((type) => {
              return { label: type[1], id: type[0], value: type[0] }
            })
            .sort((a, b) => a.label.localeCompare(b.label))
        : [],
    [offerDictsData]
  )

  const defaultItemType = useMemo(() => {
    return currentItem.type ? itemTypesList.find((listItem) => listItem.value === currentItem.type) : null
  }, [currentItem.type, itemTypesList])

  const onClose = () => {
    setFormErrors([])
    setCurrentItem(defaultItem)
    closeModal()
  }

  const checkForErrors = () => {
    const errors = requiredFields
      .map((field) => {
        if (field === 'additional_description') {
          if (
            ['scrap', 'intrinsic', 'melee', 'other'].includes(currentItem.type) &&
            !currentItem.additional_description
          ) {
            return field
          } else {
            return ''
          }
        } else if (field === 'subproduct') {
          if (
            !['scrap', 'intrinsic', 'melee', 'loose', 'other'].includes(currentItem.type) &&
            !currentItem.subproduct
          ) {
            return field
          } else {
            return ''
          }
        } else if (!currentItem[field as keyof IOfferItem]) return field

        return ''
      })
      .filter((error) => !!error)

    if (currentItem.type === 'watch') {
      if (currentItem.full_year && (currentItem.full_year > currentYear || currentItem.full_year < minWatchYear)) {
        errors.push('full_year')
      }
      if (currentItem.full_year && !currentItem.year_type) {
        errors.push('year_type')
      }
    }

    if (errors.length > 0) {
      setFormErrors(errors)
      setOpenErrorModal(true)
      return false
    }

    return true
  }

  const onSave = () => {
    if (!checkForErrors()) return
    const newItem: IOfferItem = {
      ...currentItem,
      approx_tot_col_stone_ct_wt: currentItem.approx_tot_col_stone_ct_wt
        ? +currentItem.approx_tot_col_stone_ct_wt
        : undefined,
      approx_total_dia_ct_weight: currentItem.approx_total_dia_ct_weight
        ? +currentItem.approx_total_dia_ct_weight
        : undefined,
    }
    onItemSave(newItem, itemIndex)
    setCurrentItem(defaultItem)
    closeModal()
  }

  return (
    <Modal showed={showed} bodyStyle='w-[1080px] !max-w-[95vw] xl:!max-w-[80vw] relative'>
      <div className='absolute top-3 right-5'>
        <button type='button' onClick={onClose}>
          <IconClose />
        </button>
      </div>
      <div className='flex flex-col gap-6 pt-4'>
        <div className='w-full'>
          <div className='w-full md:w-2/3 lg:w-1/2 flex items-center pr-2 md:pr-12'>
            <label
              htmlFor='input-item_edit-type'
              className={`w-1/3 flex font-medium text-[28px] leading-9 items-end capitalize`}
            >
              {t('Edit item')}
              <span className=' text-blue-500 text-xl ml-2'>#{itemIndex + 1}</span>
            </label>
            <MultiSelect
              selectId='select-item-type'
              inputId='input-item_edit-type'
              options={itemTypesList}
              onChange={(value) => editField('type', value)}
              value={defaultItemType}
              containerStyles='w-2/3 h-[38px]'
              controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
              controlDefaultStyles='form-offer-item-select'
              inputStyles='!text-zinc-900'
            />
          </div>
        </div>

        <div className='max-h-[70vh] overflow-y-auto custom_scroll block pr-2'>
          <>
            <AddItemForm
              currentItem={currentItem}
              setCurrentItem={setCurrentItem}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              requiredFields={requiredFields}
            />

            <div className='flex flex-col md:flex-row justify-end items-center mt-6'>
              <button
                type='button'
                className='main_button w-full md:w-[200px]'
                onClick={onSave}
                disabled={!currentItem.type}
              >
                {t('Save')}
              </button>
            </div>
          </>
        </div>
      </div>
    </Modal>
  )
}
