import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types/storeTypes'

const initialState: IStore['showAgreementsWarn'] = {showed: false, nextStep: 0}

export const showAgreementsWarnSlice = createSlice({
  name: 'showAgreementsWarn',
  initialState,
  reducers: {
    setShowAgreementsWarn: (state, action) => {
      return action.payload
    },
    clearShowAgreementsWarn: () => {
      return initialState
    },
  },
})

export const { setShowAgreementsWarn, clearShowAgreementsWarn } = showAgreementsWarnSlice.actions

export default showAgreementsWarnSlice.reducer
