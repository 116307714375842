import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IStore } from '../../../types/storeTypes'
import { useSelector } from 'react-redux'
import { MultiSelect } from '../../multiSelect'
import { SourceSelect } from '../../sourceSelect'

interface Props {
  buyer: number
  setBuyer: (value: number) => void
  client: IStore['client']
  setClientInfo: (value: IStore['client']) => void
  turnover?: number
  setTurnover?: (value: number) => void
  formErrors: string[]
  setFormErrors: (value: string[]) => void
  requiredFields: string[]
}

export const BuyerAndSourceBlock: React.FC<Props> = ({
  buyer,
  setBuyer,
  client,
  setClientInfo,
  formErrors,
  setFormErrors,
  requiredFields,
  turnover,
  setTurnover,
}) => {
  const dicts = useSelector((store: IStore) => store.dicts)
  const { t } = useTranslation()

  const buyersList = useMemo(() => {
    if (!dicts || !dicts.buyers) return []
    return dicts.buyers
      .filter((buyer) => buyer.is_buyer)
      .map((buyer) => {
        return { label: buyer.text, id: buyer.id, value: buyer.id }
      })
  }, [dicts])

  const turnoversList = useMemo(() => {
    if (!dicts || !dicts.turnovers) return []
    return dicts.turnovers
      .filter((turnover) => turnover.is_buyer)
      .map((turnover) => {
        return { label: turnover.text, id: turnover.id, value: turnover.id }
      })
  }, [dicts])

  const defaultBuyer = useMemo(() => {
    return buyer ? buyersList.find((person) => person.value === buyer) : null
  }, [buyersList, buyer])

  const defaultTurnover = useMemo(() => {
    return turnover ? buyersList.find((person) => person.value === turnover) : null
  }, [buyersList, turnover])

  const sourceList = useMemo(() => {
    if (!dicts || !dicts.sources) return []
    return dicts.sources.map((source) => {
      return { label: source.text, id: source.id, value: source.id }
    })
  }, [dicts])

  const defaultSource = useMemo(() => {
    return client.ns_lead_source ? sourceList.find((source) => source.value === client.ns_lead_source) : null
  }, [client, sourceList])

  const editField = (name: string, value: any) => {
    if (name === 'buyer') setBuyer(value)
    if (name === 'turnover' && setTurnover) setTurnover(value)
    else setClientInfo({ ...client, [name]: value })
    setFormErrors(formErrors.filter((error) => error !== name))
  }

  return (
    <div className='form_block h-fit'>
      <div className='form_block_title'>{t('Buyer and Source')}</div>
      <div className='w-full flex items-center'>
        <label
          htmlFor='input-buyer'
          className={`w-1/3 flex form_label ${formErrors.includes('buyer') ? 'error_label' : ''}`}
        >
          {t('Buyer')}
          {requiredFields.includes('buyer') ? <span className='text-red-500'>*</span> : ''}
        </label>
        <MultiSelect
          selectId='select-client-buyer'
          inputId='input-buyer'
          options={buyersList}
          onChange={(value) => editField('buyer', value)}
          value={defaultBuyer}
          containerStyles='w-2/3'
          controlFocusedStyles='form-select form-select-focused'
          controlDefaultStyles={`form-select ${formErrors.includes('buyer') ? 'error_field' : ''}`}
          inputStyles='!text-zinc-900'
        />
      </div>

      <div className='w-full flex items-center'>
        <label
          htmlFor='input-client-ns_lead_source'
          className={`w-1/3 flex form_label ${formErrors.includes('ns_lead_source') ? 'error_label' : ''}`}
        >
          {t('Source')}
          {requiredFields.includes('ns_lead_source') ? <span className='text-red-500'>*</span> : ''}
        </label>
        <SourceSelect
          selectId='select-client-ns_lead_source'
          inputId='input-client-ns_lead_source'
          options={sourceList}
          onChange={(value) => editField('ns_lead_source', value)}
          value={defaultSource}
          containerStyles='w-2/3'
          controlFocusedStyles='form-select form-select-focused'
          controlDefaultStyles={`form-select ${formErrors.includes('ns_lead_source') ? 'error_field' : ''}`}
          inputStyles='!text-zinc-900'
        />
      </div>

      {!!setTurnover && (
        <div className='w-full flex items-center'>
          <label
            htmlFor='input-turnover'
            className={`w-1/3 flex form_label ${formErrors.includes('turnover') ? 'error_label' : ''}`}
          >
            {t('Turnover')}
            {requiredFields.includes('turnover') ? <span className='text-red-500'>*</span> : ''}
          </label>
          <MultiSelect
            selectId='select-client-turnover'
            inputId='input-turnover'
            options={turnoversList}
            onChange={(value) => editField('turnover', value)}
            value={defaultTurnover}
            containerStyles='w-2/3'
            controlFocusedStyles='form-select form-select-focused'
            controlDefaultStyles={`form-select ${formErrors.includes('turnover') ? 'error_field' : ''}`}
            inputStyles='!text-zinc-900'
            isClearable={!requiredFields.includes('turnover')}
          />
        </div>
      )}
    </div>
  )
}
