import React from 'react'
import { Loader } from '../loader'
import { IconPrint } from '../../images/IconPrint'
import { IconEmail } from '../../images/IconEmail'
import { IconDownload } from '../../images/IconDownload'

interface Props {
  title: string
  onClick: () => void
  loading?: boolean
  disabled?: boolean
  classname?: string
  loaderColor?: string
  icon?: string
  iconStyle?: string
}

export const DefaultButton: React.FC<Props> = ({
  onClick,
  loading,
  disabled,
  classname,
  title,
  loaderColor,
  icon,
  iconStyle,
}) => {
  const getIcon = () => {
    switch (icon) {
      case 'print':
        return <IconPrint classname={iconStyle ? iconStyle : undefined} />
      case 'email':
        return <IconEmail classname={iconStyle ? iconStyle : undefined} />
      case 'download':
        return <IconDownload classname={iconStyle ? iconStyle : undefined} />

      default:
        return ''
    }
  }
  return (
    <div className='relative'>
      {loading && (
        <div className=' absolute inset-0 flex justify-center items-center'>
          <Loader className={`h-8 w-8 ml-2 !${loaderColor}`} />
        </div>
      )}
      <button
        type='button'
        className={`${classname ? classname : 'main_button'}`}
        onClick={onClick}
        disabled={disabled || loading}
      >
        {icon ? <span className='flex'>{getIcon()}</span> : null}
        {title}
      </button>
    </div>
  )
}
