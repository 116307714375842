import React from 'react'

export const IconDownload = ({ classname = 'text-[#d4d4d8]' }) => {
  return (
    <svg
      className={`${classname}`}
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.5 12.1813C9.4 12.1813 9.30625 12.1656 9.21875 12.1344C9.13125 12.1031 9.05 12.05 8.975 11.975L6.275 9.275C6.125 9.125 6.05313 8.95 6.05938 8.75C6.06563 8.55 6.1375 8.375 6.275 8.225C6.425 8.075 6.60313 7.99688 6.80938 7.99063C7.01563 7.98438 7.19375 8.05625 7.34375 8.20625L8.75 9.6125V4.25C8.75 4.0375 8.82188 3.85938 8.96563 3.71563C9.10938 3.57188 9.2875 3.5 9.5 3.5C9.7125 3.5 9.89063 3.57188 10.0344 3.71563C10.1781 3.85938 10.25 4.0375 10.25 4.25V9.6125L11.6563 8.20625C11.8063 8.05625 11.9844 7.98438 12.1906 7.99063C12.3969 7.99688 12.575 8.075 12.725 8.225C12.8625 8.375 12.9344 8.55 12.9406 8.75C12.9469 8.95 12.875 9.125 12.725 9.275L10.025 11.975C9.95 12.05 9.86875 12.1031 9.78125 12.1344C9.69375 12.1656 9.6 12.1813 9.5 12.1813ZM5 15.5C4.5875 15.5 4.23438 15.3531 3.94063 15.0594C3.64688 14.7656 3.5 14.4125 3.5 14V12.5C3.5 12.2875 3.57188 12.1094 3.71563 11.9656C3.85938 11.8219 4.0375 11.75 4.25 11.75C4.4625 11.75 4.64063 11.8219 4.78438 11.9656C4.92813 12.1094 5 12.2875 5 12.5V14H14V12.5C14 12.2875 14.0719 12.1094 14.2156 11.9656C14.3594 11.8219 14.5375 11.75 14.75 11.75C14.9625 11.75 15.1406 11.8219 15.2844 11.9656C15.4281 12.1094 15.5 12.2875 15.5 12.5V14C15.5 14.4125 15.3531 14.7656 15.0594 15.0594C14.7656 15.3531 14.4125 15.5 14 15.5H5Z'
        fill='currentColor'
      />
    </svg>
  )
}
