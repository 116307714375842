import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types/storeTypes'

const initialState: IStore['appointmentInfo'] = {
  buyer_id: null,
  intermediate_save: null,
  appointment: null,
  related_persons: [],
}

export const appointmentInfoSlice = createSlice({
  name: 'appointmentInfo',
  initialState,
  reducers: {
    setAppointmentInfo: (state, action) => {
      return action.payload
    },
    setAppointmentBuyer: (state, action) => {
      return {
        ...state,
        buyer_id: action.payload,
      }
    },
    setIntermediateSave: (state, action) => {
      return {
        ...state,
        intermediate_save: action.payload,
      }
    },
    setRelatedPersons: (state, action) => {
      return {
        ...state,
        related_persons: action.payload,
      }
    },
  },
})

export const { setAppointmentInfo, setAppointmentBuyer, setIntermediateSave, setRelatedPersons } =
  appointmentInfoSlice.actions

export default appointmentInfoSlice.reducer
