import React, { useMemo, useState } from 'react'
import { Logo } from '../../images/Logo'
import { LanguageSwitcher } from '../languageSwitcher'
import { useDispatch, useSelector } from 'react-redux'
import { IStore } from '../../types/storeTypes'
import { useLocation } from 'react-router-dom'
import { IconNotes } from '../../images/IconNotes'
import { IconNotesFilled } from '../../images/IconNotesFilled'
import { EditNoteBlock } from '../editNoteBlock'
import { showCloseFormModal } from '../../store/closeFormModalSlice'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import { FORM_SUBMITTED } from '../../utils/consts'

export const Header = () => {
  const location = useLocation()
  const currentStep = useSelector((store: IStore) => store.currentStep)
  const offerInfo = useSelector((store: IStore) => store.offerInfo)
  const sessionLoadingError = useSelector((store: IStore) => store.sessionLoadingError)
  const { internal_notes } = offerInfo
  const [notesOpened, setNotesOpened] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const formSubmitted = sessionStorage.getItem(FORM_SUBMITTED) === 'true'

  const notes = useMemo(() => {
    return internal_notes
  }, [internal_notes])

  return (
    <>
      <header className='py-6 flex justify-center relative'>
        {['/', '/es/', '/en/'].includes(location.pathname) &&
          !sessionLoadingError &&
          currentStep < 5 &&
          !formSubmitted && (
            <div className='absolute left-0 top-7 flex gap-4'>
              <button
                type='button'
                className='p-2 mr-3  text-red-400 hover:text-red-500'
                onClick={() => dispatch(showCloseFormModal())}
              >
                {t('Close form')}
              </button>
            </div>
          )}
        <div className='px-2'>
          <a href='/'>
            <Logo classname='w-[200px] md:w-[300px]' />
          </a>
        </div>

        <div className='absolute right-0 top-9 flex gap-4'>
          {['/', '/es/', '/en/'].includes(location.pathname) && currentStep >= 2 && (
            <>
              <button
                type='button'
                data-tooltip-id='button-internal_notes'
                className='px-2 group'
                onClick={() => setNotesOpened(true)}
              >
                {notes.length > 0 ? (
                  <IconNotesFilled classname='text-zinc-500 group-hover:text-zinc-700' />
                ) : (
                  <IconNotes classname='text-zinc-500 group-hover:text-zinc-700' />
                )}
              </button>
              <Tooltip id='button-internal_notes' className='max-w-[350px] z-10'>
                {t('Internal notes')}
              </Tooltip>
            </>
          )}
          <LanguageSwitcher />
        </div>
      </header>

      <EditNoteBlock opened={notesOpened} onClose={() => setNotesOpened(false)} />
    </>
  )
}
