import React from 'react'

export const IconFromPhone = ({ classname = 'text-zinc-500' }) => {
  return (
    <svg
      className={`w-[18px] h-[18px] ${classname}`}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.25 17.25C4.8375 17.25 4.48438 17.1031 4.19063 16.8094C3.89688 16.5156 3.75 16.1625 3.75 15.75V12C3.75 11.7875 3.82188 11.6094 3.96563 11.4656C4.10938 11.3219 4.2875 11.25 4.5 11.25C4.7125 11.25 4.89063 11.3219 5.03438 11.4656C5.17813 11.6094 5.25 11.7875 5.25 12V13.5H12.75V4.5H5.25V6C5.25 6.2125 5.17813 6.39062 5.03438 6.53438C4.89063 6.67813 4.7125 6.75 4.5 6.75C4.2875 6.75 4.10938 6.67813 3.96563 6.53438C3.82188 6.39062 3.75 6.2125 3.75 6V2.25C3.75 1.8375 3.89688 1.48438 4.19063 1.19063C4.48438 0.896875 4.8375 0.75 5.25 0.75H12.75C13.1625 0.75 13.5156 0.896875 13.8094 1.19063C14.1031 1.48438 14.25 1.8375 14.25 2.25V15.75C14.25 16.1625 14.1031 16.5156 13.8094 16.8094C13.5156 17.1031 13.1625 17.25 12.75 17.25H5.25ZM5.25 15V15.75H12.75V15H5.25ZM7.6125 9.75H2.25C2.0375 9.75 1.85938 9.67813 1.71563 9.53438C1.57188 9.39062 1.5 9.2125 1.5 9C1.5 8.7875 1.57188 8.60938 1.71563 8.46563C1.85938 8.32188 2.0375 8.25 2.25 8.25H7.6125L6.95625 7.575C6.81875 7.4375 6.75 7.26563 6.75 7.05938C6.75 6.85313 6.825 6.675 6.975 6.525C7.1125 6.3875 7.2875 6.31875 7.5 6.31875C7.7125 6.31875 7.8875 6.3875 8.025 6.525L9.975 8.475C10.125 8.625 10.2 8.8 10.2 9C10.2 9.2 10.125 9.375 9.975 9.525L8.025 11.475C7.8875 11.6125 7.71563 11.6844 7.50938 11.6906C7.30313 11.6969 7.125 11.625 6.975 11.475C6.8375 11.3375 6.76562 11.1656 6.75938 10.9594C6.75313 10.7531 6.81875 10.575 6.95625 10.425L7.6125 9.75ZM5.25 3H12.75V2.25H5.25V3Z'
        fill='currentColor'
      />
    </svg>
  )
}
