import React, { useMemo } from 'react'
import { Modal } from '../../modal'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import { IconEmailRead } from '../../../images/IconEmailRead'

interface Props {
  text: string
  title?: string
  opened: boolean
  buttonText?: string
  onClose: () => void
  type: 'message' | 'error' | 'warn' | 'email'
  errors?: string[]
}

export const MessageModal: React.FC<Props> = ({ title, text, opened, buttonText, onClose, type, errors }) => {
  const { t } = useTranslation()
  const textColor = useMemo(() => {
    switch (type) {
      case 'error':
        return 'text-red-600'

      case 'warn':
        return 'text-amber-500'

      default:
        return 'text-zinc-800'
    }
  }, [type])

  const onCloseClick = () => {
    onClose()
    const body = document.getElementById('page_body')
    if (body) body.style.overflow = 'auto'
  }
  return (
    <Modal showed={opened}>
      <div className='flex flex-col justify-center items-center space-y-12'>
        {title ? (
          <div className={`text-[28px] leading-9 font-medium font-sans text-center ${textColor}`}>
            {parse(t(title))}
          </div>
        ) : null}
        {type === 'email' ? (
          <div className=''>
            <IconEmailRead />
          </div>
        ) : (
          <div className={`text-xl`}>
            {parse(t(text))} {errors && errors.length > 0 ? `(${errors?.join(', ')})` : ''}
          </div>
        )}
        <button type='button' className='main_button' onClick={onCloseClick}>
          {buttonText || t('Ok')}
        </button>
      </div>
    </Modal>
  )
}
