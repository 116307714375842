import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as filestack from 'filestack-js'
import { IconLoadImage } from '../../../images/IconLoadImage'
import { IconLoadFromMobile } from '../../../images/IconLoadFromMobile'
import { fileStackApi, fileStackSource, serverMode } from '../../../utils/consts'
import { IImage, IStore } from '../../../types/storeTypes'
import { LoadImagesViaMobileQrCode } from '../../modals/QRCodeModals/loadImagesViaMobileQrCode'
import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux'

interface Props {
  blockStyle: string
  desktopButtonText: string
  desktopButtonStyle: string
  showQRCodeButton: boolean
  qrcodeButtonText?: string
  qrcodeButtonStyle?: string
  callback: (image: IImage[]) => void
  maxFiles?: number
}

export const PhotoUploader: React.FC<Props> = ({
  blockStyle,
  desktopButtonText,
  desktopButtonStyle,
  showQRCodeButton,
  qrcodeButtonStyle,
  qrcodeButtonText,
  callback,
  maxFiles = 1,
}) => {
  const { t, i18n } = useTranslation()

  const [showQrCode, setShowQrCode] = useState(false)
  const [token, setToken] = useState<string>(uuidv4())

  const client = filestack.init(fileStackApi)
  const photoViaMobile = useSelector((store: IStore) => store.photoViaMobile)

  const email = ''
  const pickerOptions = {
    accept: ['image/*', 'image/heic', 'image/heif', '.pdf'],
    uploadInBackground: false,
    storeTo: {
      location: 's3',
      container: 'mondiamo-media',
      region: 'us-east-1',
      access: 'private',
      path: `/${serverMode}/appointments/${filestack.md5(email || 'undefined')}/`,
    },
    maxFiles: maxFiles,
    fromSources: fileStackSource,
    /// @ts-ignore
    onUploadDone: ({ filesUploaded }) => {
      const images = filesUploaded.map((file: any) => {
        const formattedUrl =
          file.mimetype.includes('heic') || file.mimetype.includes('heif')
            ? `https://cdn.filestackcontent.com/output=format:jpg/${file.handle}`
            : file.url
        return {
          name: file.filename,
          content: '',
          url: formattedUrl,
          mimetype: file.mimetype,
        }
      })
      callback(images)
    },
  }
  const onPick = () => client.picker(pickerOptions).open()

  useEffect(() => {
    if (!showQrCode) setToken(uuidv4())
  }, [showQrCode])

  useEffect(() => {
    if (photoViaMobile[token]) {
      const images = photoViaMobile[token].map((file: any, index) => {
        const formattedUrl =
          file.mimetype.includes('heic') || file.mimetype.includes('heif')
            ? `https://cdn.filestackcontent.com/output=format:jpg/${file.handle}`
            : file.url
        return {
          name: `Photo ${index + 1}`,
          content: '',
          url: formattedUrl,
          mimetype: file.mimetype,
        }
      })
      callback(images)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoViaMobile[token]])

  return (
    <div className={`${blockStyle}`}>
      <button className={`${desktopButtonStyle}`} type='button' onClick={onPick}>
        <IconLoadImage />
        {t(desktopButtonText || 'Choose file')}
      </button>
      {showQRCodeButton ? (
        <button className={`${qrcodeButtonStyle}`} type='button' onClick={() => setShowQrCode(true)}>
          <IconLoadFromMobile />
          {t(qrcodeButtonText || 'From mobile')}
        </button>
      ) : null}
      <LoadImagesViaMobileQrCode
        visible={showQrCode}
        setVisible={setShowQrCode}
        qrCodeValue={`${window.location.origin}/${i18n.language}/add-photo/?max=${maxFiles}&country=${i18n.language}&upload_token=${token}`}
        locale='en'
        token={token}
      />
    </div>
  )
}
