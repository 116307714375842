import { createSlice } from '@reduxjs/toolkit'

const initialState: boolean = false

export const sessionLoadingErrorSlice = createSlice({
  name: 'sessionLoadingError',
  initialState,
  reducers: {
    setSessionLoadingError: (state, action) => action.payload,
  },
})

export const { setSessionLoadingError } = sessionLoadingErrorSlice.actions

export default sessionLoadingErrorSlice.reducer