import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectArrow } from '../../images/SelectArrow'

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation()
  const [opened, setOpened] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const onLanguageChange = (language: string) => {
    i18n.changeLanguage(language)
    setOpened(false)
  }

  const handleClickOutside = (e: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Element)) {
      setOpened(false)
    }
  }

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside)
    return () => window.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <div className='relative' ref={dropdownRef}>
      <button
        type='button'
        onClick={() => setOpened(!opened)}
        className='uppercase font-sans text-base flex pl-[6px] text-zinc-800 w-12'
      >
        {i18n.language}
        <span className={`${opened ? 'rotate-180' : ''}`}>
          <SelectArrow />
        </span>
      </button>
      <div className={`flex flex-col absolute top-7 left-0 bg-white w-12 ${!opened && 'hidden'}`}>
        {i18n.languages.map((language) => {
          return (
            <button
              type='button'
              key={language}
              className={`uppercase font-sans text-base flex h-6 pl-[6px] ${i18n.language === language ? ' bg-zinc-200' : ''} hover:bg-zinc-300`}
              onClick={() => onLanguageChange(language)}
            >
              {language}
            </button>
          )
        })}
      </div>
    </div>
  )
}
