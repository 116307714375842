import React from 'react'
import { Tooltip } from 'react-tooltip'

interface Props {
  id: string
  children: React.ReactNode
  containerStyle?: string
  buttonStyle?: string
  tooltipStyle?: string
  clickable?: boolean
  buttonIcon?: string
  place?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end'
}

export const DefaultTooltip: React.FC<Props> = ({
  id,
  containerStyle,
  buttonStyle,
  clickable = false,
  tooltipStyle,
  children,
  place = 'top',
  buttonIcon = '?',
}) => {
  return (
    <span className={`flex items-start justify-start h-full ml-2 cursor-help ${containerStyle}`}>
      <div
        data-tooltip-id={id}
        className={`bg-blue-400 text-white font-semibold rounded-full h-4 w-4 flex justify-center text-center text-xs items-center ${buttonStyle}`}
      >
        {buttonIcon}
      </div>

      <Tooltip
        id={id}
        className={tooltipStyle ? tooltipStyle : 'max-w-[350px] z-10'}
        clickable={clickable}
        place={place}
      >
        {children}
      </Tooltip>
    </span>
  )
}
