import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types/storeTypes'

const initialState: IStore['client'] = {
  address1: '',
  address2: '',
  birthday: '',
  country: '',
  email: '',
  first_name: '',
  full_name: '',
  gender: '',
  id: 0,
  id_number: '',
  id_type: '',
  last_name: '',
  municipality: '',
  ns_lead_source: 0,
  phone_no: '',
  postal_code: '',
  province: '',
  height_type: '',
  height_1: 0,
  height_2: 0,
}

export const clientInfoSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientInfo: (state, action) => {
      return action.payload
    },
    updateClientInfo: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const { setClientInfo, updateClientInfo } = clientInfoSlice.actions

export default clientInfoSlice.reducer
