import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IPaymentInfo, IStore } from '../../../types/storeTypes'
import { useSelector } from 'react-redux'
import { getCurrency } from '../../../utils/getCurrency'

interface Props {
  paymentData: IPaymentInfo['payment_data'] | null
  client: IStore['client']
  setPaymentData: (value: IPaymentInfo['payment_data']) => void
}

export const GiftCardInfoBlock: React.FC<Props> = ({ paymentData, client, setPaymentData }) => {
  const { t } = useTranslation()
  const dicts = useSelector((store: IStore) => store.dicts)
  const paymentInfo = useSelector((store: IStore) => store.paymentInfo)

  const onEditField = (name: string, value: any) => {
    setPaymentData({ ...paymentData, [name]: value })
  }

  const giftCardPercent = useMemo(() => {
    return paymentData?.gift_card && dicts.gift_card_percents ? dicts.gift_card_percents[paymentData.gift_card] : 0
  }, [paymentData?.gift_card, dicts.gift_card_percents])

  return (
    <div className='form_block'>
      <div className='form_block_title'>{t('Payment Information')}</div>
      <div className='w-full flex items-center'>
        <label htmlFor='input-gift_card_data-gift_card' className='w-1/2 flex form_label'>
          {t('Type')}
        </label>
        <input
          type='text'
          name='gift_card'
          id='input-gift_card_data-gift_card'
          value={paymentData?.gift_card}
          onChange={(e) => onEditField('gift_card', e.target.value)}
          className='form-input w-1/2'
          autoComplete='false'
          disabled
        />
      </div>

      <div className='w-full flex items-center'>
        <label htmlFor='input-gift_card_data-full_name' className='w-1/2 flex form_label'>
          {t('Name')}
        </label>
        <input
          type='text'
          name='full_name'
          id='input-gift_card_data-full_name'
          value={client.full_name}
          className='form-input w-1/2'
          autoComplete='false'
          disabled
        />
      </div>

      <div className='w-full flex items-center'>
        <label htmlFor='input-gift_card_data-email' className='w-1/2 flex form_label'>
          {t('Email')}
        </label>
        <input
          type='email'
          name='email'
          id='input-gift_card_data-email'
          value={client.email}
          className='form-input w-1/2'
          autoComplete='false'
          disabled
        />
      </div>

      {paymentInfo.amounts && (
        <>
          <div className='w-full flex items-center justify-between h-[42px]'>
            <div className='text-base text-zinc-700'>
              {t('Gift Card Amount')} (+{giftCardPercent}%)
            </div>

            <div className='font-medium text-base text-zinc-900'>
              {getCurrency(
                Math.ceil(paymentInfo.amounts.purchase * (1 + giftCardPercent / 100)),
                paymentInfo.amounts.currency
              )}
            </div>
          </div>

          <div className='w-full flex items-center justify-between h-[42px]'>
            <div className='text-base text-zinc-700'>{t('Trade in amount')}</div>

            <div className='font-medium text-base text-zinc-900'>
              {getCurrency(paymentInfo.amounts.purchase, paymentInfo.amounts.currency)}
            </div>
          </div>
        </>
      )}

      <div className='text-base text-orange-500 mt-2'>
        {t('This will automatically send an email to CIRCA Sales Team and CIRCA Accounting')}
      </div>
    </div>
  )
}
