import { useState } from 'react'
import { useFormSession } from './useFormSession'
import { useDispatch } from 'react-redux'
import { callApi } from '../utils/callApi'
import { ApiRoutes } from '../utils/apiRoutes'
import { showGlobalPopup } from '../store/globalPopupSlice'

export interface IUploadGDPRData {
  first_name: string
  last_name: string
  email: string
  phone: string
  id_number: string
  source_id: number
  signature: string
  send_marketing: boolean
  country: string
  state: string
  city: string
  address1: string
  address2: string
  postal_code: string
}

export const useGDPR = () => {
  const dispatch = useDispatch()
  const { startCheckSession, stopCheckSession } = useFormSession()
  const [uploadGDPRLoading, setUploadGDPRLoading] = useState(false)
  const [deleteGDPRLoading, setDeleteGDPRLoading] = useState(false)

  const uploadGDPR = async (data: IUploadGDPRData, cb?: (value: string) => void) => {
    stopCheckSession()
    setUploadGDPRLoading(true)
    try {
      const resp = await callApi({
        method: 'POST',
        url: `${ApiRoutes.gdpr}`,
        data,
      })

      if (resp && resp.data && resp.data.success) {
        const url = resp.data.url
        if (cb) cb(url)
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setUploadGDPRLoading(false)
      startCheckSession()
    }
  }

  const deleteGDPR = async (cb?: () => void) => {
    stopCheckSession()
    setDeleteGDPRLoading(true)
    try {
      const resp = await callApi({
        method: 'DELETE',
        url: `${ApiRoutes.gdpr}`,
      })
      if (resp && resp.data && resp.data.success) {
        if (cb) cb()
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setDeleteGDPRLoading(false)
      startCheckSession()
    }
  }

  return {
    uploadGDPR,
    deleteGDPR,
    uploadGDPRLoading,
    deleteGDPRLoading,
  }
}
