import { callApi } from '../utils/callApi'
import { ApiRoutes } from '../utils/apiRoutes'
import { useState } from 'react'

export const usePayments = () => {
  const [loading, setLoading] = useState(false)
  const [plaidData, setPlaidData] = useState<any>(null)

  const getPlaidData = async (token: string, name: string) => {
    setLoading(true)
    try {
      const res = await callApi({
        method: 'GET',
        url: ApiRoutes.plaidData,
        params: { public_token: token },
      })

      const data = {
        ...res.data,
        publicToken: token,
        name,
      }

      setPlaidData(data)
      return data
    } catch (e: any) {
      console.error('usePayments - getPlaidData ->', e)
      // throw new Error(e)
    } finally {
      setLoading(false)
    }
  }

  return {
    getPlaidData,
    loading,
    plaidData,
  }
}
