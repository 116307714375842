import React from 'react'

export const IconPrint = ({ classname = 'text-zinc-500' }) => {
  return (
    <svg
      className={`${classname}`}
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.5 15.75C6.0875 15.75 5.73438 15.6031 5.44063 15.3094C5.14688 15.0156 5 14.6625 5 14.25V12.75H3.5C3.0875 12.75 2.73438 12.6031 2.44063 12.3094C2.14687 12.0156 2 11.6625 2 11.25V8.25C2 7.6125 2.21875 7.07812 2.65625 6.64688C3.09375 6.21563 3.625 6 4.25 6H14.75C15.3875 6 15.9219 6.21563 16.3531 6.64688C16.7844 7.07812 17 7.6125 17 8.25V11.25C17 11.6625 16.8531 12.0156 16.5594 12.3094C16.2656 12.6031 15.9125 12.75 15.5 12.75H14V14.25C14 14.6625 13.8531 15.0156 13.5594 15.3094C13.2656 15.6031 12.9125 15.75 12.5 15.75H6.5ZM3.5 11.25H5C5 10.8375 5.14688 10.4844 5.44063 10.1906C5.73438 9.89688 6.0875 9.75 6.5 9.75H12.5C12.9125 9.75 13.2656 9.89688 13.5594 10.1906C13.8531 10.4844 14 10.8375 14 11.25H15.5V8.25C15.5 8.0375 15.4281 7.85938 15.2844 7.71563C15.1406 7.57188 14.9625 7.5 14.75 7.5H4.25C4.0375 7.5 3.85938 7.57188 3.71563 7.71563C3.57188 7.85938 3.5 8.0375 3.5 8.25V11.25ZM12.5 6V3.75H6.5V6H5V3.75C5 3.3375 5.14688 2.98438 5.44063 2.69063C5.73438 2.39687 6.0875 2.25 6.5 2.25H12.5C12.9125 2.25 13.2656 2.39687 13.5594 2.69063C13.8531 2.98438 14 3.3375 14 3.75V6H12.5ZM14 9.375C14.2125 9.375 14.3906 9.30313 14.5344 9.15938C14.6781 9.01563 14.75 8.8375 14.75 8.625C14.75 8.4125 14.6781 8.23438 14.5344 8.09063C14.3906 7.94688 14.2125 7.875 14 7.875C13.7875 7.875 13.6094 7.94688 13.4656 8.09063C13.3219 8.23438 13.25 8.4125 13.25 8.625C13.25 8.8375 13.3219 9.01563 13.4656 9.15938C13.6094 9.30313 13.7875 9.375 14 9.375ZM12.5 14.25V11.25H6.5V14.25H12.5Z'
        fill='currentColor'
      />
    </svg>
  )
}
