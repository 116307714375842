import React from 'react'

export const IconDelete = ({ classname = 'text-zinc-500' }) => {
  return (
    <svg
      className={`${classname}`}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.77694 16.5C4.32953 16.5 3.94653 16.3427 3.62793 16.0282C3.30931 15.7138 3.15 15.3357 3.15 14.8941V3.61842H2.925C2.73375 3.61842 2.57344 3.55454 2.44406 3.42678C2.31469 3.29903 2.25 3.14072 2.25 2.95185C2.25 2.76299 2.31469 2.60478 2.44406 2.47722C2.57344 2.34965 2.73375 2.28587 2.925 2.28587H6.29999C6.29999 2.06833 6.37759 1.88297 6.53278 1.72978C6.68797 1.57659 6.87575 1.5 7.09613 1.5H10.9039C11.1242 1.5 11.312 1.57659 11.4672 1.72978C11.6224 1.88297 11.7 2.06833 11.7 2.28587H15.075C15.2662 2.28587 15.4266 2.34975 15.5559 2.47751C15.6853 2.60528 15.75 2.76359 15.75 2.95244C15.75 3.14132 15.6853 3.29953 15.5559 3.42709C15.4266 3.55465 15.2662 3.61842 15.075 3.61842H14.85V14.8941C14.85 15.3357 14.6907 15.7138 14.3721 16.0282C14.0535 16.3427 13.6705 16.5 13.2231 16.5H4.77694ZM13.5 3.61842H4.49998V14.8941C4.49998 14.9738 4.52595 15.0393 4.57788 15.0906C4.62981 15.1418 4.69616 15.1674 4.77694 15.1674H13.2231C13.3038 15.1674 13.3702 15.1418 13.4221 15.0906C13.474 15.0393 13.5 14.9738 13.5 14.8941V3.61842ZM7.33877 13.3907C7.5301 13.3907 7.69037 13.3268 7.8196 13.1991C7.94884 13.0714 8.01346 12.9132 8.01346 12.7244V6.06146C8.01346 5.8727 7.94874 5.71446 7.81931 5.58675C7.68987 5.45905 7.52949 5.3952 7.33817 5.3952C7.14683 5.3952 6.98654 5.45905 6.85732 5.58675C6.72809 5.71446 6.66348 5.8727 6.66348 6.06146V12.7244C6.66348 12.9132 6.7282 13.0714 6.85763 13.1991C6.98705 13.3268 7.14743 13.3907 7.33877 13.3907ZM10.6618 13.3907C10.8532 13.3907 11.0135 13.3268 11.1427 13.1991C11.2719 13.0714 11.3365 12.9132 11.3365 12.7244V6.06146C11.3365 5.8727 11.2718 5.71446 11.1424 5.58675C11.0129 5.45905 10.8526 5.3952 10.6612 5.3952C10.4699 5.3952 10.3096 5.45905 10.1804 5.58675C10.0512 5.71446 9.98654 5.8727 9.98654 6.06146V12.7244C9.98654 12.9132 10.0513 13.0714 10.1807 13.1991C10.3101 13.3268 10.4705 13.3907 10.6618 13.3907Z'
        fill='currentColor'
      />
    </svg>
  )
}
