import React from 'react'

export const IconEmailRead = () => {
  return (
    <svg width='140' height='140' viewBox='0 0 140 140' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M24.1294 33.738L69.8202 63.7404L115.623 33.738H24.1294ZM25.6607 109.846C23.5206 109.846 21.6534 109.064 20.0592 107.498C18.465 105.933 17.668 104.051 17.668 101.853V37.3276C17.668 35.1876 18.465 33.3205 20.0592 31.7263C21.6534 30.1321 23.5206 29.335 25.6607 29.335H114.125C116.323 29.335 118.205 30.1321 119.77 31.7263C121.336 33.3205 122.118 35.1876 122.118 37.3276V74.5993C122.118 75.2612 121.9 75.8043 121.463 76.2287C121.026 76.6532 120.522 76.8654 119.95 76.8654C119.279 76.8654 118.739 76.6532 118.329 76.2287C117.92 75.8043 117.715 75.2612 117.715 74.5993V37.2212L72.2086 67.3776C71.7943 67.6078 70.9876 67.7827 69.7885 67.9023C69.4843 67.9023 69.1247 67.8939 68.7096 67.877C68.2946 67.8601 67.9543 67.6985 67.6888 67.3919L22.071 37.2212V101.853C22.071 102.9 22.4075 103.76 23.0806 104.433C23.7536 105.107 24.6137 105.443 25.6607 105.443H59.0846C59.6476 105.443 60.1445 105.661 60.5753 106.098C61.0062 106.535 61.2217 107.04 61.2217 107.612C61.2217 108.282 61.0062 108.823 60.5753 109.232C60.1445 109.641 59.6476 109.846 59.0846 109.846H25.6607ZM90.0013 117.553L122.788 84.6539C123.116 84.3264 123.595 84.1682 124.227 84.1795C124.859 84.1907 125.426 84.3851 125.927 84.7629C126.342 85.2265 126.549 85.7799 126.549 86.423C126.549 87.0662 126.342 87.5954 125.927 88.0104L92.7664 121.059C91.969 121.896 91.0387 122.315 89.9753 122.315C88.912 122.315 88.0045 121.896 87.2529 121.059L71.4773 105.395C71.0681 105.072 70.869 104.57 70.8802 103.889C70.8915 103.209 71.1102 102.618 71.5365 102.117C71.9516 101.777 72.4807 101.607 73.1239 101.607C73.7671 101.607 74.3365 101.774 74.832 102.109L90.0013 117.553Z'
        fill='#15803D'
      />
    </svg>
  )
}
