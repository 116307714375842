import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types/storeTypes'

const initialState: IStore['confirmationData'] = {
  client_name: '',
  consignment_agreement: null,
  link: '',
  offer_form: null,
  purchase_form: null,
  offer_id: 0,
  bulk_photo: '',
  gdpr_url: '',
}

export const confirmationDataSlice = createSlice({
  name: 'confirmationData',
  initialState,
  reducers: {
    setConfirmationData: (state, action) => {
      return action.payload
    },
  },
})

export const { setConfirmationData } = confirmationDataSlice.actions

export default confirmationDataSlice.reducer
