
import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types/storeTypes'

const initialState: IStore['currentStep'] = 1

export const currentStepSlice = createSlice({
  name: 'currentStep',
  initialState,
  reducers: {
    setCurrentStep: (state, action) => {
      return action.payload
    },
  },
})

export const { setCurrentStep } = currentStepSlice.actions

export default currentStepSlice.reducer