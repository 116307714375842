import React from 'react'
import { Modal } from '../../modal'
import { IconClose } from '../../../images/IconClose'
import { useTranslation } from 'react-i18next'
import { DefaultButton } from '../../defaultButton'

interface Props {
  showed: boolean
  closeModal: () => void
  note: string
  setNote: (value: string) => void
}

export const DecisionNotesModal: React.FC<Props> = ({ showed, closeModal, note, setNote }) => {
  const { t } = useTranslation()

  const onClose = () => {
    closeModal()
  }

  const onCancel = () => {
    closeModal()
  }

  const onSave = async () => {
    closeModal()
  }

  const onNoteEdit = (text: string) => {
    setNote(text)
    sessionStorage.setItem('decisionNotes', text)
  }

  return (
    <Modal showed={showed} bodyStyle='w-[800px] !max-w-[95vw] xl:!max-w-[80vw] relative'>
      <div className='absolute top-3 right-5'>
        <button type='button' onClick={onClose}>
          <IconClose />
        </button>
      </div>

      <div className='flex flex-col gap-6'>
        <div className='w-full flex items-end text-end pr-2 md:pr-12 text-zinc-800 text-[28px] leading-9 font-medium'>
          {t('Add note')}
          <span className=' text-red-500 pl-1 text-2xl'>({t('Visible to client')})</span>
        </div>

        <textarea
          className='form-textarea !w-full'
          placeholder={t('Enter text')}
          name='textarea-add_decision_note'
          id='textarea-add_decision_note'
          cols={30}
          rows={10}
          value={note}
          onChange={(e) => onNoteEdit(e.target.value)}
        />

        <div className='flex flex-col justify-between md:flex-row gap-4 md:gap-8 w-full md:w-auto'>
          <DefaultButton
            title={t('Cancel')}
            classname='text_button !text-red-400 w-full md:w-[200px]'
            onClick={onCancel}
          />
          <DefaultButton title={t('Save')} classname='main_button w-full md:w-[200px]' onClick={onSave} />
        </div>
      </div>
    </Modal>
  )
}
