import React, { useMemo, useState } from 'react'
import { IStore } from '../../../types/storeTypes'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { parsePhoneNumber } from 'libphonenumber-js'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { MultiSelect } from '../../multiSelect'
import { DefaultButton } from '../../defaultButton'
import { GDPRModal } from '../../modals/GDPRModal'
import { IconClose } from '../../../images/IconClose'
import { useGDPR } from '../../../hooks/useGDPR'
import { IconPdf } from '../../../images/IconPdf'
import { AddRelatedPersonModal } from '../../modals/addRelatedPersonModal'

interface Props {
  client: IStore['client']
  setClientInfo: (value: IStore['client']) => void
  formErrors: string[]
  setFormErrors: (value: string[]) => void
  requiredFields: string[]
  isModal?: boolean
}

export const ClientInfoBlock: React.FC<Props> = ({
  client,
  setClientInfo,
  formErrors,
  setFormErrors,
  requiredFields,
  isModal = false,
}) => {
  const dicts = useSelector((store: IStore) => store.dicts)
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const currentStep = useSelector((store: IStore) => store.currentStep)
  const { t } = useTranslation()
  const [showGDPRmodal, setShowGDPRmodal] = useState(false)
  const [showRelatedPersonModal, setShowRelatedPersonModal] = useState(false)
  const { deleteGDPR, deleteGDPRLoading } = useGDPR()

  const phoneCountry = useMemo(() => {
    const phone = client.phone_no ? client.phone_no : ''
    if (!phone)
      return appointmentInfo && !!appointmentInfo.appointment && appointmentInfo.appointment?.office.country
        ? appointmentInfo.appointment.office.country.toLowerCase()
        : 'us'
    const parsedPhoneNumber = parsePhoneNumber(phone, 'US')
    if (parsedPhoneNumber) {
      const regionCode = parsedPhoneNumber.country
      return regionCode?.toLowerCase()
    } else return 'us'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const countriesList = useMemo(() => {
    if (!dicts || !dicts.countries) return []
    const list = Object.entries(dicts.countries)
    return list.map((country) => {
      return { label: country[1], id: country[0], value: country[0] }
    })
  }, [dicts])

  const defaultCountry = useMemo(() => {
    return client.country
      ? countriesList.find((country) => country.value === client.country)
      : !!appointmentInfo && !!appointmentInfo.appointment && appointmentInfo.appointment?.office.country
        ? countriesList.find((country) => country.value === appointmentInfo.appointment?.office.country)
        : null
  }, [client.country, countriesList, appointmentInfo])

  const statesList = useMemo(() => {
    if (!dicts || !dicts.states || !defaultCountry) return []
    const index = defaultCountry.value === 'US' ? 'US' : 'CA'
    const list = Object.entries(dicts.states[index])
    return list.map((state) => {
      return { label: state[1], id: state[0], value: state[1] }
    })
  }, [dicts, defaultCountry])

  const defaultState = useMemo(() => {
    return client.province
      ? statesList.find(
          (state) => state.value === client.province || state.label.toLowerCase() === client.province.toLowerCase()
        )
      : null
  }, [client.province, statesList])

  const editField = (name: string, value: string | number) => {
    if (name === 'email' || name === 'phone_no') {
      setFormErrors(formErrors.filter((error) => error !== 'email' && error !== 'phone_no'))
      setClientInfo({ ...client, [name]: value })
    } else if (name === 'country') {
      if (value === 'HK') {
        setFormErrors(formErrors.filter((error) => error !== 'country' && error !== 'postal_code'))
        setClientInfo({ ...client, country: value as string, postal_code: '', province: '' })
      } else if (!['CA', 'US'].includes(value as string)) {
        setFormErrors(formErrors.filter((error) => error !== 'country' && error !== 'province'))
        setClientInfo({ ...client, country: value as string, province: '' })
      } else {
        setFormErrors(formErrors.filter((error) => error !== name))
        setClientInfo({ ...client, country: value as string, province: '' })
      }
    } else {
      setClientInfo({ ...client, [name]: value })
      setFormErrors(formErrors.filter((error) => error !== name))
    }
  }

  const onGDPROpen = () => {
    setShowGDPRmodal(true)
  }

  const onGDPRFileLoaded = (url: string) => {
    setClientInfo({ ...client, gdpr_url: url })
    setFormErrors(formErrors.filter((error) => error !== 'GDPR'))
  }

  const onGDPRFileDelete = async () => {
    await deleteGDPR(() => {
      setClientInfo({ ...client, gdpr_url: undefined })
    })
  }

  const isSpain = useMemo(() => {
    return appointmentInfo.appointment &&
      appointmentInfo.appointment.office.country &&
      appointmentInfo.appointment.office.country.toLowerCase() === 'es'
      ? true
      : false
  }, [appointmentInfo.appointment])

  const isRelatedClientEdit = useMemo(() => {
    return !!appointmentInfo.intermediate_save?.related_person
  }, [appointmentInfo.intermediate_save])

  return (
    <div className='form_block'>
      {!isModal && (
        <div className='flex justify-between items-center gap-2'>
          <div className='form_block_title'>{t('Client Details')}</div>
          {currentStep === 1 && isSpain ? (
            <button
              type='button'
              className='text-blue-500 hover:text-blue-700 hover:underline'
              onClick={() => setShowRelatedPersonModal(true)}
            >
              {isRelatedClientEdit ? t('Second person Details') : t('Add related person')}
            </button>
          ) : null}
        </div>
      )}
      <div className='w-full flex items-center'>
        <label
          htmlFor='input-client-first_name'
          className={`w-2/5 flex form_label ${formErrors.includes('first_name') ? 'error_label' : ''}`}
        >
          {t('First Name')}
          {requiredFields.includes('first_name') ? <span className='text-red-500'>*</span> : ''}
        </label>
        <input
          type='text'
          name='first_name'
          id='input-client-first_name'
          value={client?.first_name || ''}
          onChange={(e) => editField('first_name', e.target.value)}
          className={`form-input w-3/5 ${formErrors.includes('first_name') ? 'error_field' : ''}`}
          autoComplete='false'
        />
      </div>

      <div className='w-full flex items-center'>
        <label
          htmlFor='input-client-last_name'
          className={`w-2/5 flex form_label ${formErrors.includes('last_name') ? 'error_label' : ''}`}
        >
          {t('Last Name')}
          {requiredFields.includes('last_name') ? <span className='text-red-500'>*</span> : ''}
        </label>
        <input
          type='text'
          name='last_name'
          id='input-client-last_name'
          value={client?.last_name || ''}
          onChange={(e) => editField('last_name', e.target.value)}
          className={`form-input w-3/5 ${formErrors.includes('last_name') ? 'error_field' : ''}`}
          autoComplete='false'
        />
      </div>

      <div className='w-full flex items-center'>
        <label
          htmlFor='input-client-email'
          className={`w-2/5 flex form_label ${formErrors.includes('email') ? 'error_label' : ''}`}
        >
          {t('Email Address')}
        </label>
        <input
          type='email'
          name='email'
          id='input-client-email'
          value={client.email || ''}
          onChange={(e) => editField('email', e.target.value)}
          className={`form-input w-3/5 ${formErrors.includes('email') ? 'error_field' : ''}`}
          autoComplete='false'
        />
      </div>

      <div className='w-full flex items-center'>
        <label
          htmlFor='input-client-phone'
          className={`w-2/5 flex form_label ${formErrors.includes('phone_no') ? 'error_label' : ''}`}
        >
          {t('Phone')}
        </label>
        <PhoneInput
          country={phoneCountry}
          defaultMask={phoneCountry}
          value={client.phone_no || ''}
          onChange={(phoneValue) => {
            let value = phoneValue
            if (value && value.length > 0 && value[0] !== '+') value = `+${value}`
            editField('phone_no', value)
          }}
          enableSearch
          specialLabel={''}
          containerClass={`w-3/5 !p-0 flex items-center`}
          preferredCountries={['us', 'es']}
          countryCodeEditable
          autocompleteSearch
          inputClass={`form-input-phone ${formErrors.includes('phone_no') ? 'error_field' : ''}`}
          inputStyle={{ width: '100%' }}
          buttonClass={`form-input-phone-button ${formErrors.includes('phone_no') ? 'error_field' : ''}`}
          buttonStyle={{
            borderRight: '1px solid #cacaca',
          }}
          dropdownStyle={{ height: '160px', width: '300px' }}
          searchStyle={{ width: '90%' }}
          inputProps={{ name: 'phone', required: true, id: 'input-client-phone' }}
        />
      </div>

      <div className='w-full flex items-center'>
        <label
          htmlFor='input-client-country'
          className={`w-2/5 flex form_label ${formErrors.includes('country') ? 'error_label' : ''}`}
        >
          {t('Country')}
          {requiredFields.includes('country') ? <span className='text-red-500'>*</span> : ''}
        </label>
        <MultiSelect
          selectId='select-client-country'
          inputId='input-client-country'
          options={countriesList}
          onChange={(value) => editField('country', value)}
          value={defaultCountry}
          containerStyles='w-3/5'
          controlFocusedStyles='form-select form-select-focused'
          controlDefaultStyles={`form-select ${formErrors.includes('country') ? 'error_field' : ''}`}
          inputStyles='!text-zinc-900'
        />
      </div>

      <div className='w-full flex items-center'>
        <label
          htmlFor='input-client-address1'
          className={`w-2/5 flex form_label ${formErrors.includes('address1') ? 'error_label' : ''}`}
        >
          {t('Street Address')}
          {requiredFields.includes('address1') ? <span className='text-red-500'>*</span> : ''}
        </label>
        <input
          type='text'
          name='address1'
          id='input-client-address1'
          value={client?.address1 || ''}
          onChange={(e) => editField('address1', e.target.value)}
          className={`form-input w-3/5 ${formErrors.includes('address1') ? 'error_field' : ''}`}
          autoComplete='false'
        />
      </div>

      <div className='w-full flex items-center'>
        <label
          htmlFor='input-client-address2'
          className={`w-2/5 flex form_label ${formErrors.includes('address2') ? 'error_label' : ''}`}
        >
          {t('Apt, suite, unit etc')}
          {requiredFields.includes('address2') ? <span className='text-red-500'>*</span> : ''}
        </label>
        <input
          type='text'
          name='address2'
          id='input-client-address2'
          value={client?.address2 || ''}
          onChange={(e) => editField('address2', e.target.value)}
          className={`form-input w-3/5 ${formErrors.includes('address2') ? 'error_field' : ''}`}
          autoComplete='false'
        />
      </div>

      <div className='w-full flex items-center'>
        <label
          htmlFor='input-client-municipality'
          className={`w-2/5 flex form_label ${formErrors.includes('municipality') ? 'error_label' : ''}`}
        >
          {t('City / Municipality')}
          {requiredFields.includes('municipality') ? <span className='text-red-500'>*</span> : ''}
        </label>
        <input
          type='text'
          name='municipality'
          id='input-client-municipality'
          value={client?.municipality || ''}
          onChange={(e) => editField('municipality', e.target.value)}
          className={`form-input w-3/5 ${formErrors.includes('municipality') ? 'error_field' : ''}`}
          autoComplete='false'
        />
      </div>

      {client.country && ['CA', 'US'].includes(client.country) ? (
        <>
          <div className='w-full flex items-center'>
            <label
              htmlFor='input-client-province'
              className={`w-2/5 flex form_label ${formErrors.includes('province') ? 'error_label' : ''}`}
            >
              {t('State / Province')}
              {requiredFields.includes('province') ? <span className='text-red-500'>*</span> : ''}
            </label>
            <MultiSelect
              selectId='select-client-province'
              inputId='input-client-province'
              options={statesList}
              onChange={(value) => editField('province', value)}
              value={defaultState}
              containerStyles='w-3/5'
              controlFocusedStyles='form-select form-select-focused'
              controlDefaultStyles={`form-select ${formErrors.includes('province') ? 'error_field' : ''}`}
              inputStyles='!text-zinc-900'
              isClearable={!requiredFields.includes('province')}
            />
          </div>
        </>
      ) : null}

      {client.country !== 'HK' && (
        <div className='w-full flex items-center'>
          <label
            htmlFor='input-client-postal_code'
            className={`w-2/5 flex form_label ${formErrors.includes('postal_code') ? 'error_label' : ''}`}
          >
            {t('Zip / Postal Code')}
            {requiredFields.includes('postal_code') ? <span className='text-red-500'>*</span> : ''}
          </label>
          <input
            type='text'
            name='postal_code'
            id='input-client-postal_code'
            value={client?.postal_code || ''}
            onChange={(e) => editField('postal_code', e.target.value)}
            className={`form-input w-3/5 ${formErrors.includes('postal_code') ? 'error_field' : ''}`}
            autoComplete='false'
          />
        </div>
      )}

      {!isModal && isSpain ? (
        <div className='w-full flex items-center'>
          <div className={`w-2/5 flex form_label ${formErrors.includes('GDPR') ? 'error_label' : ''}`}>
            {t('GDPR')}
            {requiredFields.includes('GDPR') ? <span className='text-red-500 pl-1'>*</span> : ''}
          </div>
          {client.gdpr_url ? (
            <div className='w-3/5 flex items-center gap-2'>
              <a
                href={client.gdpr_url}
                target='_blank'
                rel='noopener noreferrer'
                className=' w-auto flex justify-center items-center'
              >
                <IconPdf classname='h-[54px] w-[54px]' />
                <div className='text-xs text-zinc-500'>{t('GDPR file')}</div>
              </a>

              <button type='button' onClick={onGDPRFileDelete} disabled={deleteGDPRLoading}>
                <IconClose />
              </button>
            </div>
          ) : (
            <span className='flex w-3/5 [&>div]:w-full'>
              <DefaultButton title={t('Sign')} onClick={onGDPROpen} classname='!h-[38px] !w-full grey_button' />
            </span>
          )}
        </div>
      ) : null}

      <GDPRModal
        showed={showGDPRmodal}
        closeModal={() => setShowGDPRmodal(false)}
        client={client}
        onFileLoaded={onGDPRFileLoaded}
      />
      <AddRelatedPersonModal showed={showRelatedPersonModal} closeModal={() => setShowRelatedPersonModal(false)} />
    </div>
  )
}
