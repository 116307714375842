import React, { useEffect } from 'react'

interface Props {
  children: React.ReactNode
  showed: boolean
  bodyStyle?: string
}

export const Modal: React.FC<Props> = ({ children, bodyStyle, showed }) => {
  useEffect(() => {
    const body = document.getElementById('page_body')
    // document.body.style.overflow = 'hidden'
    if (body && showed) body.style.overflow = 'hidden'
    if (body && !showed) body.style.overflow = 'auto'
  }, [showed])

  return (
    <div
      className='fixed inset-0 bg-black bg-opacity-50 justify-center items-center z-[100]'
      style={{ display: showed ? 'flex' : 'none' }}
    >
      <div className={`bg-white rounded p-7 w-full max-w-[90vw] md:max-w-min min-w-96 ${bodyStyle}`}>{children}</div>
    </div>
  )
}
