import axios, { AxiosPromise } from 'axios'
import { API_URL, LOCAL_ACCESS_TOKEN } from './consts'

interface Props {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  url: string
  data?: Object | null
  isFormData?: boolean
  params?: object
  signal?: any
  responseType?: 'blob' | 'arraybuffer'
}

export const callApi = async ({
  method,
  url,
  data = null,
  isFormData,
  params,
  signal,
  responseType,
}: Props): Promise<AxiosPromise<any>> => {
  try {
    let sendData: any = data

    if (isFormData) {
      sendData = new FormData()
      sendData.append('data', JSON.stringify(data))
    }

    const currentLang = window.location.pathname.includes('/es/') ? 'es' : 'en'

    let headers = {
      'Content-Type': isFormData ? 'multipart/form-data ' : 'application/json',
      Authorization: '',
      'Accept-Language': currentLang,
    }

    const token = sessionStorage.getItem(LOCAL_ACCESS_TOKEN)

    if (token) {
      headers = { ...headers, Authorization: `Bearer ${token}` }
    }

    let api_uri = API_URL

    return await axios({
      method: method,
      url: api_uri + url,
      data: sendData,
      headers,
      params,
      signal,
      responseType,
    })
  } catch (e: any) {
    console.log('-> e', e)
    if (
      e?.response?.status === 401 &&
      e.response?.data &&
      e.response.data?.message &&
      e.response?.data &&
      e.response.data.message === 'Token has expired'
    ) {
      console.log(e)
      // sessionStorage.removeItem(LOCAL_ACCESS_TOKEN)
      // eslint-disable-next-line no-throw-literal
      throw 'Your session has been expired. Please return back to the Appointment card in Admin and click on Continue appointment. If Continue appointment button is not there, reopen the appointment card.'
    }
    if (e?.response && e.response?.data && e.response.data?.message) throw e.response.data.message
    else if (e?.response && e.response?.data && e.response.data?.error) throw e.response.data.error
    else throw e?.message
  }
}
