import React from 'react'

export const IconEdit = ({ classname = 'text-zinc-500' }) => {
  return (
    <svg
      className={`w-[18px] h-[18px] ${classname}`}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.35505 14.645H4.16542L12.3244 6.48602L11.514 5.67565L3.35505 13.8346V14.645ZM14.6634 5.69407L12.3059 3.33663L13.0795 2.5631C13.2882 2.35437 13.546 2.25 13.853 2.25C14.16 2.25 14.4178 2.35437 14.6265 2.5631L15.4369 3.37347C15.6456 3.5822 15.75 3.84004 15.75 4.147C15.75 4.45396 15.6456 4.7118 15.4369 4.92053L14.6634 5.69407ZM13.8898 6.4676L4.60744 15.75H2.25V13.3926L11.5324 4.11016L13.8898 6.4676ZM11.9192 6.08083L11.514 5.67565L12.3244 6.48602L11.9192 6.08083Z'
        fill='currentColor'
      />
    </svg>
  )
}
