import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IOfferItem } from '../../types/offerTypes'
import { useSelector } from 'react-redux'
import { IStore } from '../../types/storeTypes'
import { IconNoData } from '../../images/IconNoData'
import { DefaultTextarea } from '../defaultTextarea'
import { getCurrencySymbol } from '../../utils/getCurrencySymbol'
import { DefaultButton } from '../defaultButton'
import { useCheckDiamondReport } from '../../hooks/useCheckDiamondReport'
import { TCheckReportData, TReactSelectOption } from '../../types/additionalTypes'
import { MultiSelect } from '../multiSelect'
import { currentYear, minWatchYear } from '../../utils/consts'
import { DefaultTooltip } from '../defaultTooltip'
import { validateWeight } from '../../utils/validateNumbers'

interface Props {
  currentItem: IOfferItem
  setCurrentItem: (value: IOfferItem) => void
  formErrors: string[]
  setFormErrors: (value: string[]) => void
  requiredFields: string[]
}

export const AddItemForm: React.FC<Props> = ({
  currentItem,
  setCurrentItem,
  formErrors,
  setFormErrors,
  requiredFields,
}) => {
  const { t } = useTranslation()
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const offerDicts = useSelector((store: IStore) => store.offerDicts)
  const { offerDictsData } = offerDicts
  const { checkReport, checkReportLoading } = useCheckDiamondReport()
  const currency =
    appointmentInfo && appointmentInfo.intermediate_save && appointmentInfo.intermediate_save.currency
      ? appointmentInfo.intermediate_save.currency
      : 'USD'

  const editField = (name: keyof IOfferItem, value: any) => {
    if (requiredFields.includes(name)) {
      setFormErrors(formErrors.filter((error) => error !== name))
    }

    if (name === 'diamond') {
      setCurrentItem({
        ...currentItem,
        diamond: value,
        cert_type: '',
        cert_number: '',
        carat_weight: undefined,
        shape: '',
        color_grade: '',
        clarity_grade: '',
        cut: '',
        polish: '',
        symmetry: '',
        fluorescence: '',
      })
    } else if (name === 'cert_type') {
      setCurrentItem({
        ...currentItem,
        cert_type: value,
        cert_number: '',
      })
    } else {
      setCurrentItem({ ...currentItem, [name]: value })
    }
  }

  const onEditCollection = (fullValue: TReactSelectOption, fieldName: keyof IOfferItem) => {
    const newCollection = fullValue ? (fullValue.value as number) : undefined
    let newBrand = undefined
    const valueFromDicts =
      offerDictsData && offerDictsData.models && fullValue
        ? offerDictsData.models.find((model) => model.id === fullValue.id)
        : null

    if (offerDictsData && offerDictsData.brands && valueFromDicts && valueFromDicts.brand_id) {
      const newBrandFromDicts = offerDictsData.brands.find((brand) => brand.id === valueFromDicts.brand_id)
      if (newBrandFromDicts) {
        newBrand = newBrandFromDicts.id
      }
    }
    if (requiredFields.includes(fieldName)) {
      setFormErrors(formErrors.filter((error) => error !== fieldName))
    }
    setCurrentItem({
      ...currentItem,
      [fieldName]: newCollection,
      brand: newBrand ? newBrand : !newCollection ? undefined : currentItem.brand,
    })
  }

  const onGiaLookup = async () => {
    if (currentItem.cert_type && currentItem.cert_number) {
      const type = currentItem.cert_type.toLowerCase().includes('gia') ? 'GIA' : currentItem.cert_type
      const data: TCheckReportData = await checkReport(type, currentItem.cert_number)
      const formattedData = {
        carat_weight: data.caratWeight,
        shape: data.stoneShape,
        color_grade: data.stoneColor,
        clarity_grade: data.stoneClarity,
        cut: data.stoneCut,
        polish: data.stonePolish,
        symmetry: data.stoneSymmetry,
        fluorescence: data.stoneFluorescence,
      }
      setCurrentItem({ ...currentItem, ...formattedData })
    }
  }

  const subproductsList = useMemo(
    () =>
      offerDictsData?.subproducts
        ? offerDictsData.subproducts
            .filter((subproduct) => !!subproduct.name)
            .map((subproduct) => {
              return { label: subproduct.name, id: subproduct.id, value: subproduct.id }
            })
        : [],
    [offerDictsData]
  )

  const defaultSubproduct = useMemo(() => {
    return currentItem.subproduct ? subproductsList.find((listItem) => listItem.value === currentItem.subproduct) : null
  }, [currentItem.subproduct, subproductsList])

  const brandsList = useMemo(
    () =>
      offerDictsData?.brands
        ? offerDictsData.brands.map((brand) => {
            return { label: brand.name, id: brand.id, value: brand.id }
          })
        : [],
    [offerDictsData]
  )

  const defaultBrand = useMemo(() => {
    return currentItem.brand ? brandsList.find((listItem) => listItem.value === currentItem.brand) : null
  }, [currentItem.brand, brandsList])

  const modelsList = useMemo(
    () =>
      offerDictsData?.models
        ? offerDictsData.models.map((model) => {
            return { label: model.name, id: model.id, value: model.id }
          })
        : [],
    [offerDictsData]
  )

  const defaultModel = useMemo(() => {
    return currentItem.model ? modelsList.find((listItem) => listItem.value === currentItem.model) : null
  }, [currentItem.model, modelsList])

  const metalsList = useMemo(
    () =>
      offerDictsData?.metals
        ? offerDictsData.metals.map((metal) => {
            return { label: metal.name, id: metal.id, value: metal.id }
          })
        : [],
    [offerDictsData]
  )

  const defaultMetal = useMemo(() => {
    return currentItem.metal ? metalsList.find((listItem) => listItem.value === currentItem.metal) : null
  }, [currentItem.metal, metalsList])

  const defaultMetal1 = useMemo(() => {
    return currentItem.metal1 ? metalsList.find((listItem) => listItem.value === currentItem.metal1) : null
  }, [currentItem.metal1, metalsList])

  const defaultMetal2 = useMemo(() => {
    return currentItem.metal2 ? metalsList.find((listItem) => listItem.value === currentItem.metal2) : null
  }, [currentItem.metal2, metalsList])

  const defaultCollection = useMemo(() => {
    return currentItem.collection ? modelsList.find((listItem) => listItem.value === currentItem.collection) : null
  }, [currentItem.collection, modelsList])

  const diamondsList = useMemo(
    () =>
      offerDictsData?.diamonds
        ? offerDictsData.diamonds.map((model) => {
            return { label: model.name, id: model.id, value: model.id }
          })
        : [],
    [offerDictsData]
  )

  const defaultDiamond = useMemo(() => {
    return currentItem.diamond ? diamondsList.find((listItem) => listItem.value === currentItem.diamond) : null
  }, [currentItem.diamond, diamondsList])

  const gemsList = useMemo(
    () =>
      offerDictsData?.gems
        ? offerDictsData.gems.map((model) => {
            return { label: model.name, id: model.id, value: model.id }
          })
        : [],
    [offerDictsData]
  )

  const defaultPrimaryGem = useMemo(() => {
    return currentItem.primary_gem ? gemsList.find((listItem) => listItem.value === currentItem.primary_gem) : null
  }, [currentItem.primary_gem, gemsList])

  const certTypesList = useMemo(
    () =>
      offerDictsData?.reports
        ? offerDictsData.reports.map((model) => {
            return { label: model.name, id: model.id, value: model.name }
          })
        : [],
    [offerDictsData]
  )

  const defaultCertType = useMemo(() => {
    return currentItem.cert_type ? certTypesList.find((listItem) => listItem.value === currentItem.cert_type) : null
  }, [currentItem.cert_type, certTypesList])

  const shapesList = useMemo(
    () =>
      offerDictsData?.shapes
        ? offerDictsData.shapes.map((model) => {
            return { label: model.name, id: model.id, value: model.name }
          })
        : [],
    [offerDictsData]
  )

  const defaultShape = useMemo(() => {
    return currentItem.shape ? shapesList.find((listItem) => listItem.value === currentItem.shape) : null
  }, [currentItem.shape, shapesList])

  const colorGradeList = useMemo(
    () =>
      offerDictsData?.color_grades
        ? offerDictsData.color_grades.map((model) => {
            return { label: model.name, id: model.id, value: model.name }
          })
        : [],
    [offerDictsData]
  )

  const defaultColorGrade = useMemo(() => {
    return currentItem.color_grade
      ? colorGradeList.find((listItem) => listItem.value === currentItem.color_grade)
      : null
  }, [currentItem.color_grade, colorGradeList])

  const cutsList = useMemo(
    () =>
      offerDictsData?.cuts
        ? offerDictsData.cuts.map((model) => {
            return { label: model.name, id: model.id, value: model.name }
          })
        : [],
    [offerDictsData]
  )

  const defaultCut = useMemo(() => {
    return currentItem.cut ? cutsList.find((listItem) => listItem.value === currentItem.cut) : null
  }, [currentItem.cut, cutsList])

  const polishesList = useMemo(
    () =>
      offerDictsData?.polishes
        ? offerDictsData.polishes.map((model) => {
            return { label: model.name, id: model.id, value: model.name }
          })
        : [],
    [offerDictsData]
  )

  const defaultPolish = useMemo(() => {
    return currentItem.polish ? polishesList.find((listItem) => listItem.value === currentItem.polish) : null
  }, [currentItem.polish, polishesList])

  const symmetriesList = useMemo(
    () =>
      offerDictsData?.symmetries
        ? offerDictsData.symmetries.map((model) => {
            return { label: model.name, id: model.id, value: model.name }
          })
        : [],
    [offerDictsData]
  )

  const defaultSymmetry = useMemo(() => {
    return currentItem.symmetry ? symmetriesList.find((listItem) => listItem.value === currentItem.symmetry) : null
  }, [currentItem.symmetry, symmetriesList])

  const fluorescenceList = useMemo(
    () =>
      offerDictsData?.fluorescence
        ? offerDictsData.fluorescence.map((model) => {
            return { label: model.name, id: model.id, value: model.name }
          })
        : [],
    [offerDictsData]
  )

  const defaultFluorescence = useMemo(() => {
    return currentItem.fluorescence
      ? fluorescenceList.find((listItem) => listItem.value === currentItem.fluorescence)
      : null
  }, [currentItem.fluorescence, fluorescenceList])

  const clarityGradesList = useMemo(
    () =>
      offerDictsData?.clarity_grades
        ? offerDictsData.clarity_grades.map((model) => {
            return { label: model.name, id: model.id, value: model.name }
          })
        : [],
    [offerDictsData]
  )

  const defaultClarityGrade = useMemo(() => {
    return currentItem.clarity_grade
      ? clarityGradesList.find((listItem) => listItem.value === currentItem.clarity_grade)
      : null
  }, [currentItem.clarity_grade, clarityGradesList])

  const isHongkong = useMemo(() => {
    return appointmentInfo.intermediate_save &&
      appointmentInfo.intermediate_save.office &&
      appointmentInfo.intermediate_save.office.country &&
      appointmentInfo.intermediate_save.office.country.toLowerCase() === 'hk'
      ? true
      : false
  }, [appointmentInfo.intermediate_save])

  return (
    <>
      {currentItem.type ? (
        <div className='w-full grid grid-cols-1 md:grid-cols-2 md:divide-x min-h-[50vh]'>
          <div className='flex flex-col md:pr-6 lg:pr-12 gap-6 mb-6 md:mb-0'>
            {!['scrap', 'intrinsic', 'melee', 'loose', 'other'].includes(currentItem.type) && (
              <>
                <div className='w-full flex items-center'>
                  <label
                    htmlFor='input-item-subproduct'
                    className={`w-1/3 flex form-offer-item_label ${formErrors.includes('subproduct') ? 'error_label' : ''}`}
                  >
                    {t('Sub product')}
                    {requiredFields.includes('subproduct') ? <span className='text-red-500'>*</span> : ''}
                  </label>
                  <MultiSelect
                    selectId='select-item-subproduct'
                    inputId='input-item-subproduct'
                    options={subproductsList}
                    onChange={(value) => editField('subproduct', value)}
                    value={defaultSubproduct}
                    // isClearable
                    containerStyles='w-2/3 h-[38px]'
                    controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                    controlDefaultStyles={`form-select ${formErrors.includes('subproduct') ? 'error_field' : ''}`}
                    inputStyles='!text-zinc-900'
                  />
                </div>
              </>
            )}

            {['watch'].includes(currentItem.type) && (
              <>
                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-model' className={`w-1/3 flex form-offer-item_label`}>
                    {t('Model')}
                  </label>
                  <MultiSelect
                    selectId='select-item-model'
                    inputId='input-item-model'
                    options={modelsList}
                    onChange={(value, fullValue) => onEditCollection(fullValue, 'model')}
                    value={defaultModel}
                    isClearable
                    containerStyles='w-2/3 h-[38px]'
                    controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                    controlDefaultStyles='form-offer-item-select'
                    inputStyles='!text-zinc-900'
                  />
                </div>
                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-brand_watch' className={`w-1/3 flex form-offer-item_label`}>
                    {t('Brand')}
                  </label>
                  <MultiSelect
                    selectId='select-item-brand_watch'
                    inputId='input-item-brand_watch'
                    options={brandsList}
                    onChange={(value) => editField('brand', value)}
                    value={defaultBrand}
                    isClearable
                    containerStyles='w-2/3 h-[38px]'
                    controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                    controlDefaultStyles='form-offer-item-select'
                    inputStyles='!text-zinc-900'
                  />
                </div>
                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-model_number' className={`w-1/3 flex form-offer-item_label`}>
                    {t('Model')}#
                  </label>
                  <input
                    type='text'
                    name='model_number'
                    id='input-item-model_number'
                    value={currentItem.model_number || ''}
                    onChange={(e) => editField('model_number', e.target.value)}
                    className={`form-input w-2/3 h-[38px]`}
                    autoComplete='false'
                  />
                </div>
                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-serial_number' className={`w-1/3 flex form-offer-item_label`}>
                    {t('Serial')}#
                  </label>
                  <input
                    type='text'
                    name='serial_number'
                    id='input-item-serial_number'
                    value={currentItem.serial_number || ''}
                    onChange={(e) => editField('serial_number', e.target.value)}
                    className={`form-input w-2/3 h-[38px]`}
                    autoComplete='false'
                  />
                </div>

                <div className='w-full flex items-center relative'>
                  <label
                    htmlFor='input-item-full_year'
                    className={`w-1/3 flex form-offer-item_label ${formErrors.includes('full_year') ? 'error_label' : ''}`}
                  >
                    {t('Full Year')}
                    <DefaultTooltip
                      id='full_year-tooltip'
                      buttonStyle={`!h-3 !w-3 text-[10px] ${formErrors.includes('full_year') && '!bg-red-500'}`}
                      place='top-start'
                      buttonIcon={formErrors.includes('full_year') ? '!' : undefined}
                    >
                      {formErrors.includes('full_year') ? (
                        <div className='text-sm text-red-400'>
                          {t('Year must be greater than or equal to {{minWatchYear}} and less than {{currentYear}}', {
                            minWatchYear: minWatchYear,
                            currentYear: currentYear,
                          })}
                        </div>
                      ) : (
                        t('Type in full year, either exact or approximate.')
                      )}
                    </DefaultTooltip>
                  </label>
                  <input
                    type='number'
                    name='full_year'
                    min={minWatchYear}
                    max={currentYear}
                    id='input-item-full_year'
                    value={currentItem.full_year || ''}
                    onChange={(e) => editField('full_year', e.target.value)}
                    className={`form-input w-2/3 h-[38px] ${formErrors.includes('full_year') ? 'error_field' : ''}`}
                    autoComplete='false'
                  />
                </div>

                <div className='w-full flex items-center'>
                  <div
                    className={`w-1/3 flex form-offer-item_label ${formErrors.includes('year_type') ? 'error_label' : ''}`}
                  >
                    {t('Year Type')}
                    <DefaultTooltip
                      id='year_type-tooltip'
                      buttonStyle={`!h-3 !w-3 text-[10px] ${formErrors.includes('year_type') && '!bg-red-500'}`}
                      place='top-start'
                      buttonIcon={formErrors.includes('year_type') ? '!' : undefined}
                    >
                      {formErrors.includes('year_type') ? (
                        <div className='text-sm text-red-400'>{t('Year type is required if a year is entered')}</div>
                      ) : (
                        t('Select exact if the exact year is indicated select circa if approximate')
                      )}
                    </DefaultTooltip>
                  </div>
                  <div className='w-2/3 flex justify-between'>
                    <div className='w-2/5 flex items-center justify-between gap-2'>
                      <input
                        type='checkbox'
                        name='year_type-exact'
                        id='input-item-year_type-exact'
                        checked={currentItem.year_type === 'exact'}
                        onChange={() => editField('year_type', currentItem.year_type === 'exact' ? undefined : 'exact')}
                        className={`form-input w-[24px] h-[24px] accent-green-700`}
                        autoComplete='false'
                      />
                      <label htmlFor='input-item-year_type-exact' className={`w-2/3 flex form-offer-item_label`}>
                        {t('Exact')}
                      </label>
                    </div>
                    <div className='w-2/5 flex items-center justify-between gap-2'>
                      <input
                        type='checkbox'
                        name='year_type-circa'
                        id='input-item-year_type-circa'
                        checked={currentItem.year_type === 'circa'}
                        onChange={() => editField('year_type', currentItem.year_type === 'circa' ? undefined : 'circa')}
                        className={`form-input w-[24px] h-[24px] accent-green-700`}
                        autoComplete='false'
                      />
                      <label htmlFor='input-item-year_type-circa' className={`w-2/3 flex form-offer-item_label`}>
                        {t('Circa')}
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}

            {['scrap', 'intrinsic', 'coins', 'melee'].includes(currentItem.type) && (
              <div className='w-full flex items-center'>
                <label htmlFor='input-item-weight' className={`w-1/3 flex form-offer-item_label`}>
                  {t('Total weight (Grams)')}
                </label>
                <input
                  type='number'
                  name='weight'
                  id='input-item-weight'
                  value={currentItem.weight || ''}
                  onChange={(e) => editField('weight', +e.target.value)}
                  className={`form-input w-2/3 h-[38px]`}
                  autoComplete='false'
                />
              </div>
            )}

            {!['scrap', 'intrinsic', 'coins', 'melee', 'other', 'watch', 'loose'].includes(currentItem.type) && (
              <>
                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-collection' className={`w-1/3 flex form-offer-item_label`}>
                    {t('Collection')}
                  </label>
                  <MultiSelect
                    selectId='select-item-collection'
                    inputId='input-item-collection'
                    options={modelsList}
                    onChange={(value, fullValue) => onEditCollection(fullValue, 'collection')}
                    value={defaultCollection}
                    isClearable
                    containerStyles='w-2/3 h-[38px]'
                    controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                    controlDefaultStyles='form-offer-item-select'
                    inputStyles='!text-zinc-900'
                  />
                </div>
              </>
            )}

            {!['scrap', 'intrinsic', 'melee', 'loose', 'other', 'coins', 'watch'].includes(currentItem.type) && (
              <div className='w-full flex items-center'>
                <label htmlFor='input-item-brand' className={`w-1/3 flex form-offer-item_label`}>
                  {t('Brand')}
                </label>
                <MultiSelect
                  selectId='select-item-brand'
                  inputId='input-item-brand'
                  options={brandsList}
                  onChange={(value) => editField('brand', value)}
                  value={defaultBrand}
                  isClearable
                  containerStyles='w-2/3 h-[38px]'
                  controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                  controlDefaultStyles='form-offer-item-select'
                  inputStyles='!text-zinc-900'
                />
              </div>
            )}

            {!['watch', 'melee', 'other', 'loose', 'scrap', 'intrinsic', 'coins'].includes(currentItem.type) && (
              <>
                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-metal' className={`w-1/3 flex form-offer-item_label`}>
                    {t('Metal')}
                  </label>
                  <MultiSelect
                    selectId='select-item-metal'
                    inputId='input-item-metal'
                    options={metalsList}
                    onChange={(value) => editField('metal', value)}
                    value={defaultMetal}
                    isClearable
                    containerStyles='w-2/3 h-[38px]'
                    controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                    controlDefaultStyles='form-offer-item-select'
                    inputStyles='!text-zinc-900'
                  />
                </div>
              </>
            )}

            {['scrap', 'intrinsic', 'coins'].includes(currentItem.type) && (
              <>
                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-metal1' className={`w-1/3 flex form-offer-item_label`}>
                    {t('Metal')}
                  </label>
                  <MultiSelect
                    selectId='select-item-metal1'
                    inputId='input-item-metal1'
                    options={metalsList}
                    onChange={(value) => editField('metal1', value)}
                    value={defaultMetal1}
                    isClearable
                    containerStyles='w-2/3 h-[38px]'
                    controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                    controlDefaultStyles='form-offer-item-select'
                    inputStyles='!text-zinc-900'
                  />
                </div>
                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-metal2' className={`w-1/3 flex form-offer-item_label`}>
                    {`${t('Metal')} 2`}
                  </label>
                  <MultiSelect
                    selectId='select-item-metal2'
                    inputId='input-item-metal2'
                    options={metalsList}
                    onChange={(value) => editField('metal2', value)}
                    value={defaultMetal2}
                    isClearable
                    containerStyles='w-2/3 h-[38px]'
                    controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                    controlDefaultStyles='form-offer-item-select'
                    inputStyles='!text-zinc-900'
                  />
                </div>
              </>
            )}

            {!['scrap', 'intrinsic', 'coins', 'melee', 'other', 'watch'].includes(currentItem.type) && (
              <>
                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-diamond' className={`w-1/3 flex form-offer-item_label`}>
                    {t('Diamond')}
                  </label>
                  <MultiSelect
                    selectId='select-item-diamond'
                    inputId='input-item-diamond'
                    options={diamondsList}
                    onChange={(value) => editField('diamond', value)}
                    value={defaultDiamond}
                    isClearable
                    containerStyles='w-2/3 h-[38px]'
                    controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                    controlDefaultStyles='form-offer-item-select'
                    inputStyles='!text-zinc-900'
                  />
                </div>

                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-primary_gem' className={`w-1/3 flex form-offer-item_label`}>
                    {t('Primary Gem')}
                  </label>
                  <MultiSelect
                    selectId='select-item-primary_gem'
                    inputId='input-item-primary_gem'
                    options={gemsList}
                    onChange={(value) => editField('primary_gem', value)}
                    value={defaultPrimaryGem}
                    isClearable
                    containerStyles='w-2/3 h-[38px]'
                    controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                    controlDefaultStyles='form-offer-item-select'
                    inputStyles='!text-zinc-900'
                  />
                </div>

                {!!currentItem.diamond && (
                  <div className='w-full flex items-center'>
                    <label
                      htmlFor='input-item-approx_total_dia_ct_weight'
                      className={`w-1/3 flex form-offer-item_label`}
                    >
                      {t('Approximate diamond carat weight')}
                    </label>
                    <input
                      type='text'
                      name='approx_total_dia_ct_weight'
                      id='input-item-approx_total_dia_ct_weight'
                      value={currentItem.approx_total_dia_ct_weight || ''}
                      onChange={(e) => {
                        let newValue = e.target.value
                        if ([',', '.'].includes(newValue)) newValue = '0,'
                        if (validateWeight(newValue) || e.target.value === '') {
                          editField('approx_total_dia_ct_weight', newValue)
                        }
                      }}
                      className={`form-input w-2/3 h-[38px]`}
                      autoComplete='false'
                    />
                  </div>
                )}

                {!!currentItem.primary_gem && (
                  <div className='w-full flex items-center'>
                    <label
                      htmlFor='input-item-approx_tot_col_stone_ct_wt'
                      className={`w-1/3 flex form-offer-item_label`}
                    >
                      {t('Approximate Colored stone weight')}
                    </label>
                    <input
                      type='text'
                      name='approx_tot_col_stone_ct_wt'
                      id='input-item-approx_tot_col_stone_ct_wt'
                      value={currentItem.approx_tot_col_stone_ct_wt || ''}
                      onChange={(e) => {
                        let newValue = e.target.value
                        if ([',', '.'].includes(newValue)) newValue = '0,'
                        if (validateWeight(newValue) || e.target.value === '') {
                          editField('approx_tot_col_stone_ct_wt', newValue)
                        }
                      }}
                      className={`form-input w-2/3 h-[38px]`}
                      autoComplete='false'
                    />
                  </div>
                )}
              </>
            )}

            {!['scrap', 'intrinsic', 'coins', 'melee', 'other'].includes(currentItem.type) && (
              <div className='w-full flex items-center justify-end'>
                <div className='w-2/3 flex justify-between'>
                  <div className='w-2/5 flex items-center justify-between'>
                    <input
                      type='checkbox'
                      name='box'
                      id='input-item-box'
                      checked={!!currentItem.box}
                      onChange={() => editField('box', !currentItem.box)}
                      className={`form-input w-[24px] h-[24px] accent-green-700`}
                      autoComplete='false'
                    />
                    <label htmlFor='input-item-box' className={`w-2/3 flex form-offer-item_label`}>
                      {t('Box')}
                    </label>
                  </div>
                  <div className='w-2/5 flex items-center justify-between'>
                    <input
                      type='checkbox'
                      name='papers'
                      id='input-item-papers'
                      checked={!!currentItem.papers}
                      onChange={() => editField('papers', !currentItem.papers)}
                      className={`form-input w-[24px] h-[24px] accent-green-700`}
                      autoComplete='false'
                    />
                    <label htmlFor='input-item-papers' className={`w-2/3 flex form-offer-item_label`}>
                      {t('Papers')}
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className='flex flex-col md:pl-6 lg:pl-12 gap-6'>
            {!!currentItem.diamond && (
              <>
                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-cert_type' className={`w-1/3 flex form-offer-item_label`}>
                    {t('Report')}
                  </label>
                  <MultiSelect
                    selectId='select-item-cert_type'
                    inputId='input-item-cert_type'
                    options={certTypesList}
                    onChange={(value) => editField('cert_type', value)}
                    value={defaultCertType}
                    isClearable
                    containerStyles='w-2/3 h-[38px]'
                    controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                    controlDefaultStyles='form-offer-item-select'
                    inputStyles='!text-zinc-900'
                  />
                </div>

                {!!currentItem.cert_type && (
                  <div className='w-full flex items-start'>
                    <label htmlFor='input-item-cert_number' className={`w-1/3 flex form-offer-item_label`}>
                      {t('Report Number')}
                    </label>
                    <div className='w-2/3 flex flex-col gap-2'>
                      <input
                        type='text'
                        name='cert_number'
                        id='input-item-cert_number'
                        value={currentItem.cert_number || ''}
                        onChange={(e) => editField('cert_number', e.target.value)}
                        className={`form-input w-full h-[38px]`}
                        autoComplete='false'
                      />
                      {!!currentItem.cert_type && currentItem.cert_type.toLowerCase().includes('gia') && (
                        <DefaultButton
                          title={t('Lookup GIA report')}
                          onClick={onGiaLookup}
                          classname='h-[38px] w-full blue_button'
                          loading={checkReportLoading}
                          loaderColor='fill-blue-500'
                        />
                      )}
                    </div>
                  </div>
                )}

                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-carat_weight' className={`w-1/3 flex form-offer-item_label`}>
                    {t('Carat Weight')}
                  </label>
                  <input
                    type='number'
                    name='carat_weight'
                    id='input-item-carat_weight'
                    value={currentItem.carat_weight || ''}
                    onChange={(e) => editField('carat_weight', e.target.value)}
                    className={`form-input w-2/3 h-[38px]`}
                    autoComplete='false'
                  />
                </div>

                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-shape' className={`w-1/3 flex form-offer-item_label`}>
                    {t('Shape')}
                  </label>
                  <MultiSelect
                    selectId='select-item-shape'
                    inputId='input-item-shape'
                    options={shapesList}
                    onChange={(value) => editField('shape', value)}
                    value={defaultShape}
                    isClearable
                    containerStyles='w-2/3 h-[38px]'
                    controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                    controlDefaultStyles='form-offer-item-select'
                    inputStyles='!text-zinc-900'
                  />
                </div>

                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-color_grade' className={`w-1/3 flex form-offer-item_label`}>
                    {t('Color Grade')}
                  </label>
                  <MultiSelect
                    selectId='select-item-color_grade'
                    inputId='input-item-color_grade'
                    options={colorGradeList}
                    onChange={(value) => editField('color_grade', value)}
                    value={defaultColorGrade}
                    isClearable
                    containerStyles='w-2/3 h-[38px]'
                    controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                    controlDefaultStyles='form-offer-item-select'
                    inputStyles='!text-zinc-900'
                    disableSort
                  />
                </div>

                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-clarity_grade' className={`w-1/3 flex form-offer-item_label`}>
                    {t('Clarity Grade')}
                  </label>
                  <MultiSelect
                    selectId='select-item-clarity_grade'
                    inputId='input-item-clarity_grade'
                    options={clarityGradesList}
                    onChange={(value) => editField('clarity_grade', value)}
                    value={defaultClarityGrade}
                    isClearable
                    containerStyles='w-2/3 h-[38px]'
                    controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                    controlDefaultStyles='form-offer-item-select'
                    inputStyles='!text-zinc-900'
                  />
                </div>

                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-cut' className={`w-1/3 flex form-offer-item_label`}>
                    {t('Cut')}
                  </label>
                  <MultiSelect
                    selectId='select-item-cut'
                    inputId='input-item-cut'
                    options={cutsList}
                    onChange={(value) => editField('cut', value)}
                    value={defaultCut}
                    isClearable
                    containerStyles='w-2/3 h-[38px]'
                    controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                    controlDefaultStyles='form-offer-item-select'
                    inputStyles='!text-zinc-900'
                  />
                </div>

                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-polish' className={`w-1/3 flex form-offer-item_label`}>
                    {t('Polish')}
                  </label>
                  <MultiSelect
                    selectId='select-item-polish'
                    inputId='input-item-polish'
                    options={polishesList}
                    onChange={(value) => editField('polish', value)}
                    value={defaultPolish}
                    isClearable
                    containerStyles='w-2/3 h-[38px]'
                    controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                    controlDefaultStyles='form-offer-item-select'
                    inputStyles='!text-zinc-900'
                  />
                </div>

                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-symmetry' className={`w-1/3 flex form-offer-item_label`}>
                    {t('Symmetry')}
                  </label>
                  <MultiSelect
                    selectId='select-item-symmetry'
                    inputId='input-item-symmetry'
                    options={symmetriesList}
                    onChange={(value) => editField('symmetry', value)}
                    value={defaultSymmetry}
                    isClearable
                    containerStyles='w-2/3 h-[38px]'
                    controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                    controlDefaultStyles='form-offer-item-select'
                    inputStyles='!text-zinc-900'
                  />
                </div>

                <div className='w-full flex items-center'>
                  <label htmlFor='input-item-fluorescence' className={`w-1/3 flex form-offer-item_label`}>
                    {t('Fluorescence')}
                  </label>
                  <MultiSelect
                    selectId='select-item-fluorescence'
                    inputId='input-item-fluorescence'
                    options={fluorescenceList}
                    onChange={(value) => editField('fluorescence', value)}
                    value={defaultFluorescence}
                    isClearable
                    containerStyles='w-2/3 h-[38px]'
                    controlFocusedStyles='form-offer-item-select form-offer-item-select-focused'
                    controlDefaultStyles='form-offer-item-select'
                    inputStyles='!text-zinc-900'
                  />
                </div>
              </>
            )}

            <div className='w-full flex items-start'>
              <label
                htmlFor='textarea-item-additional_description'
                className={`w-1/3 flex flex-col form-offer-item_label mt-2 pr-2 ${formErrors.includes('additional_description') ? 'error_label' : ''}`}
              >
                <div className='flex'>
                  {t('Additional description')}
                  {requiredFields.includes('additional_description') &&
                  ['scrap', 'intrinsic', 'melee', 'other'].includes(currentItem.type) ? (
                    <span className='text-red-500'>*</span>
                  ) : (
                    ''
                  )}
                </div>
                <div className=' text-red-500 pl-1 text-lg'>({t('Visible to client')})</div>
              </label>
              <div className=' w-2/3'>
                <DefaultTextarea
                  value={currentItem.additional_description || ''}
                  name='textarea-item-additional_description'
                  id='textarea-item-additional_description'
                  styles='form-textarea w-full'
                  onEdit={(value) => editField('additional_description', value)}
                  maxLength={200}
                  error={formErrors.includes('additional_description')}
                />
              </div>
            </div>

            <div className='w-full flex items-center'>
              <label
                htmlFor='input-item-no_offer_made'
                className={`w-1/3 flex form-offer-item_label pr-2 ${formErrors.includes('no_offer_made') ? 'error_label' : ''}`}
              >
                {t('No Offer Made')}
                {requiredFields.includes('no_offer_made') ? <span className='text-red-500'>*</span> : ''}
              </label>
              <div className='relative w-2/3'>
                <input
                  type='checkbox'
                  name='no_offer_made'
                  id='input-item-no_offer_made'
                  checked={!currentItem.offer_made}
                  onChange={() => editField('offer_made', !currentItem.offer_made)}
                  className={`form-input w-[24px] h-[24px] accent-green-700`}
                  autoComplete='false'
                />
              </div>
            </div>

            <div className='w-full flex items-center'>
              <label
                htmlFor='input-item-price'
                className={`w-1/3 flex form-offer-item_label ${formErrors.includes('price') ? 'error_label' : ''}`}
              >
                {!currentItem.offer_made ? t('Est Item Value') : t('Offer Price')}
                {requiredFields.includes('price') ? <span className='text-red-500'>*</span> : ''}
              </label>
              <div className='relative w-2/3'>
                <label htmlFor='input-item-price' className='absolute top-[4px] left-2'>
                  {getCurrencySymbol(currency)}
                </label>
                <input
                  type='text'
                  name='price'
                  id='input-item-price'
                  value={currentItem.price || ''}
                  onChange={(e) => {
                    var regex = /^\d+$/
                    if (e.target.value.match(regex) || e.target.value === '') {
                      editField('price', e.target.value)
                    }
                  }}
                  className={`form-input  ${isHongkong ? '!pl-12' : '!pl-6'} w-full h-[38px] ${formErrors.includes('price') ? 'error_field' : ''}`}
                  autoComplete='false'
                />
              </div>
            </div>

            <div className='w-full flex items-start'>
              <label
                htmlFor='textarea-item-internal_note'
                className={`w-1/3 flex form-offer-item_label mt-2 pr-2 ${formErrors.includes('internal_note') ? 'error_label' : ''}`}
              >
                {t('Internal note')}
              </label>
              <div className=' w-2/3'>
                <DefaultTextarea
                  value={currentItem.internal_note || ''}
                  name='textarea-item-internal_note'
                  id='textarea-item-internal_note'
                  styles='form-textarea w-full !bg-zinc-50'
                  onEdit={(value) => editField('internal_note', value)}
                  maxLength={200}
                  error={formErrors.includes('internal_note')}
                  bgColor='bg-zinc-50'
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='w-full h-[50vh] flex justify-center items-center'>
          <div className='flex flex-col justify-center items-center'>
            <div className='text-[22px] text-zinc-800'>{t('Please select the type of item!')}</div>
            <IconNoData />
            <div className='text-lg text-zinc-400'>{t('No data')}</div>
          </div>
        </div>
      )}
    </>
  )
}
