import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StepBarArrow } from '../../../images/StepBarArrow'
import { IconDoneSimple } from '../../../images/IconDoneSimple'
import { useSelector } from 'react-redux'
import { IStore } from '../../../types/storeTypes'
import { useGetStepData } from '../../../hooks/useGetStepData'
import { ACQUIRED } from '../../../utils/itemStatuses'

interface Props {}

const steps = [
  { id: 1, name: 'Client Details' },
  { id: 2, name: 'Offer' },
  { id: 3, name: 'Decision' },
  { id: 4, name: 'Signature' },
  { id: 5, name: 'Payment' },
  { id: 6, name: 'Confirmation' },
]

export const StepBar: React.FC<Props> = () => {
  const { t } = useTranslation()
  const currentStep = useSelector((store: IStore) => store.currentStep)
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const client = useSelector((store: IStore) => store.client)
  const offerInfo = useSelector((store: IStore) => store.offerInfo)
  const { changeCurrentStep } = useGetStepData()

  const getStepStyle = (step: number) => {
    if (currentStep === step) return 'selected_step'
    if (currentStep > step) return 'completed_step'

    return 'default_step'
  }

  const clientName = useMemo(() => {
    return (
      client.full_name || appointmentInfo.intermediate_save?.client_name || client.first_name || client.last_name || ''
    )
  }, [client.full_name, appointmentInfo.intermediate_save?.client_name, client.first_name, client.last_name])

  const appointmentOffice = useMemo(() => {
    return appointmentInfo.appointment?.office.name || appointmentInfo.intermediate_save?.office?.name || ''
  }, [appointmentInfo.appointment?.office.name, appointmentInfo.intermediate_save?.office?.name])

  return (
    <div className='bg-white p-4 rounded-lg'>
      <div className='w-full mb-2'>{`${clientName}'s ${appointmentOffice} ${t('Appointment')}`}</div>
      <div className=' flex overflow-x-auto gap-4 form_step_bar pb-2'>
        {steps.map((step) => {
          return (
            <button
              type='button'
              onClick={() => changeCurrentStep(step.id)}
              className={`form_step ${getStepStyle(step.id)} ${currentStep <= step.id || currentStep === 6 || (currentStep === 5 && step.id === 4 && !offerInfo.offerItems.some((item) => [ACQUIRED].includes(item.status || ''))) ? ' pointer-events-none' : ''}`}
              key={step.id}
            >
              {currentStep > step.id ? (
                <IconDoneSimple />
              ) : (
                <StepBarArrow color={currentStep === step.id ? 'white' : '#D4D4D8'} />
              )}
              {t(step.name)}
            </button>
          )
        })}
      </div>
    </div>
  )
}
