import React from 'react'

export const IconLoadImage = () => {
  return (
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.4375 9.375L9.575 8.25C9.5 8.15 9.4 8.1 9.275 8.1C9.15 8.1 9.05 8.15 8.975 8.25L7.71875 9.9C7.61875 10.025 7.60313 10.1562 7.67188 10.2938C7.74063 10.4313 7.85625 10.5 8.01875 10.5H13.9813C14.1438 10.5 14.2594 10.4313 14.3281 10.2938C14.3969 10.1562 14.3813 10.025 14.2813 9.9L12.4625 7.51875C12.3875 7.41875 12.2875 7.36875 12.1625 7.36875C12.0375 7.36875 11.9375 7.41875 11.8625 7.51875L10.4375 9.375ZM6.5 13.5C6.0875 13.5 5.73438 13.3531 5.44063 13.0594C5.14688 12.7656 5 12.4125 5 12V3C5 2.5875 5.14688 2.23438 5.44063 1.94063C5.73438 1.64687 6.0875 1.5 6.5 1.5H15.5C15.9125 1.5 16.2656 1.64687 16.5594 1.94063C16.8531 2.23438 17 2.5875 17 3V12C17 12.4125 16.8531 12.7656 16.5594 13.0594C16.2656 13.3531 15.9125 13.5 15.5 13.5H6.5ZM6.5 12H15.5V3H6.5V12ZM3.5 16.5C3.0875 16.5 2.73438 16.3531 2.44063 16.0594C2.14687 15.7656 2 15.4125 2 15V5.25C2 5.0375 2.07188 4.85938 2.21563 4.71563C2.35938 4.57188 2.5375 4.5 2.75 4.5C2.9625 4.5 3.14062 4.57188 3.28438 4.71563C3.42813 4.85938 3.5 5.0375 3.5 5.25V15H13.25C13.4625 15 13.6406 15.0719 13.7844 15.2156C13.9281 15.3594 14 15.5375 14 15.75C14 15.9625 13.9281 16.1406 13.7844 16.2844C13.6406 16.4281 13.4625 16.5 13.25 16.5H3.5Z'
        fill='#71717A'
      />
    </svg>
  )
}
