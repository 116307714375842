import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPayee, IPaymentInfo, IStore } from '../../../types/storeTypes'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { MultiSelect } from '../../multiSelect'
import { DefaultButton } from '../../defaultButton'
import { IconClose } from '../../../images/IconClose'

interface Props {
  paymentType: string
  paymentData: IPaymentInfo['payment_data'] | null
  client: IStore['client']
  setPaymentData: (value: IPaymentInfo['payment_data']) => void
}

export const PaymentInfoBlock: React.FC<Props> = ({ paymentType, paymentData, client, setPaymentData }) => {
  const paymentInfo = useSelector((store: IStore) => store.paymentInfo)
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const { intermediate_save } = appointmentInfo
  const dicts = useSelector((store: IStore) => store.dicts)
  const { t } = useTranslation()
  const [payees, setPayees] = useState<IPayee[]>([])
  const isTransfer = paymentType === 'manualAch'

  const accountsList = useMemo(
    () =>
      dicts?.bank_accounts
        ? dicts.bank_accounts
            .filter(
              (account) =>
                (account.destination === 'check' && !isTransfer) ||
                (isTransfer && account.destination === 'bankTransfer')
            )
            .map((account) => {
              return { label: account.name, id: account.id, value: account.id }
            })
        : [],
    [dicts, isTransfer]
  )

  useEffect(() => {
    if (paymentData && !paymentData?.payees?.length) {
      let newArr: IPayee[] = []
      if (paymentType === 'check') {
        if (
          intermediate_save?.paymentData &&
          intermediate_save.paymentData.type === 'Check' &&
          intermediate_save.paymentData.payees.length > 0
        ) {
          const payeesAmount = intermediate_save.paymentData.payees.reduce((a, b) => {
            return a + (b.amount ? +b.amount : 0)
          }, 0)
          newArr = intermediate_save.paymentData.payees.map((payee, index) => {
            return {
              check_number: payee.check_number || '',
              amount:
                payeesAmount === paymentInfo.amounts?.purchase
                  ? payee.amount || 0
                  : index === 0 && paymentInfo.amounts?.purchase
                    ? paymentInfo.amounts?.purchase
                    : '',
              account_id: payee.account_id ? payee.account_id : null,
              payee_name: payee.payee_name || '',
              payee_email: payee.payee_email || '',
            }
          })
        } else {
          newArr = [
            {
              check_number: '',
              amount: paymentInfo.amounts?.purchase || 0,
              account_id: accountsList.length ? accountsList[0].id : null,
              payee_name: client.full_name,
              payee_email: client.email,
            },
          ]
        }
      } else if (isTransfer) {
        if (
          intermediate_save?.paymentData &&
          (intermediate_save.paymentData.type === 'ACH Bank Transfer' ||
            intermediate_save.paymentData.type === 'Wire Transfer') &&
          intermediate_save.paymentData.payees.length > 0
        ) {
          const payeesAmount = intermediate_save.paymentData.payees.reduce((a, b) => {
            return a + (b.amount ? +b.amount : 0)
          }, 0)
          newArr = intermediate_save.paymentData.payees.map((payee, index) => {
            return {
              check_number: payee.check_number || '',
              amount:
                payeesAmount === paymentInfo.amounts?.purchase
                  ? payee.amount || 0
                  : index === 0 && paymentInfo.amounts?.purchase
                    ? paymentInfo.amounts?.purchase
                    : '',
              account_id: payee.account_id ? payee.account_id : null,
              payee_name: payee.payee_name || '',
              payee_email: payee.payee_email || '',
            }
          })
        } else {
          newArr = [
            {
              check_number: 'ACH ' + moment().format('MM.DD.yyyy'),
              amount: paymentInfo.amounts?.purchase || 0,
              account_id: accountsList.length ? accountsList[0].id : null,
              payee_name: client.full_name,
              payee_email: client.email,
            },
          ]
        }
      }
      setPayees(newArr)
      setPaymentData({ ...paymentData, payees: newArr })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paymentType,
    paymentData?.payees,
    paymentData?.payee_name,
    intermediate_save?.paymentData,
    accountsList,
    paymentInfo.amounts?.purchase,
  ])

  const addPayee = () => {
    if (paymentType === 'check') {
      const newArr = [
        ...payees,
        {
          check_number: '',
          amount: 0,
          account_id: accountsList.length ? accountsList[0].id : 0,
          payee_name: client.full_name,
          payee_email: client.email,
        },
      ]
      setPayees(newArr)
      setPaymentData({ ...paymentData, payees: newArr })
    }
    if (isTransfer) {
      const newArr = [
        ...payees,
        {
          check_number: 'ACH ' + moment().format('MM.DD.yyyy'),
          amount: paymentInfo.amounts?.purchase || 0,
          account_id: accountsList.length ? accountsList[0].id : 0,
          payee_name: client.full_name,
          payee_email: client.email,
        },
      ]
      setPayees(newArr)
      setPaymentData({ ...paymentData, payees: newArr })
    }
  }

  const onEditField = (name: keyof IPayee, index: number, value: string | number) => {
    let newArr = [...payees]
    if (newArr && newArr[index]) {
      // @ts-ignore
      newArr[index][name] = value
    }
    setPayees(newArr)
    setPaymentData({ ...paymentData, payees: newArr })
  }

  const onPayeeDelete = (index: number) => {
    const newArr = [...payees]
    newArr.splice(index, 1)
    setPayees(newArr)
    setPaymentData({ ...paymentData, payees: newArr })
  }

  return (
    <div className='form_block'>
      <div className='form_block_title'>{t('Payment Information')}</div>
      <div className='flex flex-col gap-4 w-full divide-y'>
        {payees.map((payee, index) => {
          const id = `payee-${index + 1}`
          const defaultAccount = accountsList.find((account) => account.id === payee.account_id)
          return (
            <div className={`flex flex-col gap-4 w-full ${index > 0 && 'pt-4'}`} key={id}>
              {payees.length > 1 && (
                <div className='w-full flex items-center justify-end'>
                  <button type='button' onClick={() => onPayeeDelete(index)}>
                    <IconClose />
                  </button>
                </div>
              )}
              <div className='w-full flex items-center'>
                <label htmlFor={`input-${id}-payee_name`} className='w-1/3 flex form_label'>
                  {t('Name')}
                  <span className='text-red-500 pl-1'>*</span>
                </label>
                <input
                  type='text'
                  name='payee_name'
                  id={`input-${id}-payee_name`}
                  value={payee?.payee_name}
                  onChange={(e) => onEditField('payee_name', index, e.target.value)}
                  className='form-input w-2/3'
                  autoComplete='false'
                />
              </div>

              {!isTransfer && (
                <div className='w-full flex items-center'>
                  <label htmlFor={`input-${id}-check_number`} className='w-1/3 flex form_label'>
                    {t('Check number')}
                    <span className='text-red-500 pl-1'>*</span>
                  </label>
                  <input
                    type='text'
                    name='check_number'
                    id={`input-${id}-check_number`}
                    value={payee?.check_number}
                    onChange={(e) => onEditField('check_number', index, e.target.value)}
                    className='form-input w-2/3'
                    autoComplete='false'
                    disabled={isTransfer}
                  />
                </div>
              )}

              <div className='w-full flex items-center'>
                <label htmlFor={`input-${id}-amount`} className='w-1/3 flex form_label'>
                  {t('Amount')}
                  <span className='text-red-500 pl-1'>*</span>
                </label>
                <input
                  type='text'
                  name='amount'
                  id={`input-${id}-amount`}
                  value={payee?.amount}
                  onChange={(e) => {
                    var regex = /^\d+$/
                    if (e.target.value.match(regex) || e.target.value === '') {
                      onEditField('amount', index, e.target.value)
                    }
                  }}
                  className='form-input w-2/3'
                  autoComplete='false'
                />
              </div>

              {!!accountsList && accountsList.length > 1 ? (
                <div className='w-full flex items-center'>
                  <label htmlFor={`input-${id}-account_id`} className='w-1/3 flex form_label'>
                    {t('Account')}
                  </label>
                  <MultiSelect
                    selectId={`select-${id}-account_id`}
                    inputId={`input-${id}-account_id`}
                    options={accountsList}
                    onChange={(value) => onEditField('account_id', index, value)}
                    value={defaultAccount}
                    containerStyles='w-2/3'
                    controlFocusedStyles='form-select form-select-focused'
                    controlDefaultStyles={`form-select`}
                    inputStyles='!text-zinc-900'
                  />
                </div>
              ) : null}
            </div>
          )
        })}
      </div>

      <div className='w-full flex items-center justify-end [&>div]:w-2/3'>
        <DefaultButton title={t('Add Payee')} onClick={addPayee} classname='h-[38px] w-full blue_button' />
      </div>
    </div>
  )
}
