import React from 'react'
import { useTranslation } from 'react-i18next'

export const P404 = () => {
  const { t } = useTranslation()
  return (
    <h1 className='border-lightblue-900 text-lightblue-900 mobile:text-lg font-semibold uppercase text-center mt-14 mb-6'>
      {t('The page you are looking for was not found')}
    </h1>
  )
}
