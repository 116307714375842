import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { callApi } from '../utils/callApi'
import { ApiRoutes } from '../utils/apiRoutes'
import { showGlobalPopup } from '../store/globalPopupSlice'
import { IEmailData } from '../types/additionalTypes'

export interface IEmailSendData {
  attachments: ('offer' | 'purchase' | 'consignment')[]
  subject: string
  body: string
  to: string
  copy?: string[]
  inputCopy?: string
  expiration_date?: string
  external_note?: string
}

export const useOfferEmails = () => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const [loadEmailLoading, setLoadEmailLoading] = useState(false)
  const [sendEmailLoading, setSendEmailLoading] = useState(false)

  const loadEmailData = async (cb?: (data: IEmailData) => void) => {
    setLoadEmailLoading(true)
    try {
      const resp = await callApi({
        method: 'GET',
        url: `${ApiRoutes.email}?lang=${i18n.language}`,
      })

      if (resp && resp.data) {
        if (cb) cb(resp.data)
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setLoadEmailLoading(false)
    }
  }

  const sendEmail = async (data: IEmailSendData, cb?: () => void) => {
    setSendEmailLoading(true)
    try {
      const resp = await callApi({
        method: 'POST',
        url: `${ApiRoutes.email}?lang=${i18n.language}`,
        data,
      })
      // @ts-ignore
      if (resp && resp.data && resp.data.success) {
        if (cb) cb()
        dispatch(showGlobalPopup({ type: 'email', title: 'Message sent', text: '' }))
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setSendEmailLoading(false)
    }
  }
  return {
    loadEmailLoading,
    sendEmailLoading,
    loadEmailData,
    sendEmail,
  }
}
