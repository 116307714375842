/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import QRCode from 'qrcode.react'
import { useSelector } from 'react-redux'
import { callApi } from '../../../../utils/callApi'
import { IStore } from '../../../../types/storeTypes'
import { useTranslation } from 'react-i18next'
import { IconDone } from '../../../../images/IconDone'
import { IUploadSignatureData } from '../../../../hooks/useOfferAgreements'
import { useGetStepData } from '../../../../hooks/useGetStepData'

interface Props {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
  qrCodeValue: string
  token: string
  signature_type: IUploadSignatureData['agreement']
}

export const LoadSignatureViaMobileQrCode: React.FC<Props> = ({
  visible,
  setVisible,
  qrCodeValue,
  token,
  signature_type,
}) => {
  const { t } = useTranslation()
  const agreements = useSelector((store: IStore) => store.agreements)
  const { getSignatureStepAgreements } = useGetStepData()

  const [mobileSessionIsActive, setMobileSessionIsActive] = useState<boolean>(false)
  const [mobileSessionWasActive, setMobileSessionWasActive] = useState(false)

  const onClose = () => {
    setVisible(false)
  }

  // console.log(qrCodeValue, 'qrCodeValue')

  const onEndSession = () => {}

  const listenMobileSession = () => {
    return setInterval(async () => {
      try {
        const response: any = await callApi({
          method: 'GET',
          url: `/v1/booking/images/flag?token=${token}`,
        })
        setMobileSessionIsActive((pr) => {
          if (pr !== response.data.flag) {
            if (pr && !response.data.flag) onEndSession()
            return response.data.flag
          }
          return pr
        })
        if (response.data.flag) setMobileSessionWasActive(true)
      } catch (e) {
        console.error(e)
      }
    }, 5000)
  }

  const listenImages = () => setInterval(getSignatureStepAgreements, 5000)

  useEffect(() => {
    const el = document.getElementById('main_screen_body')
    if (el) visible ? el.classList.remove('animate__fadeIn') : el.classList.add('animate__fadeIn')

    let imagesListener: any = null
    if (visible) {
      const mobileSessionListener = listenMobileSession()
      imagesListener = listenImages()
      return () => {
        clearInterval(mobileSessionListener)
        clearInterval(imagesListener)
      }
    }
  }, [visible])

  useEffect(() => {
    setMobileSessionIsActive(false)
    setMobileSessionWasActive(false)
  }, [qrCodeValue])

  useEffect(() => {
    if (
      (signature_type === 'purchase' && !!agreements.purchase_form) ||
      (signature_type === 'offer' && !!agreements.offer_form) ||
      (signature_type === 'consignment' && !!agreements.consignment_agreement) ||
      (mobileSessionWasActive && !mobileSessionIsActive)
    )
      setVisible(false)
  }, [agreements, mobileSessionWasActive, mobileSessionIsActive])

  return visible ? (
    <div className='fixed inset-0 flex justify-center items-center bg-black/20 z-[1000]' style={{ top: -20 }}>
      <div className='bg-white p-4 flex justify-center items-center flex-col gap-1' style={{ width: 640 }}>
        <h4 className='text-2xl font-semibold font-serif'>{t('Use Camera Phone')}</h4>
        <p className='mb-5 text-lg text-zinc-700'>{t('Scan QR code with mobile device camera to begin')}.</p>
        <div className='relative'>
          <QRCode value={qrCodeValue} size={160} className={mobileSessionIsActive ? 'opacity-10' : ''} />
          {mobileSessionIsActive && (
            <div className='absolute inset-0 flex flex-col justify-center items-center gap-1'>
              <IconDone classname='w-12 h-12' />
              <h3 className='text-green-700 text-lg font-semibold'>{t('Authorization')}</h3>
            </div>
          )}
        </div>
        <div className='mt-4'>
          <p className='text-zinc-700'>{t('To cancel and close this page click End Session on your phone')}</p>
        </div>
        <div className='mt-4 mb-2'>
          <button
            type='button'
            disabled={mobileSessionIsActive}
            onClick={onClose}
            className=' text-red-500 text-base disabled:opacity-35'
            aria-label='Close this window'
          >
            {t('Close this window')}
          </button>
        </div>
      </div>
    </div>
  ) : null
}
