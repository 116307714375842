import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types/storeTypes'

const initialState: IStore['offerDicts'] = {
  offerDictsUploaded: false,
  offerDictsData: null,
  language: 'en'
}

export const offerDictsSLice = createSlice({
  name: 'offerDicts',
  initialState,
  reducers: {
    setOfferDictsInfo: (state, action) => {
      return { ...state, offerDictsUploaded: true, ...action.payload }
    },
    setOfferDictsCopiedItems: (state, action) => {
      if (state && state.offerDictsData && state.offerDictsData.items_for_copy) {
        return { ...state, offerDictsData: { ...state.offerDictsData, items_for_copy: action.payload} }

      } else {
        return state
      }
    },
  },
})

export const { setOfferDictsInfo, setOfferDictsCopiedItems } = offerDictsSLice.actions

export default offerDictsSLice.reducer
