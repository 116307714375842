import React, { useMemo, useRef, useState } from 'react'
import { Modal } from '../../modal'
import { IconClose } from '../../../images/IconClose'
import { useTranslation } from 'react-i18next'
import { DefaultButton } from '../../defaultButton'
import SignatureCanvas from 'react-signature-canvas'
import { IStore } from '../../../types/storeTypes'
import { Logo } from '../../../images/Logo'
import { useSelector } from 'react-redux'
import { useGDPR } from '../../../hooks/useGDPR'
import { useFormSession } from '../../../hooks/useFormSession'
import { useRelatedClient } from '../../../hooks/useRelatedClient'

interface Props {
  showed: boolean
  closeModal: () => void
  client: IStore['client']
  onFileLoaded: (value: string) => void
  isRelatedClient?: boolean
}

export const GDPRModal: React.FC<Props> = ({ showed, closeModal, client, onFileLoaded, isRelatedClient = false }) => {
  const { t } = useTranslation()
  const dicts = useSelector((store: IStore) => store.dicts)
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const canvas = useRef<any>(null)
  const [sign, setSign] = useState('')
  const [sendMarketing, setSendMarketing] = useState(false)
  const { uploadGDPR, uploadGDPRLoading } = useGDPR()
  const { uploadRelatedClientGDPR, uploadRelatedClientGDPRLoading } = useRelatedClient()
  const { saveSession } = useFormSession()

  const onClose = () => {
    canvas.current.clear()
    closeModal()
  }

  const onCancel = () => {
    // @ts-ignore
    canvas.current.clear()
    closeModal()
  }

  const onClear = () => {
    // @ts-ignore
    canvas.current.clear()
  }

  const onSave = async () => {
    if (isRelatedClient) {
      await uploadRelatedClientGDPR(
        {
          first_name: client.first_name,
          last_name: client.last_name,
          email: client.email,
          phone: client.phone_no,
          signature: sign,
          send_marketing: sendMarketing,
          country: client.country || '',
          city: client.municipality || '',
          address1: client.address1 || '',
          address2: client.address2 || '',
          postal_code: client.postal_code || '',
          id: client.id ? client.id : undefined,
        },
        (url) => {
          onFileLoaded(url)
        }
      )
    } else {
      await uploadGDPR(
        {
          first_name: client.first_name,
          last_name: client.last_name,
          email: client.email,
          phone: client.phone_no,
          id_number: client.id_number,
          source_id: client.ns_lead_source,
          signature: sign,
          send_marketing: sendMarketing,
          country: client.country || '',
          state: client.province || '',
          city: client.municipality || '',
          address1: client.address1 || '',
          address2: client.address2 || '',
          postal_code: client.postal_code || '',
        },
        (url) => {
          saveSession({
            send_marketing: sendMarketing,
          })
          onFileLoaded(url)
        }
      )
    }
    closeModal()
  }

  const source = useMemo(() => {
    if (client.ns_lead_source && dicts && dicts.sources) {
      const fromDicts = dicts.sources.find((source) => source.id === client.ns_lead_source)
      if (fromDicts) return fromDicts.text
      else return ''
    }

    return ''
  }, [client.ns_lead_source, dicts])

  const country = useMemo(() => {
    if (client.country && dicts && dicts.countries) {
      const fromDicts = dicts.countries[client.country]
      if (fromDicts) return fromDicts
      else return ''
    }

    return ''
  }, [client.country, dicts])

  const appointmentOffice = useMemo(() => {
    return appointmentInfo.appointment?.office.name || appointmentInfo.intermediate_save?.office?.name || ''
  }, [appointmentInfo.appointment?.office.name, appointmentInfo.intermediate_save?.office?.name])

  return (
    <Modal showed={showed} bodyStyle='w-[1080px] !max-w-[95vw] xl:!max-w-[80vw] relative'>
      <div className='absolute top-3 right-5'>
        <button type='button' onClick={onClose}>
          <IconClose />
        </button>
      </div>

      {client.id && (
        <div className='flex flex-col gap-6 max-h-[90vh] overflow-y-auto custom_scroll pr-2 mt-2'>
          <div className='w-full flex flex-col items-start pr-2 md:pr-12 text-zinc-800 text-lg font-medium'>
            <Logo classname='w-[200px]' />
            <div className='flex flex-col gap-1 pl-2 mt-3'>
              <div className=''>
                N: {client.first_name} {client.last_name}
              </div>
              <div className=''>
                D:{' '}
                {[
                  `${client.address1 ? `${client.address1}${client.address2 ? ' ' : ''}` : ''}${client.address2 ? client.address2 : ''}`,
                  client.municipality,
                  client.postal_code,
                  country,
                ]
                  .filter((value) => !!value)
                  .join(', ')}
              </div>
              {client.phone_no ? <div className=''>T: {client.phone_no}</div> : null}
              {client.email ? <div className=''>E: {client.email}</div> : null}
              <div className='flex gap-4'>
                {client.id_number ? (
                  <div className=''>
                    {t('DNI')}: {client.id_number}
                  </div>
                ) : null}
                {source ? (
                  <div className=''>
                    {t('FUENTE')}: {source}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className='flex flex-col gap-2 text-lg'>
            <div className=''>{appointmentOffice.includes('Madrid') ? t('GDPR_text_Madrid_1') : t('GDPR_text_1')}</div>
            <div className='w-full flex items-center text-center'>
              <input
                type='checkbox'
                name='offer_made'
                id='input-item-offer_made'
                checked={!!sendMarketing}
                onChange={() => setSendMarketing(!sendMarketing)}
                className={`form-input w-[24px] h-[24px] accent-green-700`}
                autoComplete='false'
              />
              <label htmlFor='input-item-offer_made' className={`flex form-offer-item_label pl-3 text-center`}>
                {t('ACEPTO RECIBIR INFORMACION DE LOS SERVICIOS DE CIRCA')}
              </label>
            </div>
            <div className=''>{appointmentOffice.includes('Madrid') ? t('GDPR_text_Madrid_2') : t('GDPR_text_2')}</div>
          </div>

          {showed && (
            <div className=' rounded-lg border border-zinc-400 border-dashed p-6 pt-1 pb-3'>
              <SignatureCanvas
                canvasProps={{
                  height: 260,
                  className: 'w-full border-b border-zinc-400',
                }}
                onEnd={() => {
                  if (canvas?.current?.isEmpty()) return null
                  setSign(canvas?.current?.toDataURL())
                }}
                ref={canvas}
              />
              <div className='text-lg text-zinc-400 mt-3 text-center'>{t('Draw signature')}</div>
            </div>
          )}

          <div className='flex flex-col justify-between md:flex-row gap-4 md:gap-8 w-full md:w-auto'>
            <DefaultButton
              title={t('Clear signature')}
              classname='text_button !text-red-400  w-full md:w-[200px]'
              onClick={onClear}
              disabled={uploadGDPRLoading || uploadRelatedClientGDPRLoading}
            />
            <div className='flex flex-col justify-end md:flex-row gap-4 md:gap-8 w-full md:w-auto'>
              <DefaultButton
                title={t('Cancel')}
                classname='text_button  w-full md:w-[200px]'
                onClick={onCancel}
                disabled={uploadGDPRLoading || uploadRelatedClientGDPRLoading}
              />
              <DefaultButton
                title={t('Done')}
                classname='main_button w-full md:w-[200px]'
                disabled={!sign || uploadGDPRLoading || uploadRelatedClientGDPRLoading}
                onClick={onSave}
                loading={uploadGDPRLoading || uploadRelatedClientGDPRLoading}
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}
