import React from 'react'

export const IconArrows = ({ classname = 'text-zinc-500' }) => {
  return (
    <svg
      className={`${classname}`}
      width='14'
      height='12'
      viewBox='0 0 14 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.57511 5.9998L0.700109 2.0998C0.516776 1.91647 0.420943 1.6873 0.412609 1.4123C0.404276 1.1373 0.500109 0.899805 0.700109 0.699805C0.883443 0.516471 1.11678 0.424805 1.40011 0.424805C1.68344 0.424805 1.91678 0.516471 2.10011 0.699805L6.70011 5.2998C6.80011 5.3998 6.87094 5.50814 6.91261 5.6248C6.95428 5.74147 6.97511 5.86647 6.97511 5.9998C6.97511 6.13314 6.95428 6.25814 6.91261 6.3748C6.87094 6.49147 6.80011 6.5998 6.70011 6.6998L2.10011 11.2998C1.91678 11.4831 1.68761 11.579 1.41261 11.5873C1.13761 11.5956 0.900109 11.4998 0.700109 11.2998C0.516776 11.1165 0.425109 10.8831 0.425109 10.5998C0.425109 10.3165 0.516776 10.0831 0.700109 9.89981L4.57511 5.9998ZM11.1751 5.9998L7.30011 2.0998C7.11678 1.91647 7.02094 1.6873 7.01261 1.4123C7.00428 1.1373 7.10011 0.899805 7.30011 0.699805C7.48344 0.516471 7.71678 0.424805 8.00011 0.424805C8.28344 0.424805 8.51678 0.516471 8.70011 0.699805L13.3001 5.2998C13.4001 5.3998 13.4709 5.50814 13.5126 5.6248C13.5543 5.74147 13.5751 5.86647 13.5751 5.9998C13.5751 6.13314 13.5543 6.25814 13.5126 6.3748C13.4709 6.49147 13.4001 6.5998 13.3001 6.6998L8.70011 11.2998C8.51678 11.4831 8.28761 11.579 8.01261 11.5873C7.73761 11.5956 7.50011 11.4998 7.30011 11.2998C7.11678 11.1165 7.02511 10.8831 7.02511 10.5998C7.02511 10.3165 7.11678 10.0831 7.30011 9.89981L11.1751 5.9998Z'
        fill='currentColor'
      />
    </svg>
  )
}
