import React from 'react'
import { Header } from '../header'

interface LayoutWrapperProps {
  children: React.ReactNode
}

export const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ children }) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      {/* <div>Footer</div> */}
    </>
  )
}
