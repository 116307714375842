import React, { useEffect } from 'react'
import { LayoutWrapper } from './components/layoutWrapper'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Home, P404 } from './pages'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string'
import { LOCAL_ACCESS_TOKEN } from './utils/consts'
import AddPhoto from './pages/AddPhoto'
import { MessageModal } from './components/modals/messageModal'
import { useDispatch, useSelector } from 'react-redux'
import { IStore } from './types/storeTypes'
import { hideGlobalPopup } from './store/globalPopupSlice'
import { AddSignature } from './pages/AddSignature'
import { AddPaymentData } from './pages/AddPaymentData'
import { FormClosed } from './pages/FormClosed'
import 'moment/min/locales'

function App() {
  const { pathname, search } = useLocation()
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const languageRoutesList = [...i18n.languages, '']
  const globalPopup = useSelector((store: IStore) => store.globalPopup)
  const dispatch = useDispatch()

  useEffect(() => {
    const pathLng = pathname.split('/').filter((str) => !!str)
    const currentLanguage = i18n.language
    const list = i18n.languages

    if (!pathLng || !pathLng[0] || pathLng[0] !== currentLanguage) {
      const path = !!pathLng[0] && list.includes(pathLng[0]) ? pathname.replace(`/${pathLng[0]}`, '') : pathname
      navigate('/' + currentLanguage + path + search)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  useEffect(() => {
    const { token } = queryString.parse(search)
    if (token && typeof token === 'string') {
      sessionStorage.setItem(LOCAL_ACCESS_TOKEN, token)
      sessionStorage.removeItem('2_step_items')
      sessionStorage.removeItem('3_step_items')
      document.location.search = ''
    }
  }, [search])

  return (
    <>
      <LayoutWrapper>
        <Routes>
          {languageRoutesList.map((language) => {
            return (
              <React.Fragment key={language}>
                <Route key={`home-${language}`} path={`/${language}`} element={<Home />} />
                <Route key={`add-photo-${language}`} path={`/${language}/add-photo`} element={<AddPhoto />} />
                <Route
                  key={`add-signature-${language}`}
                  path={`/${language}/add-signature`}
                  element={<AddSignature />}
                />
                <Route
                  key={`add-payment-data-${language}`}
                  path={`/${language}/add-payment-data`}
                  element={<AddPaymentData />}
                />
                <Route key={`form-closed-${language}`} path={`/${language}/form-closed`} element={<FormClosed />} />
              </React.Fragment>
            )
          })}
          <Route path={`*`} element={<P404 />} />
        </Routes>
        {!!globalPopup && (
          <MessageModal
            opened={!!globalPopup}
            title={globalPopup.title}
            text={globalPopup.text}
            onClose={() => dispatch(hideGlobalPopup())}
            type={globalPopup.type}
          />
        )}
      </LayoutWrapper>
    </>
  )
}

export default App
