import React from 'react'

export const Logo = ({ classname = '' }) => {
  return (
    <svg
      width='302'
      height='48'
      viewBox='0 0 302 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${classname}`}
    >
      <path
        d='M54.3133 15.0347C53.9402 14.363 53.567 13.7444 53.1405 13.1257C52.1455 11.7647 50.6708 10.4213 49.2493 9.55515C47.206 8.26481 42.7996 6.40884 36.6164 6.40884C34.8751 6.40884 28.1943 6.53256 23.0594 10.0501C22.633 10.4213 21.6379 11.093 20.5896 12.2596C18.9194 14.0448 16.5742 17.4917 16.5742 23.6606C16.5742 25.4458 16.7518 30.3774 20.0388 34.6196C23.9478 39.675 30.3799 40.8416 32.2277 41.1421C34.3243 41.4426 36.563 41.4426 37.2382 41.4426C39.2104 41.4426 47.7035 41.5133 52.9095 35.8393C53.9579 34.7257 54.5798 33.8065 55.3261 32.5162L61.8824 33.8773C60.461 36.4049 59.6614 37.5185 58.7907 38.5614C51.6658 46.8691 39.0328 46.8691 36.492 46.8691C27.8212 46.8691 22.7574 44.4651 19.648 42.5031C18.5286 41.7607 17.658 41.089 16.6808 40.2229C16.0589 39.6043 14.7085 38.2609 13.4648 36.2812C11.919 33.948 10 30.183 10 23.7136C10 21.6278 10.1243 15.9008 13.8378 10.7925C14.4597 9.87333 15.2593 8.88347 17.0006 7.34566C18.5464 6.05531 20.3409 4.818 22.0822 3.95188C27.7146 1.12373 34.7152 1 36.3854 1C38.4287 1 46.5487 1.17675 52.8563 4.99475C53.7269 5.54271 55.5215 6.727 57.3693 8.61833C59.3416 10.7218 60.4077 12.6308 60.9585 13.6029L54.3133 15.0347Z'
        fill='#27272A'
      />
      <path d='M86.3154 1H79.1592V43.4615H86.3154V1Z' fill='#27272A' />
      <path
        d='M133.255 1.01726C136.954 1.08631 138.317 1.20714 140.158 1.67318C146.017 3.17487 149.345 7.62814 149.345 13.4623C149.345 15.7407 148.849 21.6957 142.317 24.3366C141.202 24.7681 140.034 25.1133 137.574 25.4758L153.681 43.4615H145.592L130.847 26.4424H109.819V43.4615H103.588V1H133.255V1.01726ZM109.765 21.454H131.29C134.812 21.454 138.937 21.3332 141.344 18.3298C141.963 17.6049 142.955 16.1722 142.955 13.7039C142.955 12.9272 142.901 11.3047 141.839 9.56136C139.981 6.36812 137.096 6.12646 133.697 6.07467H109.765V21.454Z'
        fill='#27272A'
      />
      <path
        d='M215.268 15.0748C214.895 14.4011 214.521 13.7807 214.095 13.1603C213.117 11.7954 211.606 10.4482 210.184 9.57957C208.14 8.28554 203.75 6.42427 197.547 6.42427C195.805 6.42427 189.122 6.54835 183.985 10.0759C183.559 10.4482 182.563 11.1218 181.515 12.2917C179.844 14.0821 177.48 17.5387 177.48 23.7252C177.48 25.5156 177.658 30.4613 180.946 34.7156C184.856 39.7854 191.291 40.9553 193.157 41.2566C195.254 41.558 197.494 41.558 198.169 41.558C200.142 41.558 208.638 41.6289 213.846 35.9387C214.894 34.822 215.517 33.9002 216.263 32.6062L222.839 33.9711C221.418 36.506 220.618 37.6227 219.747 38.6686C212.619 47 200 47 197.458 47C188.784 47 183.701 44.5892 180.608 42.6216C179.489 41.8771 178.635 41.2035 177.64 40.3349C177.018 39.7144 175.667 38.3672 174.423 36.3819C172.877 34.042 170.957 30.2663 170.957 23.7784C170.957 21.6867 171.081 15.9434 174.796 10.8204C175.418 9.89865 176.218 8.90597 177.96 7.36377C179.506 6.06974 181.302 4.8289 183.043 3.96031C188.678 1.12408 195.681 1 197.352 1C199.396 1 207.501 1.17725 213.828 5.00616C214.699 5.55567 216.494 6.74335 218.343 8.64007C220.316 10.7495 221.382 12.664 221.933 13.6389L215.268 15.0748Z'
        fill='#27272A'
      />
      <path
        d='M269.518 1L292.001 43.4615H284.798L279.449 33.0015H252.492L247.143 43.4615H240.118L262.85 1H269.518ZM255.094 28.1167H276.775L267.521 10.2C266.719 8.57749 266.523 8.14597 266.042 6.41989C265.596 7.67992 265.418 8.28406 264.366 10.4417L255.094 28.1167Z'
        fill='#27272A'
      />
    </svg>
  )
}
