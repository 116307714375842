import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types/storeTypes'

const initialState: IStore['dicts'] | null = null

export const dictsSlice = createSlice({
  name: 'dicts',
  initialState,
  reducers: {
    setDictsInfo: (state, action) => {
      return action.payload
    },
    updateDictsInfo: (state, action) => {
      const oldData = state ? state : {}
      return {
        ...oldData,
        ...action.payload,
      }
    },
  },
})

export const { setDictsInfo, updateDictsInfo } = dictsSlice.actions

export default dictsSlice.reducer
