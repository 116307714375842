import React, { useEffect, useMemo, useState } from 'react'
import Select, { components } from 'react-select'
import { matchSorter } from 'match-sorter'
import { TReactSelectOption } from '../../types/additionalTypes'
import { useTranslation } from 'react-i18next'

interface Props {
  value: any
  options: TReactSelectOption[]
  onChange: (value: string, fullValue: TReactSelectOption) => void
  isClearable?: boolean
  selectId?: string
  inputId?: string
  containerStyles: string
  controlFocusedStyles: string
  controlDefaultStyles: string
  inputStyles: string
  dropdownIndicatorStyles?: string
  disableSort?: boolean
}

const optionsId = [365, 408, 793, 406, 301, 344, 551, 4, 761, 294, 891, 755, 635, 985, 398, 733, 252, 1001]

export const SourceSelect: React.FC<Props> = ({
  selectId,
  inputId,
  value,
  options,
  isClearable = false,
  onChange,
  containerStyles,
  controlFocusedStyles,
  controlDefaultStyles,
  inputStyles,
  dropdownIndicatorStyles,
  disableSort = false,
}) => {
  const [selectOptions, setSelectOptions] = useState<TReactSelectOption[]>([])
  const [showMore, setShowMore] = useState(false)
  const { t } = useTranslation()

  const optionList = useMemo(() => {
    const list = [...optionsId]
    const selectedValue = value && value.id ? (value.id as number) : undefined
    if (selectedValue) list.push(selectedValue)
    return showMore ? options : options.filter((option) => list.includes(+option.id))
  }, [value, showMore, options])

  useEffect(() => {
    setSelectOptions(optionList)
  }, [optionList])

  const SelectMenuButton = (props: any) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        <button
          type='button'
          onClick={() => setShowMore(!showMore)}
          className='font-bold py-2 px-3 hover:bg-slate-300 w-full text-start'
        >
          {showMore ? t('Show Less') : t('Other')}
        </button>
      </components.MenuList>
    )
  }

  return (
    <Select
      components={{ MenuList: SelectMenuButton }}
      id={selectId}
      inputId={inputId}
      value={value}
      classNames={{
        container: () => containerStyles,
        control: (state) => (state.isFocused ? controlFocusedStyles : controlDefaultStyles),
        input: () => inputStyles,
        dropdownIndicator: () => dropdownIndicatorStyles || '',
        valueContainer: () => '!pt-0',
      }}
      options={selectOptions}
      onChange={(e) => onChange(e?.value || '', e)}
      onMenuClose={() => setShowMore(false)}
      isClearable={isClearable}
      onInputChange={(inputValue) => {
        if (!disableSort) setSelectOptions(matchSorter(optionList, inputValue, { keys: ['label'] }))
      }}
    />
  )
}
