import React, { useEffect, useRef, useState } from 'react'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { callApi } from '../utils/callApi'
import { Loader } from '../components/loader'
import { IUploadSignatureData, useOfferAgreements } from '../hooks/useOfferAgreements'
import { LOCAL_ACCESS_TOKEN } from '../utils/consts'
import { DefaultButton } from '../components/defaultButton'
import parse from 'html-react-parser'
import ReactSignatureCanvas from 'react-signature-canvas'
import { IconDone } from '../images/IconDone'

export const AddSignature = () => {
  const location = useLocation()
  const { upload_token, signature_type, amount, user_token } = queryString.parse(location.search)
  const { t } = useTranslation()
  const { uploadSignature, uploadSignatureLoading } = useOfferAgreements()
  const [error, setError] = useState<any>(null)
  const [signatureAreUploaded, setSignatureAreUploaded] = useState(false)
  const canvas = useRef<any>(null)
  const [sign, setSign] = useState('')

  const onEndSession = () => {
    callApi({
      method: 'DELETE',
      url: `/v1/booking/images/flag?token=${upload_token}`,
    })
      .then()
      .catch(console.error)
      .finally()
  }

  const onSave = () => {
    if (sign && typeof signature_type === 'string') {
      uploadSignature({
        signature: sign,
        agreement: signature_type as IUploadSignatureData['agreement'],
      })
        .then(() => setSignatureAreUploaded(true))
        .catch((e) => setError(e))
    }
  }

  const onClear = () => {
    // @ts-ignore
    canvas.current.clear()
  }

  useEffect(() => {
    if (user_token && typeof user_token === 'string') sessionStorage.setItem(LOCAL_ACCESS_TOKEN, user_token)
    else sessionStorage.removeItem(LOCAL_ACCESS_TOKEN)
  }, [user_token])

  useEffect(() => {
    callApi({
      method: 'POST',
      url: `/v1/booking/images/flag?token=${upload_token}`,
    })
      .then()
      .catch(console.error)
  }, [upload_token])

  return (
    <div className='bg-white mx-[-16px] flex flex-col border border-zinc-300 justify-between overflow-hidden landscape:max-h-fit'>
      {signatureAreUploaded ? (
        <div className='h-[100vh] flex justify-center items-center text-xl font-medium'>
          <div className='flex pb-6 gap-2'>
            <IconDone />
            {t('Signature uploaded')}
          </div>
        </div>
      ) : (
        <>
          <div className='flex flex-col'>
            {uploadSignatureLoading ? (
              <div className='flex justify-center items-center'>
                <Loader className='!mx-auto flex justify-center items-center' />
              </div>
            ) : (
              <>
                <div className='text-2xl font-medium text-zinc-800 px-4 pb-2 pt-6'>{t('Your Signature')}</div>

                <div className='bg-zinc-100 flex flex-col border-t border-x-zinc-200 p-4'>
                  <div className='text-base font-medium text-zinc-700 flex justify-between gap-3'>
                    <div className=''>
                      {signature_type === 'purchase'
                        ? t('Total Purchase Amount')
                        : signature_type === 'consignment'
                          ? t('Total Consigned Amount')
                          : t('Total Offer Amount')}
                    </div>

                    <div className='text-lg'>{amount}</div>
                  </div>

                  <div className='mt-2'>
                    {signature_type === 'purchase'
                      ? parse(t('signature_purchase_agreement'))
                      : parse(t('signature_consignment_agreement'))}
                  </div>
                </div>

                {upload_token && (
                  <div className='bg-white p-4 h-[300px]'>
                    <div className='rounded-lg border border-zinc-400 border-dashed p-6 pt-1 pb-3 relative'>
                      <ReactSignatureCanvas
                        canvasProps={{
                          className: 'w-full border-b border-zinc-400 h-[200px]',
                        }}
                        onEnd={() => {
                          if (canvas?.current?.isEmpty()) return null
                          setSign(canvas?.current?.toDataURL())
                        }}
                        ref={canvas}
                        clearOnResize={false}
                      />
                      <div className='text-lg text-zinc-400 mt-3 text-center '>{t('Draw signature')}</div>
                      <button
                        type='button'
                        className=' absolute bottom-2 right-2 !py-1 text_button !text-blue-500'
                        onClick={onClear}
                      >
                        {t('Clear')}
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className='flex flex-row gap-4 md:gap-8 w-full px-4 py-2 [&>div]:w-1/2 border-t border-zinc-200'>
            <DefaultButton
              title={t('Cancel')}
              classname='text_button !w-full'
              disabled={uploadSignatureLoading}
              onClick={onEndSession}
            />
            <DefaultButton
              title={t('Done')}
              classname='main_button !w-full'
              disabled={uploadSignatureLoading || !sign}
              onClick={onSave}
              loading={uploadSignatureLoading}
            />
          </div>
        </>
      )}
      {!!error && (
        <div className='text-center mt-3'>
          <p className='text-red-700 text-xs'>{JSON.stringify(error?.message || error)}</p>
        </div>
      )}
    </div>
  )
}
