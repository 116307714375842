import React from 'react'

export const IconNotes = ({ classname = 'text-zinc-500' }) => {
  return (
    <svg
      className={`${classname}`}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17 14H7C6.71667 14 6.47917 13.9042 6.2875 13.7125C6.09583 13.5208 6 13.2833 6 13C6 12.7167 6.09583 12.4792 6.2875 12.2875C6.47917 12.0958 6.71667 12 7 12H17C17.2833 12 17.5208 12.0958 17.7125 12.2875C17.9042 12.4792 18 12.7167 18 13C18 13.2833 17.9042 13.5208 17.7125 13.7125C17.5208 13.9042 17.2833 14 17 14ZM17 11H7C6.71667 11 6.47917 10.9042 6.2875 10.7125C6.09583 10.5208 6 10.2833 6 10C6 9.71667 6.09583 9.47917 6.2875 9.2875C6.47917 9.09583 6.71667 9 7 9H17C17.2833 9 17.5208 9.09583 17.7125 9.2875C17.9042 9.47917 18 9.71667 18 10C18 10.2833 17.9042 10.5208 17.7125 10.7125C17.5208 10.9042 17.2833 11 17 11ZM17 8H7C6.71667 8 6.47917 7.90417 6.2875 7.7125C6.09583 7.52083 6 7.28333 6 7C6 6.71667 6.09583 6.47917 6.2875 6.2875C6.47917 6.09583 6.71667 6 7 6H17C17.2833 6 17.5208 6.09583 17.7125 6.2875C17.9042 6.47917 18 6.71667 18 7C18 7.28333 17.9042 7.52083 17.7125 7.7125C17.5208 7.90417 17.2833 8 17 8ZM20 18C20.55 18 21.0208 17.8042 21.4125 17.4125C21.8042 17.0208 22 16.55 22 16V4C22 3.45 21.8042 2.97917 21.4125 2.5875C21.0208 2.19583 20.55 2 20 2H4C3.45 2 2.97917 2.19583 2.5875 2.5875C2.19583 2.97917 2 3.45 2 4V19.575C2 20.025 2.20417 20.3375 2.6125 20.5125C3.02083 20.6875 3.38333 20.6167 3.7 20.3L6 18H20ZM5.15 16L4 17.125V4H20V16H5.15Z'
        fill='currentColor'
      />
    </svg>
  )
}
