import { useState } from 'react'
import { IInternalNote } from '../types/storeTypes'
import { useDispatch } from 'react-redux'
import { callApi } from '../utils/callApi'
import { ApiRoutes } from '../utils/apiRoutes'
import { deleteOfferInternalNote, setOfferInternalNotes } from '../store/offerInfoSlice'
import { showGlobalPopup } from '../store/globalPopupSlice'

export const useOfferNotes = () => {
  const [deleteNoteLoading, setDeleteNoteLoading] = useState({ id: 0, value: false })
  const [editNoteLoading, setEditNoteLoading] = useState({ id: 0, value: false })
  const [createNoteLoading, setCreateNoteLoading] = useState(false)
  const dispatch = useDispatch()

  const getOfferNotes = async () => {
    try {
      const resp: any = await callApi({
        method: 'GET',
        url: `${ApiRoutes.internalNote}`,
      })

      if (resp) {
        const data = resp.data.data
        if (data) {
          dispatch(setOfferInternalNotes(data))
        }
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
    }
  }

  const createOfferNote = async (text: string | string[], cb?: () => void) => {
    setCreateNoteLoading(true)
    const data = {
      note: text,
    }
    try {
      const resp: any = await callApi({
        method: 'POST',
        url: `${ApiRoutes.internalNote}`,
        data,
      })

      if (resp) {
        const data = resp.data.data
        if (data) {
          dispatch(setOfferInternalNotes(data))
          if (cb) cb()
        }
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setCreateNoteLoading(false)
    }
  }

  const deleteOfferNote = async (note: IInternalNote) => {
    setDeleteNoteLoading({ id: note.id, value: true })
    try {
      const resp: any = await callApi({
        method: 'DELETE',
        url: `${ApiRoutes.internalNote}/${note.id}`,
      })

      if (resp) {
        dispatch(deleteOfferInternalNote(note))
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setDeleteNoteLoading({ id: note.id, value: false })
    }
  }

  const editOfferNote = async (note: IInternalNote, cb?: () => void) => {
    setEditNoteLoading({ id: note.id, value: true })
    const data = {
      note: note.note,
    }
    try {
      const resp: any = await callApi({
        method: 'POST',
        url: `${ApiRoutes.internalNote}/${note.id}`,
        data,
      })

      if (resp) {
        const data = resp.data.data
        if (data) {
          dispatch(setOfferInternalNotes(data))
          if (cb) cb()
        }
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setEditNoteLoading({ id: note.id, value: false })
    }
  }
  return {
    deleteNoteLoading,
    createNoteLoading,
    editNoteLoading,
    deleteOfferNote,
    createOfferNote,
    editOfferNote,
    getOfferNotes,
  }
}
