import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types/storeTypes'

const initialState: IStore['globalPopup'] | null = null

export const globalPopupSlice = createSlice({
  name: 'globalPopup',
  initialState,
  reducers: {
    showGlobalPopup: (state, action) => {
      return action.payload
    },
    hideGlobalPopup: () => null,
  },
})

export const { showGlobalPopup, hideGlobalPopup } = globalPopupSlice.actions

export default globalPopupSlice.reducer
