import React from 'react'

export const IconAttach = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.5 11.8125C13.5 13.1125 13.0438 14.2187 12.1313 15.1312C11.2188 16.0437 10.1125 16.5 8.8125 16.5C7.5125 16.5 6.40625 16.0437 5.49375 15.1312C4.58125 14.2187 4.125 13.1125 4.125 11.8125V4.875C4.125 3.9375 4.45313 3.14062 5.10938 2.48437C5.76563 1.82812 6.5625 1.5 7.5 1.5C8.4375 1.5 9.23438 1.82812 9.89063 2.48437C10.5469 3.14062 10.875 3.9375 10.875 4.875V11.4375C10.875 12.0125 10.675 12.5 10.275 12.9C9.875 13.3 9.3875 13.5 8.8125 13.5C8.2375 13.5 7.75 13.3 7.35 12.9C6.95 12.5 6.75 12.0125 6.75 11.4375V5.25C6.75 5.0375 6.82188 4.85937 6.96563 4.71562C7.10938 4.57187 7.2875 4.5 7.5 4.5C7.7125 4.5 7.89063 4.57187 8.03438 4.71562C8.17813 4.85937 8.25 5.0375 8.25 5.25V11.4375C8.25 11.6 8.30313 11.7344 8.40938 11.8406C8.51563 11.9469 8.65 12 8.8125 12C8.975 12 9.10938 11.9469 9.21563 11.8406C9.32188 11.7344 9.375 11.6 9.375 11.4375V4.875C9.3625 4.35 9.17813 3.90625 8.82188 3.54375C8.46563 3.18125 8.025 3 7.5 3C6.975 3 6.53125 3.18125 6.16875 3.54375C5.80625 3.90625 5.625 4.35 5.625 4.875V11.8125C5.6125 12.7 5.91875 13.4531 6.54375 14.0719C7.16875 14.6906 7.925 15 8.8125 15C9.6875 15 10.4313 14.6906 11.0438 14.0719C11.6563 13.4531 11.975 12.7 12 11.8125V5.25C12 5.0375 12.0719 4.85937 12.2156 4.71562C12.3594 4.57187 12.5375 4.5 12.75 4.5C12.9625 4.5 13.1406 4.57187 13.2844 4.71562C13.4281 4.85937 13.5 5.0375 13.5 5.25V11.8125Z'
        fill='#3B82F6'
      />
    </svg>
  )
}
