import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { matchSorter } from 'match-sorter'
import { TReactSelectOption } from '../../types/additionalTypes'
import { useTranslation } from 'react-i18next'

interface Props {
  value: any
  options: TReactSelectOption[]
  onChange: (value: string, fullValue: TReactSelectOption) => void
  isClearable?: boolean
  selectId?: string
  inputId?: string
  containerStyles: string
  controlFocusedStyles: string
  controlDefaultStyles: string
  inputStyles: string
  dropdownIndicatorStyles?: string
  disableSort?: boolean
}

export const MultiSelect: React.FC<Props> = ({
  selectId,
  inputId,
  value,
  options,
  isClearable = false,
  onChange,
  containerStyles,
  controlFocusedStyles,
  controlDefaultStyles,
  inputStyles,
  dropdownIndicatorStyles,
  disableSort = false,
}) => {
  const [selectOptions, setSelectOptions] = useState<TReactSelectOption[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    setSelectOptions(options)
  }, [options])

  return (
    <Select
      id={selectId}
      inputId={inputId}
      value={value}
      placeholder={`${t('Select')}...`}
      classNames={{
        container: () => containerStyles,
        control: (state) => (state.isFocused ? controlFocusedStyles : controlDefaultStyles),
        input: () => inputStyles,
        dropdownIndicator: () => dropdownIndicatorStyles || '',
        valueContainer: () => '!pt-0',
      }}
      options={selectOptions}
      onChange={(e) => onChange(e?.value || '', e)}
      isClearable={isClearable}
      onInputChange={(inputValue) => {
        if (!disableSort) setSelectOptions(matchSorter(options, inputValue, { keys: ['label'] }))
      }}
    />
  )
}
