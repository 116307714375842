import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types/storeTypes'

const initialState: IStore['offerInfo'] = {
  id: 0,
  status: '',
  total_acquired_amount: 0,
  currency: 'USD',
  offerItems: [],
  items: [],
  internal_notes: [],
  offerInfoUploaded: false,
  send_client_satisfaction: false,
  send_marketing: false,
  send_thank_you: false,
  show_form_in_account: false,
  expiration_date: '',
  followup_date: '',
}

export const offerInfoSlice = createSlice({
  name: 'offerInfo',
  initialState,
  reducers: {
    setOfferInfo: (state, action) => {
      return action.payload
    },
    setOfferItems: (state, action) => {
      return {
        ...state,
        offerItems: action.payload,
      }
    },
    setOfferInternalNotes: (state, action) => {
      return {
        ...state,
        internal_notes: action.payload,
      }
    },
    addOfferInternalNote: (state, action) => {
      return {
        ...state,
        internal_notes: [...state.internal_notes, action.payload],
      }
    },
    deleteOfferInternalNote: (state, action) => {
      return {
        ...state,
        internal_notes: state.internal_notes.filter((note) => note.id !== action.payload.id),
      }
    },
  },
})

export const { setOfferInfo, setOfferItems, setOfferInternalNotes, addOfferInternalNote, deleteOfferInternalNote } =
  offerInfoSlice.actions

export default offerInfoSlice.reducer
