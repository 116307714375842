import React, { useState } from 'react'
import { Modal } from '../../modal'
import { IconClose } from '../../../images/IconClose'
import { useTranslation } from 'react-i18next'
import { DefaultButton } from '../../defaultButton'
import { IPlaidData } from '../../../types/additionalTypes'
import { hideSymbols } from '../../../utils/hideSymbols'

interface Props {
  showed: boolean
  closeModal: () => void
  onDataSave: (data: IPlaidData) => void
  accountsList: IPlaidData[]
}

export const SelectPlaidAccountModal: React.FC<Props> = ({ showed, closeModal, onDataSave, accountsList }) => {
  const { t } = useTranslation()
  const [selectedAccount, setSelectedAccount] = useState<IPlaidData | null>(null)

  const onClose = () => {
    setSelectedAccount(null)
    closeModal()
  }

  const onCancel = () => {
    setSelectedAccount(null)
    closeModal()
  }

  const onSave = async () => {
    if (selectedAccount) {
      onDataSave(selectedAccount)
    }
  }

  return (
    <Modal
      showed={showed}
      bodyStyle='w-screen h-screen md:h-auto !max-w-[800px] xl:!max-w-[80vw] relative overflow-y-auto'
    >
      <div className='absolute top-3 right-5'>
        <button type='button' onClick={onClose}>
          <IconClose />
        </button>
      </div>

      <div className='flex flex-col gap-6 mt-6'>
        <div className='w-full flex items-center pr-2 md:pr-12 text-zinc-800 text-[28px] leading-9 font-medium'>
          {t(`Select which account you'd like us to send your money to`)}:
        </div>

        <div className='flex gap-4 justify-between flex-col sm:flex-row'>
          {accountsList.map((account, index) => (
            <button
              type='button'
              className={`rounded border flex gap-10 text-start p-4 ${account.accountId === selectedAccount?.accountId ? 'bg-green-50 border-green-200' : 'bg-slate-50 border-slate-200'}`}
              onClick={() => setSelectedAccount(account)}
              key={`plaid_account-${index + 1}`}
            >
              <div className='flex flex-col gap-1'>
                <p className='text-base md:text-xl font-sans	font-normal text-gray-700'>{t('Bank name')}</p>
                <p className='text-base md:text-xl font-sans	font-normal text-gray-700'>{t('Account number')}</p>
                {account.routingNumber && (
                  <p className='text-base md:text-xl font-sans font-normal text-gray-700'>{t('Routing number')}</p>
                )}
                {account.accountType && (
                  <p className='text-base md:text-xl font-sans font-normal text-gray-700'>{t('Account type')}</p>
                )}
                {account.swiftCode && (
                  <p className='text-base md:text-xl font-sans font-normal text-gray-700'>{t('Swift code')}</p>
                )}
              </div>
              <div className='flex flex-col gap-1'>
                <p className='text-base md:text-xl font-sans	font-normal'>{account.bankName}</p>
                {account.accountNumber && (
                  <p className='text-base md:text-xl font-sans	font-normal'>
                    {hideSymbols(account.accountNumber || '', 4)}
                  </p>
                )}
                {account.routingNumber && (
                  <p className='text-base md:text-xl font-sans font-normal'>
                    {hideSymbols(account.routingNumber || '', 4)}
                  </p>
                )}
                {account.accountType && (
                  <p className='text-base md:text-xl font-sans font-normal'>{account.accountType}</p>
                )}
                {account.swiftCode && <p className='text-base md:text-xl font-sans font-normal'>{account.swiftCode}</p>}
              </div>
            </button>
          ))}
        </div>

        <div className='flex flex-col justify-between md:flex-row gap-4 md:gap-8 w-full md:w-auto'>
          <DefaultButton
            title={t('Cancel')}
            classname='text_button !text-red-400 w-full md:w-[200px]'
            onClick={onCancel}
          />
          <DefaultButton
            title={t('Save')}
            classname='main_button w-full md:w-[200px]'
            onClick={onSave}
            disabled={!selectedAccount}
          />
        </div>
      </div>
    </Modal>
  )
}
