import React from 'react'

export const IconDoneSimple = ({ classname = 'text-green-700x' }) => {
  return (
    <svg
      className={`${classname} text-green-700`}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.32966 16.4618L4.73716 12.8693C4.64322 12.7742 4.53134 12.6987 4.40801 12.6472C4.28467 12.5957 4.15233 12.5691 4.01867 12.5691C3.885 12.5691 3.75266 12.5957 3.62933 12.6472C3.50599 12.6987 3.39411 12.7742 3.30017 12.8693C3.20508 12.9632 3.12958 13.0751 3.07806 13.1985C3.02653 13.3218 3 13.4541 3 13.5878C3 13.7215 3.02653 13.8538 3.07806 13.9771C3.12958 14.1005 3.20508 14.2124 3.30017 14.3063L7.60089 18.607C8.0012 19.0073 8.64785 19.0073 9.04815 18.607L19.9283 7.73716C20.0234 7.64322 20.0989 7.53134 20.1504 7.40801C20.2019 7.28467 20.2284 7.15233 20.2284 7.01867C20.2284 6.885 20.2019 6.75266 20.1504 6.62933C20.0989 6.50599 20.0234 6.39411 19.9283 6.30017C19.8343 6.20508 19.7225 6.12958 19.5991 6.07806C19.4758 6.02653 19.3434 6 19.2098 6C19.0761 6 18.9438 6.02653 18.8204 6.07806C18.6971 6.12958 18.5852 6.20508 18.4913 6.30017L8.32966 16.4618Z'
        fill='currentColor'
      />
    </svg>
  )
}
