import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../../modal'
import { IconClose } from '../../../images/IconClose'
import { IStore } from '../../../types/storeTypes'
import { DefaultButton } from '../../defaultButton'
import { useDispatch, useSelector } from 'react-redux'
import { defaultClient } from '../../../utils/defaultClient'
import { IRelatedPerson } from '../../../types/additionalTypes'
import PhoneInput from 'react-phone-input-2'
import { parsePhoneNumber } from 'libphonenumber-js'
import { MultiSelect } from '../../multiSelect'
import { GDPRModal } from '../GDPRModal'
import { IconPdf } from '../../../images/IconPdf'
import { RelatedPersonSelect } from '../../relatedPersonSelect'
import { useRelatedClient } from '../../../hooks/useRelatedClient'
import { useFormSession } from '../../../hooks/useFormSession'
import { setRelatedPersons } from '../../../store/appointmentInfoSlice'

interface Props {
  showed: boolean
  closeModal: () => void
}

const requiredFields = ['first_name', 'last_name', 'email', 'phone_no', 'country', 'email']

export const AddRelatedPersonModal: React.FC<Props> = ({ showed, closeModal }) => {
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const dicts = useSelector((store: IStore) => store.dicts)
  const { t } = useTranslation()
  const [relatedPerson, setRelatedPerson] = useState<IRelatedPerson>(defaultClient)
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [showGDPRmodal, setShowGDPRmodal] = useState(false)
  const { createRelatedClient, updateRelatedClient, relatedClientLoading } = useRelatedClient()
  const { saveSession } = useFormSession()
  const dispatch = useDispatch()

  const onClose = () => {
    setFormErrors([])
    setRelatedPerson(defaultClient)
    closeModal()
  }

  useEffect(() => {
    if (appointmentInfo.intermediate_save && appointmentInfo.intermediate_save.related_person && showed) {
      setRelatedPerson(appointmentInfo.intermediate_save.related_person)
    }
  }, [appointmentInfo.intermediate_save, appointmentInfo.intermediate_save?.related_person, showed])

  const onSave = async () => {
    const errors = requiredFields
      .map((field) => {
        if (field === 'buyer') return ''
        else if (field === 'email') {
          if (!relatedPerson.email && relatedPerson.phone_no.length < 5) return field
        } else if (field === 'phone_no') {
          if (!relatedPerson.email && relatedPerson.phone_no.length < 5) return field
        } else if (field === 'postal_code') {
          if (!relatedPerson.postal_code && relatedPerson.country !== 'HK') return field
        } else if (!relatedPerson[field as keyof IRelatedPerson]) return field

        return ''
      })
      .filter((error) => !!error)

    if (errors.length > 0) {
      setFormErrors(errors)
      return null
    }

    const data = {
      ...relatedPerson,
      first_name: relatedPerson.first_name,
      last_name: relatedPerson.last_name,
      email: relatedPerson.email,
      phone: relatedPerson.phone_no && relatedPerson.phone_no.length > 5 ? relatedPerson.phone_no : '',
      country: relatedPerson.country,
      city: relatedPerson.municipality,
      address1: relatedPerson.address1,
      address2: relatedPerson.address2,
      postal_code: relatedPerson.postal_code,
      phone_no: undefined,
    }
    if (relatedPerson.id) {
      await updateRelatedClient(data, (resp) => {
        closeModal()
        saveSession({
          related_person: resp,
        })
      })
    } else {
      await createRelatedClient(data, (resp) => {
        closeModal()
        dispatch(setRelatedPersons([...appointmentInfo.related_persons, resp]))
        saveSession({
          related_person: resp,
        })
      })
    }
  }

  const phoneCountry = useMemo(() => {
    const phone = relatedPerson.phone_no ? relatedPerson.phone_no : ''
    if (!phone) return 'es'
    const parsedPhoneNumber = parsePhoneNumber(phone, 'ES')
    if (parsedPhoneNumber) {
      const regionCode = parsedPhoneNumber.country
      return regionCode?.toLowerCase()
    } else return 'es'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const countriesList = useMemo(() => {
    if (!dicts || !dicts.countries) return []
    const list = Object.entries(dicts.countries)
    return list.map((country) => {
      return { label: country[1], id: country[0], value: country[0] }
    })
  }, [dicts])

  const defaultCountry = useMemo(() => {
    return relatedPerson.country ? countriesList.find((country) => country.value === relatedPerson.country) : 'ES'
  }, [relatedPerson.country, countriesList])

  const editField = (name: string, value: string | number) => {
    if (name === 'email' || name === 'phone_no') {
      setFormErrors(formErrors.filter((error) => error !== 'email' && error !== 'phone_no'))
      setRelatedPerson({ ...relatedPerson, [name]: value })
    } else {
      setRelatedPerson({ ...relatedPerson, [name]: value })
      setFormErrors(formErrors.filter((error) => error !== name))
    }
  }

  const onGDPROpen = async () => {
    if (!relatedPerson.id) {
      const data = {
        ...relatedPerson,
        first_name: relatedPerson.first_name,
        last_name: relatedPerson.last_name,
        email: relatedPerson.email,
        phone: relatedPerson.phone_no && relatedPerson.phone_no.length > 5 ? relatedPerson.phone_no : '',
        country: relatedPerson.country,
        city: relatedPerson.municipality,
        address1: relatedPerson.address1,
        address2: relatedPerson.address2,
        postal_code: relatedPerson.postal_code,
        gdpr_url: undefined,
        phone_no: undefined,
      }
      await createRelatedClient(data, (resp) => {
        setRelatedPerson(resp)
        dispatch(setRelatedPersons([...appointmentInfo.related_persons, resp]))
        saveSession({
          related_person: resp,
        })
        setShowGDPRmodal(true)
      })
    } else {
      setShowGDPRmodal(true)
    }
  }

  const onGDPRFileLoaded = (url: string) => {
    setRelatedPerson({ ...relatedPerson, gdpr_url: url })
  }

  const onGDPRFileDelete = async () => {
    // await deleteGDPR(() => {
    setRelatedPerson({ ...relatedPerson, gdpr_url: undefined })
    // })
  }

  const disableSign =
    !relatedPerson.first_name || !relatedPerson.last_name || (!relatedPerson.email && !relatedPerson.phone_no)

  return (
    <Modal showed={showed} bodyStyle='!w-[600px] !max-w-[95vw] xl:!max-w-[80vw] relative'>
      <div className='absolute top-3 right-5'>
        <button type='button' onClick={onClose}>
          <IconClose />
        </button>
      </div>

      <div className='font-medium text-[28px] leading-9 text-zinc-800 mb-2'>
        {relatedPerson.id ? t('Edit Second person Details') : t('Add related person')}
      </div>

      <div className='flex flex-col gap-6 max-h-[80vh] overflow-y-auto custom_scroll mx-[-16px]'>
        <div className='form_block'>
          <div className='w-full flex items-center'>
            <label
              htmlFor='input-relatedPerson-country'
              className={`w-2/5 flex form_label ${formErrors.includes('country') ? 'error_label' : ''}`}
            >
              {t('Find client')}
            </label>
            <RelatedPersonSelect
              selectId='select-relatedPerson'
              inputId='input-relatedPerson'
              onChange={(value) => setRelatedPerson(value as IRelatedPerson)}
              value={relatedPerson}
              containerStyles='w-3/5'
              controlFocusedStyles='form-select form-select-focused'
              controlDefaultStyles='form-select'
              inputStyles='!text-zinc-900'
            />
          </div>

          <div className='w-full flex items-center'>
            <label
              htmlFor='input-relatedPerson-first_name'
              className={`w-2/5 flex form_label ${formErrors.includes('first_name') ? 'error_label' : ''}`}
            >
              {t('First Name')}
              {requiredFields.includes('first_name') ? <span className='text-red-500'>*</span> : ''}
            </label>
            <input
              type='text'
              name='first_name'
              id='input-relatedPerson-first_name'
              value={relatedPerson?.first_name || ''}
              onChange={(e) => editField('first_name', e.target.value)}
              className={`form-input w-3/5 ${formErrors.includes('first_name') ? 'error_field' : ''}`}
              autoComplete='false'
            />
          </div>

          <div className='w-full flex items-center'>
            <label
              htmlFor='input-relatedPerson-last_name'
              className={`w-2/5 flex form_label ${formErrors.includes('last_name') ? 'error_label' : ''}`}
            >
              {t('Last Name')}
              {requiredFields.includes('last_name') ? <span className='text-red-500'>*</span> : ''}
            </label>
            <input
              type='text'
              name='last_name'
              id='input-relatedPerson-last_name'
              value={relatedPerson?.last_name || ''}
              onChange={(e) => editField('last_name', e.target.value)}
              className={`form-input w-3/5 ${formErrors.includes('last_name') ? 'error_field' : ''}`}
              autoComplete='false'
            />
          </div>

          <div className='w-full flex items-center'>
            <label
              htmlFor='input-relatedPerson-email'
              className={`w-2/5 flex form_label ${formErrors.includes('email') ? 'error_label' : ''}`}
            >
              {t('Email Address')}
            </label>
            <input
              type='email'
              name='email'
              id='input-relatedPerson-email'
              value={relatedPerson.email || ''}
              onChange={(e) => editField('email', e.target.value)}
              className={`form-input w-3/5 ${formErrors.includes('email') ? 'error_field' : ''}`}
              autoComplete='false'
            />
          </div>

          <div className='w-full flex items-center'>
            <label
              htmlFor='input-relatedPerson-phone'
              className={`w-2/5 flex form_label ${formErrors.includes('phone_no') ? 'error_label' : ''}`}
            >
              {t('Phone')}
            </label>
            <PhoneInput
              country={phoneCountry}
              defaultMask={phoneCountry}
              value={relatedPerson.phone_no || ''}
              onChange={(phoneValue) => {
                let value = phoneValue
                if (value && value.length > 0 && value[0] !== '+') value = `+${value}`
                editField('phone_no', value)
              }}
              enableSearch
              specialLabel={''}
              containerClass={`w-3/5 !p-0 flex items-center`}
              preferredCountries={['us', 'es']}
              countryCodeEditable
              autocompleteSearch
              inputClass={`form-input-phone ${formErrors.includes('phone_no') ? 'error_field' : ''}`}
              inputStyle={{ width: '100%' }}
              buttonClass={`form-input-phone-button ${formErrors.includes('phone_no') ? 'error_field' : ''}`}
              buttonStyle={{
                borderRight: '1px solid #cacaca',
              }}
              dropdownStyle={{ height: '160px', width: '300px' }}
              searchStyle={{ width: '90%' }}
              inputProps={{ name: 'phone', required: true, id: 'input-relatedPerson-phone' }}
            />
          </div>

          <div className='w-full flex items-center'>
            <label
              htmlFor='input-relatedPerson-country'
              className={`w-2/5 flex form_label ${formErrors.includes('country') ? 'error_label' : ''}`}
            >
              {t('Country')}
              {requiredFields.includes('country') ? <span className='text-red-500'>*</span> : ''}
            </label>
            <MultiSelect
              selectId='select-relatedPerson-country'
              inputId='input-relatedPerson-country'
              options={countriesList}
              onChange={(value) => editField('country', value)}
              value={defaultCountry}
              containerStyles='w-3/5'
              controlFocusedStyles='form-select form-select-focused'
              controlDefaultStyles={`form-select ${formErrors.includes('country') ? 'error_field' : ''}`}
              inputStyles='!text-zinc-900'
            />
          </div>

          <div className='w-full flex items-center'>
            <label
              htmlFor='input-relatedPerson-address1'
              className={`w-2/5 flex form_label ${formErrors.includes('address1') ? 'error_label' : ''}`}
            >
              {t('Street Address')}
              {requiredFields.includes('address1') ? <span className='text-red-500'>*</span> : ''}
            </label>
            <input
              type='text'
              name='address1'
              id='input-relatedPerson-address1'
              value={relatedPerson?.address1 || ''}
              onChange={(e) => editField('address1', e.target.value)}
              className={`form-input w-3/5 ${formErrors.includes('address1') ? 'error_field' : ''}`}
              autoComplete='false'
            />
          </div>

          <div className='w-full flex items-center'>
            <label
              htmlFor='input-relatedPerson-address2'
              className={`w-2/5 flex form_label ${formErrors.includes('address2') ? 'error_label' : ''}`}
            >
              {t('Apt, suite, unit etc')}
              {requiredFields.includes('address2') ? <span className='text-red-500'>*</span> : ''}
            </label>
            <input
              type='text'
              name='address2'
              id='input-relatedPerson-address2'
              value={relatedPerson?.address2 || ''}
              onChange={(e) => editField('address2', e.target.value)}
              className={`form-input w-3/5 ${formErrors.includes('address2') ? 'error_field' : ''}`}
              autoComplete='false'
            />
          </div>

          <div className='w-full flex items-center'>
            <label
              htmlFor='input-relatedPerson-municipality'
              className={`w-2/5 flex form_label ${formErrors.includes('municipality') ? 'error_label' : ''}`}
            >
              {t('City / Municipality')}
              {requiredFields.includes('municipality') ? <span className='text-red-500'>*</span> : ''}
            </label>
            <input
              type='text'
              name='municipality'
              id='input-relatedPerson-municipality'
              value={relatedPerson?.municipality || ''}
              onChange={(e) => editField('municipality', e.target.value)}
              className={`form-input w-3/5 ${formErrors.includes('municipality') ? 'error_field' : ''}`}
              autoComplete='false'
            />
          </div>

          {relatedPerson.country !== 'HK' && (
            <div className='w-full flex items-center'>
              <label
                htmlFor='input-relatedPerson-postal_code'
                className={`w-2/5 flex form_label ${formErrors.includes('postal_code') ? 'error_label' : ''}`}
              >
                {t('Zip / Postal Code')}
                {requiredFields.includes('postal_code') ? <span className='text-red-500'>*</span> : ''}
              </label>
              <input
                type='text'
                name='postal_code'
                id='input-relatedPerson-postal_code'
                value={relatedPerson?.postal_code || ''}
                onChange={(e) => editField('postal_code', e.target.value)}
                className={`form-input w-3/5 ${formErrors.includes('postal_code') ? 'error_field' : ''}`}
                autoComplete='false'
              />
            </div>
          )}

          <div className='w-full flex items-center'>
            <div className={`w-2/5 flex form_label ${formErrors.includes('GDPR') ? 'error_label' : ''}`}>
              {t('GDPR')}
              {requiredFields.includes('GDPR') ? <span className='text-red-500 pl-1'>*</span> : ''}
            </div>
            {relatedPerson.gdpr_url ? (
              <div className='w-3/5 flex items-center gap-2'>
                <a
                  href={relatedPerson.gdpr_url}
                  target='_blank'
                  rel='noopener noreferrer'
                  className=' w-auto flex justify-center items-center'
                >
                  <IconPdf classname='h-[54px] w-[54px]' />
                  <div className='text-xs text-zinc-500'>{t('GDPR file')}</div>
                </a>

                <button
                  type='button'
                  onClick={onGDPRFileDelete}
                  // disabled={deleteGDPRLoading}
                >
                  <IconClose />
                </button>
              </div>
            ) : (
              <span className='flex w-3/5 [&>div]:w-full'>
                <DefaultButton
                  title={t('Sign')}
                  onClick={onGDPROpen}
                  classname='!h-[38px] !w-full grey_button disabled:pointer-events-none'
                  disabled={disableSign}
                />
              </span>
            )}
          </div>

          <GDPRModal
            showed={showGDPRmodal}
            closeModal={() => setShowGDPRmodal(false)}
            client={relatedPerson}
            onFileLoaded={onGDPRFileLoaded}
            isRelatedClient
          />
        </div>

        <div className='flex justify-end px-4'>
          <DefaultButton
            title={t('Save')}
            onClick={() => onSave()}
            disabled={formErrors.length > 0 || relatedClientLoading}
            classname='main_button w-[200px]'
            loading={relatedClientLoading}
          />
        </div>
      </div>
    </Modal>
  )
}
