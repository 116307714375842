export const getCurrency = (amount: string | number, currency: string | undefined | null) => {
  return (+amount)
    .toLocaleString('en-US', {
      style: 'currency',
      currency: currency || 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
}

export const getCurrencyWithCents = (amount: string | number, currency: string | undefined | null) => {
  return (+amount)
    .toLocaleString('en-US', {
      style: 'currency',
      currency: currency || 'USD',
      minimumFractionDigits: 3,
      maximumFractionDigits: 2,
    })
}