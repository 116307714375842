import React from 'react'
import { Modal } from '../../modal'
import { useTranslation } from 'react-i18next'
import { DefaultButton } from '../../defaultButton'
import { useDispatch, useSelector } from 'react-redux'
import { hideCloseFormModal } from '../../../store/closeFormModalSlice'
import { IStore } from '../../../types/storeTypes'
import { LOCAL_ACCESS_TOKEN } from '../../../utils/consts'
import { useFormSession } from '../../../hooks/useFormSession'

export const CloseFormModal = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const closeFormModal = useSelector((store: IStore) => store.closeFormModal)
  const { stopCheckSession, stopCheckSessionRequest, stopCheckSessionLoading } = useFormSession()

  const onCancel = () => {
    dispatch(hideCloseFormModal())
  }

  const onClose = async () => {
    stopCheckSession()
    await stopCheckSessionRequest()
    dispatch(hideCloseFormModal())
    sessionStorage.removeItem(LOCAL_ACCESS_TOKEN)
    window.location.href = `/${i18n.language}/form-closed`
  }

  return (
    <Modal showed={closeFormModal} bodyStyle='!max-w-max'>
      <div className='flex flex-col space-y-12'>
        <div className={`text-[28px] leading-9 font-medium font-sans text-center`}>
          {t('Are you sure you want to exit the flow?')}
          <br />
          {t('All unsaved progress will be lost.')}
          <br />
          {t('Make sure you went to the next step before closing.')}
        </div>
        <div className='flex justify-end flex-row gap-4 md:gap-8 w-full [&>div]:w-1/2'>
          <DefaultButton
            title={t('Cancel')}
            classname='text_button !text-red-400 w-full'
            onClick={onCancel}
            disabled={stopCheckSessionLoading}
          />
          <DefaultButton
            title={t('Close')}
            classname='main_button w-full'
            onClick={onClose}
            disabled={stopCheckSessionLoading}
            loading={stopCheckSessionLoading}
          />
        </div>
      </div>
    </Modal>
  )
}
