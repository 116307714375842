import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { callApi } from '../utils/callApi'
import { PhotoUploaded } from '../components/photoUploaded'
import { Loader } from '../components/loader'

const LazyPhotoUploader = React.lazy(() => import('../components/photoUploader'))

const AddPhoto = () => {
  const location = useLocation()
  const { max, upload_token, country } = queryString.parse(location.search)
  const { t } = useTranslation()

  const [images, setImages] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)
  const [imagesAreUploaded, setImagesAreUploaded] = useState(false)

  const onEndSession = () => {
    callApi({
      method: 'DELETE',
      url: `/v1/booking/images/flag?token=${upload_token}`,
    })
      .then()
      .catch(console.error)
      .finally()
  }

  const onSave = () => {
    setLoading(true)
    callApi({
      method: 'POST',
      url: `/v1/booking/${country}/multi-images`,
      data: {
        token: upload_token,
        country: country,
        images,
      },
    })
      .then(() => setImagesAreUploaded(true))
      .catch((e) => setError(e))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    callApi({
      method: 'POST',
      url: `/v1/booking/images/flag?token=${upload_token}`,
    })
      .then()
      .catch(console.error)
  }, [upload_token])

  useEffect(() => {
    console.log(images, 'images')

    if (images.length) onSave()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images.length])

  return (
    <div className='bg-white py-10 px-3 flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 150px)' }}>
      {imagesAreUploaded ? (
        <PhotoUploaded images={images} />
      ) : (
        <>
          <div className='flex flex-col'>
            {loading ? (
              <Loader className='!mx-auto flex justify-center items-center' />
            ) : (
              <React.Suspense fallback={<Loader className='!mx-auto flex justify-center items-center' />}>
                <LazyPhotoUploader
                  maxFiles={Number(max || 1)}
                  email={''}
                  maxFileSize={10000000}
                  setImages={setImages}
                />
              </React.Suspense>
            )}
          </div>
          <button
            className='text-red-500 text-base mt-4 mb-10 font-semibold'
            onClick={onEndSession}
            id={'mobile_upload-btn_end_session'}
          >
            {t('End Session')}
          </button>
        </>
      )}
      {!!error && (
        <div className='text-center mt-3'>
          <p className='text-red-700 text-xs'>{JSON.stringify(error?.message || error)}</p>
        </div>
      )}
    </div>
  )
}

export default AddPhoto
