import React from 'react'

export const IconNoData = () => {
  return (
    <svg width='178' height='179' viewBox='0 0 178 179' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <ellipse cx='88.9993' cy='127.256' rx='81.5833' ry='14.1591' fill='#F4F4F5' />
      <path
        d='M71.2819 88.4385L26.7393 88.8453V131.16L147.473 130.753V88.8453L102.54 88.4557V90.4728L102.149 92.5072L101.758 95.3553L98.6326 100.238L94.7254 103.493L87.3016 105.12L82.2222 104.713L77.5335 101.865L73.6262 98.2034L71.6726 93.7278L72.0633 91.2866L71.2819 88.4385Z'
        fill='#FAFAFA'
      />
      <path
        d='M71.6719 89.2556C71.6719 98.0192 78.4943 105.124 86.9102 105.124C95.3261 105.124 102.148 98.0192 102.148 89.2556M86.9102 104.368C78.9402 104.368 72.4275 97.631 72.4275 89.2556C72.4275 88.2138 71.6011 87.2803 70.488 87.2901L26.8298 87.675L52.4742 39.4379C52.8338 38.7614 53.5374 38.3386 54.3036 38.3386H120.666C121.441 38.3386 122.151 38.7713 122.507 39.46L147.399 87.6753L103.332 87.29C102.219 87.2803 101.393 88.2138 101.393 89.2556C101.393 97.631 94.8802 104.368 86.9102 104.368ZM26.322 129.149V89.1908L70.5014 88.8013C70.7042 88.7995 70.9163 88.9814 70.9163 89.2556C70.9163 98.4074 78.0485 105.879 86.9102 105.879C95.7719 105.879 102.904 98.4074 102.904 89.2556C102.904 88.9814 103.116 88.7994 103.319 88.8012L147.889 89.1909V129.149C147.889 130.294 146.961 131.221 145.817 131.221H28.3939C27.2496 131.221 26.322 130.294 26.322 129.149Z'
        stroke='#D4D4D8'
        strokeWidth='1.51123'
      />
    </svg>
  )
}
