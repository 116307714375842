import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import parse from 'html-react-parser'
import { IInternalNote, IStore } from '../../types/storeTypes'
import { IconNotesFilled } from '../../images/IconNotesFilled'
import { IconNotes } from '../../images/IconNotes'
import { useTranslation } from 'react-i18next'
import { IconArrows } from '../../images/IconArrows'
import moment from 'moment'
import { IconEdit } from '../../images/IconEdit'
import { IconDelete } from '../../images/IconDelete'
import { useOfferNotes } from '../../hooks/useOfferNotes'
import { Loader } from '../loader'
import { DefaultButton } from '../defaultButton'
import { useFormSession } from '../../hooks/useFormSession'
import { Tooltip } from 'react-tooltip'

interface Props {
  opened: boolean
  onClose: () => void
}

const emptyNote = {
  text: '',
}

export const EditNoteBlock: React.FC<Props> = ({ opened, onClose }) => {
  const { t, i18n } = useTranslation()
  const offerInfo = useSelector((store: IStore) => store.offerInfo)
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const { internal_notes } = offerInfo
  const [newNote, setNewNote] = useState<{ text: string } | null>(null)
  const [editNote, setEditNote] = useState<IInternalNote | null>(null)
  const { createOfferNote, createNoteLoading, deleteOfferNote, deleteNoteLoading, editOfferNote, editNoteLoading } =
    useOfferNotes()
  const { saveSession, saveSessionLoading } = useFormSession()
  const tooltipStyle = 'max-w-[350px] z-10 text-sm font-normal'
  const tooltipDelay = 400

  const offerId = useMemo(
    () => offerInfo.id || appointmentInfo.intermediate_save?.offerId,
    [appointmentInfo.intermediate_save, offerInfo.id]
  )

  const notes = useMemo(() => {
    return offerId ? internal_notes : appointmentInfo.intermediate_save?.internal_notes || []
  }, [offerId, internal_notes, appointmentInfo.intermediate_save])

  useEffect(() => {
    if (!notes.length) setNewNote(emptyNote)
    else setNewNote(null)
  }, [notes, opened])

  useEffect(() => {
    const body = document.getElementById('page_body')
    if (body && opened) body.style.overflow = 'hidden'
    if (body && !opened) body.style.overflow = 'auto'
  }, [opened])

  const onNoteDelete = (note: IInternalNote) => {
    if (offerId) {
      deleteOfferNote(note)
    } else {
      const oldNotes = appointmentInfo.intermediate_save?.internal_notes
        ? appointmentInfo.intermediate_save.internal_notes
        : []

      saveSession({ internal_notes: oldNotes.filter((oldNote) => oldNote.id !== note.id) })
    }
  }

  const onNoteEdit = (note: IInternalNote) => {
    setNewNote(null)
    setEditNote(note)
  }

  const onNewNoteSubmit = () => {
    if (newNote && newNote.text) {
      if (offerId) {
        createOfferNote(newNote.text, () => setNewNote(null))
      } else {
        const oldNotes = appointmentInfo.intermediate_save?.internal_notes
          ? appointmentInfo.intermediate_save.internal_notes
          : []
        const note = {
          id: oldNotes.length + 1,
          user_name: '',
          note: newNote.text,
          type: 'Internal',
          external_types: [],
          created_at: moment().toISOString(),
          can_modify: true,
        }
        saveSession({ internal_notes: [note, ...oldNotes] })
      }
    }
  }

  const onEditNoteSubmit = async () => {
    if (editNote) {
      if (offerId) {
        editOfferNote(editNote, () => setEditNote(null))
      } else {
        const oldNotes = appointmentInfo.intermediate_save?.internal_notes
          ? appointmentInfo.intermediate_save.internal_notes
          : []

        let newNotes = [...oldNotes]
        const index = newNotes.findIndex((note) => note.id === editNote.id)
        if (index >= 0 && newNotes[index]) {
          const newNote = { ...newNotes[index] }
          newNote.note = editNote.note
          newNotes[index] = newNote
          await saveSession({ internal_notes: newNotes })
          setEditNote(null)
        }
      }
    }
  }

  const onCloseBar = () => {
    onClose()
    setNewNote(null)
  }

  const onCancel = () => {
    onCloseBar()
  }

  return (
    <>
      <div
        className={`absolute top-0 left-0 h-screen w-screen bg-black bg-opacity-50 z-10 ${opened ? 'block' : 'hidden'}`}
      ></div>
      <div
        className={`absolute top-0 right-0 h-screen z-20 bg-white max-w-[540px] overflow-hidden transition-[width] duration-500 ${opened ? ' w-screen' : 'w-0'}`}
      >
        <div className='py-[27px] pl-6 pr-6 md:pl-6 md:pr-16'>
          <div className='flex justify-between items-center gap-8 pb-5'>
            <div className='flex justify-between items-center gap-2 text-[28px] leading-9 text-zinc-800 font-medium'>
              <button
                type='button'
                className='px-2 group disabled:pointer-events-none'
                onClick={onCloseBar}
                disabled={createNoteLoading || saveSessionLoading}
              >
                <IconArrows classname='text-zinc-500 group-hover:text-zinc-700' />
              </button>
              {t('Internal Notes')}
            </div>
            <button
              type='button'
              className='px-2 group disabled:pointer-events-none'
              onClick={() => setNewNote(emptyNote)}
              disabled={createNoteLoading || saveSessionLoading}
              data-tooltip-id='button-add_new_note'
            >
              {notes.length > 0 ? (
                <IconNotesFilled classname='text-zinc-500 group-hover:text-zinc-700' />
              ) : (
                <IconNotes classname='text-zinc-500 group-hover:text-zinc-700' />
              )}
            </button>
            <Tooltip id='button-add_new_note' className={tooltipStyle} delayShow={tooltipDelay}>
              {t('Add new note')}
            </Tooltip>
          </div>

          <div className=' max-h-[80vh] overflow-y-auto custom_scroll pr-2'>
            {newNote && (
              <div className='flex flex-col gap-4 mb-4'>
                <textarea
                  className='form-textarea !w-full'
                  placeholder={t('Enter text')}
                  name='textarea-new_note'
                  id='textarea-new_note'
                  cols={30}
                  rows={10}
                  value={newNote?.text || ''}
                  onChange={(e) => setNewNote({ text: e.target.value })}
                />

                <div className='flex gap-4 justify-end'>
                  <button type='button' className='text_button !text-red-400' onClick={onCancel}>
                    {t('Cancel')}
                  </button>
                  <DefaultButton
                    title={t('Submit')}
                    classname='main_button'
                    disabled={!newNote || !newNote.text || createNoteLoading || saveSessionLoading}
                    onClick={onNewNoteSubmit}
                    loading={createNoteLoading}
                  />
                </div>
              </div>
            )}
            {notes.map((note) => {
              return (
                <div
                  key={`internal_note-${note.id}`}
                  className='flex flex-col gap-1 border-t border-zinc-300 mb-6 relative'
                >
                  {editNote && editNote.id === note.id ? (
                    <>
                      <div className='flex flex-col gap-4 my-2'>
                        <textarea
                          className='form-textarea !w-full'
                          placeholder={t('Enter text')}
                          name='textarea-edit_note'
                          id='textarea-edit_note'
                          cols={30}
                          rows={10}
                          value={editNote.note}
                          onChange={(e) => setEditNote({ ...editNote, note: e.target.value })}
                        />

                        <div className='flex gap-4 justify-end'>
                          <button type='button' className='text_button !text-red-400' onClick={() => setEditNote(null)}>
                            {t('Cancel')}
                          </button>
                          <DefaultButton
                            title={t('Submit')}
                            classname='main_button'
                            disabled={!editNote || !editNote.note || editNoteLoading.value}
                            onClick={onEditNoteSubmit}
                            loading={editNoteLoading.value}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {deleteNoteLoading.value && deleteNoteLoading.id === note.id && (
                        <div className='absolute inset-0 w-full h-full bg-zinc-300 bg-opacity-20 flex justify-center items-center pointer-events-none'>
                          <Loader />
                        </div>
                      )}
                      <div className='text-sm text-zinc-500 mt-6 capitalize'>
                        {i18n.language === 'es'
                          ? moment(note.created_at).locale('es').format('MMMM DD, YYYY HH:mm')
                          : moment(note.created_at).format('MMMM DD, YYYY hh:mm A')}
                        {note.user_name ? (
                          <span className='transform-none pl-1'>{`${t('by')} ${note.user_name}`}</span>
                        ) : null}
                      </div>

                      <div className='text-base text-zinc-800 [&>a]:text-blue-500 [&>a]:hover:text-blue-700'>
                        {parse(note.note)}
                      </div>

                      {note.can_modify && (
                        <div className='flex justify-end'>
                          <button
                            type='button'
                            className='px-2 py-2 group disabled:pointer-events-none'
                            onClick={() => onNoteEdit(note)}
                          >
                            <IconEdit classname='text-zinc-500 group-hover:text-zinc-700' />
                          </button>
                          <button
                            type='button'
                            className='px-2 py-2 group disabled:pointer-events-none'
                            onClick={() => onNoteDelete(note)}
                          >
                            <IconDelete classname='text-zinc-500 group-hover:text-zinc-700' />
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
