import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { IRelatedPerson, TReactSelectOption } from '../../types/additionalTypes'
import { useTranslation } from 'react-i18next'
import { useRelatedClient } from '../../hooks/useRelatedClient'
import { defaultClient } from '../../utils/defaultClient'

interface Props {
  value: IRelatedPerson
  onChange: (value: IRelatedPerson) => void
  selectId?: string
  inputId?: string
  containerStyles: string
  controlFocusedStyles: string
  controlDefaultStyles: string
  inputStyles: string
  dropdownIndicatorStyles?: string
}

export const RelatedPersonSelect: React.FC<Props> = ({
  selectId,
  inputId,
  value,
  onChange,
  containerStyles,
  controlFocusedStyles,
  controlDefaultStyles,
  inputStyles,
  dropdownIndicatorStyles,
}) => {
  const [selectOptions, setSelectOptions] = useState<TReactSelectOption[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const { t } = useTranslation()
  const { getRelatedCLients, searchLoading } = useRelatedClient()

  const loadOptions = async (value: string) => {
    let options: TReactSelectOption[] = []
    await getRelatedCLients(value, (data) => {
      if (data) {
        options = data.map((option) => {
          return {
            label: `${option.full_name ? option.full_name + ' ' : ''}<${option.email}>`,
            id: option.id || 0,
            value: option,
          }
        })
        setSelectOptions(options)
      }
    })
  }

  useEffect(() => {
    let timer: any = null
    clearTimeout(timer)
    if (searchValue) {
      setSelectOptions([])
      timer = setTimeout(() => {
        loadOptions(searchValue)
      }, 1000)
    }

    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const defaultValue = useMemo(() => {
    return value && value.id
      ? ({
          label: `${value.full_name ? value.full_name + ' ' : ''}<${value.email}>`,
          id: value.id,
          value: value,
        } as TReactSelectOption)
      : undefined
  }, [value])

  return (
    <Select
      id={selectId}
      inputId={inputId}
      value={defaultValue}
      placeholder={`${t('Select')}...`}
      classNames={{
        container: () => containerStyles,
        control: (state) => (state.isFocused ? controlFocusedStyles : controlDefaultStyles),
        input: () => inputStyles,
        dropdownIndicator: () => dropdownIndicatorStyles || '',
        valueContainer: () => '!pt-0',
      }}
      options={selectOptions}
      isLoading={searchLoading}
      onChange={(e) => {
        if (e) onChange(e.value as IRelatedPerson)
        else onChange(defaultClient)
        setSelectOptions([])
      }}
      isClearable
      inputValue={searchValue}
      onInputChange={(inputValue) => {
        setSearchValue(inputValue)
      }}
    />
  )
}
