import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types/storeTypes'

const initialState: IStore['paymentInfoFromMobile'] = {
  uploaded: false,
  data: undefined,
  token: '',
}

export const paymentInfoFromMobileSlice = createSlice({
  name: 'paymentInfoFromMobile',
  initialState,
  reducers: {
    setPaymentInfoFromMobile: (state, action) => {
      return { ...state, ...action.payload }
    },
    removePaymentInfoFromMobile: () => {
      return initialState
    },
  },
})

export const { setPaymentInfoFromMobile, removePaymentInfoFromMobile } = paymentInfoFromMobileSlice.actions

export default paymentInfoFromMobileSlice.reducer
