import React from 'react'

interface Props {
  value: string
  name: string
  id: string
  styles: string
  onEdit: (value: string) => void
  maxLength?: number
  error?: boolean
  bgColor?: string
}

export const DefaultTextarea: React.FC<Props> = ({
  value,
  name,
  id,
  styles,
  maxLength,
  onEdit,
  error = false,
  bgColor,
}) => {
  const background = bgColor ? bgColor : 'bg-white'
  return (
    <div
      className={`relative w-full ${background} rounded border  ${error ? 'border-red-500 focus-within:border-red-600' : 'border-zinc-300 focus-within:border-zinc-400'}`}
    >
      <textarea
        value={value}
        name={name}
        id={id}
        className={`${styles ? styles : 'form-textarea'} !border-b-0 !rounded-b-none !border-none focus:!border-none`}
        onChange={(e) => onEdit(e?.target.value || '')}
        maxLength={maxLength}
      />
      {maxLength && (
        <div className={`mt-[-6px] text-xs text-end ${value.length === maxLength ? 'text-red-500' : 'text-zinc-400'} `}>
          <div className='pr-5 pb-1'>{`${value.length} / ${maxLength}`}</div>
        </div>
      )}
    </div>
  )
}
