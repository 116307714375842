import { useState } from 'react'
import { callApi } from '../utils/callApi';
import { ApiRoutes } from '../utils/apiRoutes';
import { useDispatch } from 'react-redux';
import { showGlobalPopup } from '../store/globalPopupSlice';

export const useCheckDiamondReport = () => {
  const dispatch = useDispatch()
  const [checkReportLoading, setCheckReportLoading] = useState(false)

  const checkReport = async (name: string, number: string) => {
    setCheckReportLoading(true)
    try {
      const resp = await callApi({
        method: 'GET',
        url: `${ApiRoutes.checkReport}`,
        params: { cert: name, number }
      })
      
      if (resp.data && resp.data.data) return resp.data.data
      
    } catch (error) {
      console.log(error, 'error data');
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setCheckReportLoading(false)
    }
  }

  return {
    checkReportLoading,
    checkReport,
  }
}
