import { useTranslation } from 'react-i18next'
import { ApiRoutes } from '../utils/apiRoutes'
import { callApi } from '../utils/callApi'
import { useDispatch, useSelector } from 'react-redux'
import { setClientInfo } from '../store/clientInfoSlice'
import { updateDictsInfo } from '../store/dictsSlice'
import { setAppointmentInfo, setIntermediateSave } from '../store/appointmentInfoSlice'
import { useState } from 'react'
import { useFormSession } from './useFormSession'
import { showGlobalPopup } from '../store/globalPopupSlice'
import { setOfferDictsInfo } from '../store/offerDictsSLice'
import { setOfferInfo } from '../store/offerInfoSlice'
import { IIntermediateSave, IStore } from '../types/storeTypes'
import { setCurrentStep } from '../store/currentStepSlice'
import { setPaymentInfo } from '../store/paymentInfoSlice'
import { setAgreementsInfo } from '../store/agreementsSlice'
import { setConfirmationData } from '../store/confirmationDataSlice'
import { useOfferNotes } from './useOfferNotes'
import { IOfferItem } from '../types/offerTypes'
import { DECLINED, INELIGIBLE } from '../utils/itemStatuses'
import { setSessionLoadingError } from '../store/sessionLoadingErrorSlice'
import { setShowAgreementsWarn } from '../store/showAgreementsWarnSlice'

export const useGetStepData = () => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const [stepsLoading, setStepsLoading] = useState<number[]>([])
  const [sessionLoading, setSessionLoading] = useState(false)
  const { startCheckSession, stopCheckSession } = useFormSession()
  const { getOfferNotes } = useOfferNotes()
  const offerDicts = useSelector((store: IStore) => store.offerDicts)
  const currentStep = useSelector((store: IStore) => store.currentStep)
  const offerInfo = useSelector((store: IStore) => store.offerInfo)
  const paymentInfo = useSelector((store: IStore) => store.paymentInfo)
  const agreements = useSelector((store: IStore) => store.agreements)
  const client = useSelector((store: IStore) => store.client)

  const changeCurrentStep = (step: number, data?: IIntermediateSave, ignoreAgreements = false) => {
    const oldStep = currentStep
    if (oldStep === 6) return
    if (
      oldStep > 3 &&
      step <= 3 &&
      (!!agreements.purchase_form || !!agreements.consignment_agreement) &&
      !ignoreAgreements
    ) {
      dispatch(setShowAgreementsWarn({ showed: true, nextStep: step }))
      return
    }
    if (oldStep > 3 && step <= 3 && !offerInfo.offerItems.length) getThirdStepData()
    if (step === 5 && !paymentInfo.paymentInfoUploaded) getPaymentStepData()
    if ([2, 4, 5].includes(step) && data !== undefined && data.offerId) getOfferNotes()
    if ([4, 5].includes(step) && !agreements.agreements_uploaded) getSignatureStepAgreements()
    if ([4, 5].includes(step) && !offerInfo.offerItems.length) getThirdStepData()
    if ([1, 4, 5].includes(step) && !client.id) getStartData()
    dispatch(setCurrentStep(step))
  }

  const getSessionData = async (cb: (step: number, data?: IIntermediateSave) => void) => {
    dispatch(setSessionLoadingError(false))
    setSessionLoading(true)
    try {
      const resp: any = await callApi({
        method: 'GET',
        url: `${ApiRoutes.intermediateSave}?lang=${i18n.language}`,
      })

      if (resp && resp.data) {
        const data = resp.data

        dispatch(setIntermediateSave(data))
        const nextStep = data && data.currentStep ? data.currentStep : 1
        if (nextStep) cb(nextStep, data)
      } else if (resp.status === 200) cb(1)
    } catch (error) {
      console.log(error, 'error data')
      dispatch(setSessionLoadingError(true))
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setSessionLoading(false)
    }
  }

  const getStartData = async (cb?: (data: any) => void) => {
    const stepNumber = 1
    stopCheckSession()
    setStepsLoading([...stepsLoading, stepNumber])
    try {
      const resp: any = await callApi({
        method: 'GET',
        url: `${ApiRoutes.formStart}?lang=${i18n.language}`,
      })

      if (resp && resp.data) {
        if (cb) cb(resp.data)
        if (resp.data.appointment && resp.data.appointment.client) {
          dispatch(setClientInfo(resp.data.appointment.client))
        }
        if (resp.data.dict) {
          dispatch(updateDictsInfo(resp.data.dict))
        }
        dispatch(
          setAppointmentInfo({
            buyer_id: resp.data.buyer_id,
            intermediate_save: resp.data.intermediate_save,
            appointment: resp.data.appointment,
            related_persons: resp.data.related_persons,
          })
        )
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setStepsLoading(stepsLoading.filter((step) => step !== stepNumber))
      startCheckSession()
    }
  }

  const getSecondStepData = async (loadOfferData = true) => {
    const stepNumber = 2
    stopCheckSession()
    setStepsLoading([...stepsLoading, stepNumber])
    try {
      const resp: any = await callApi({
        method: 'GET',
        url: `${ApiRoutes.secondStep}?lang=${i18n.language}`,
      })

      if (resp && resp.data) {
        if (resp.data.offer_id && !offerInfo.offerItems.length && loadOfferData) await getThirdStepData(false)
        dispatch(setOfferDictsInfo({ offerDictsData: resp.data, language: i18n.language }))
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setStepsLoading(stepsLoading.filter((step) => step !== stepNumber))
      startCheckSession()
    }
  }

  const getThirdStepData = async (loadSecondData = true) => {
    const stepNumber = 3
    stopCheckSession()
    setStepsLoading([...stepsLoading, stepNumber])
    if (!offerDicts.offerDictsUploaded && loadSecondData) await getSecondStepData(false)
    try {
      const resp: any = await callApi({
        method: 'GET',
        url: `${ApiRoutes.thirdStep}?lang=${i18n.language}`,
      })

      if (resp && resp.data && resp.data.data) {
        const data = resp.data.data
        let items = data.items as IOfferItem[]
        const statusData = sessionStorage.getItem('3_step_items')
        if (statusData) {
          const oldItems = JSON.parse(statusData) as IOfferItem[]
          items = items.map((newItem) => {
            const oldItem = oldItems.find((item) => item.id === newItem.id)
            if (oldItem) {
              return {
                ...newItem,
                status: oldItem.status,
                client_expectation: oldItem.client_expectation,
                target_amount: oldItem.target_amount,
                reason_decline: oldItem.reason_decline,
                offer_made:
                  oldItem.status && [DECLINED, INELIGIBLE].includes(oldItem.status)
                    ? oldItem.offer_made
                    : newItem.offer_made,
              }
            } else {
              return newItem
            }
          })
        }
        dispatch(
          setOfferInfo({
            ...data,
            offerItems: items,
            offerInfoUploaded: true,
          })
        )
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setStepsLoading(stepsLoading.filter((step) => step !== stepNumber))
      startCheckSession()
      sessionStorage.removeItem('3_step_items')
    }
  }

  const getPaymentStepData = async () => {
    const stepNumber = 5
    stopCheckSession()
    setStepsLoading([...stepsLoading, stepNumber])
    try {
      const resp: any = await callApi({
        method: 'GET',
        url: `${ApiRoutes.fourthStep}?lang=${i18n.language}`,
      })

      if (resp && resp.data) {
        const data = resp.data

        if (data.client) {
          dispatch(setClientInfo(data.client))
        }
        if (data.dict) {
          dispatch(updateDictsInfo(data.dict))
        }
        dispatch(
          setPaymentInfo({
            paymentInfoUploaded: true,
            amounts: data.amounts,
            buyer_id: data.buyer_id,
            payment_data: data.payment_data,
            turnover_id: data.turnover_id,
          })
        )
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setStepsLoading(stepsLoading.filter((step) => step !== stepNumber))
      startCheckSession()
    }
  }

  const getSignatureStepAgreements = async () => {
    const stepNumber = 4
    stopCheckSession()
    setStepsLoading([...stepsLoading, stepNumber])
    try {
      const resp: any = await callApi({
        method: 'GET',
        url: `${ApiRoutes.agreements}?lang=${i18n.language}`,
      })

      if (resp && resp.data) {
        const data = resp.data
        if (data) {
          dispatch(setAgreementsInfo({ ...data, agreements_uploaded: true }))
        }
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setStepsLoading(stepsLoading.filter((step) => step !== stepNumber))
      startCheckSession()
    }
  }

  const getSixthStepData = async (external_note?: string) => {
    const stepNumber = 6
    stopCheckSession()
    setStepsLoading([...stepsLoading, stepNumber])
    try {
      const resp: any = await callApi({
        method: 'GET',
        url: `${ApiRoutes.sixthStep}?lang=${i18n.language}${external_note ? `&external_note=${external_note}` : ''}`,
      })

      if (resp && resp.data) {
        const data = resp.data
        dispatch(setConfirmationData(data))
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setStepsLoading(stepsLoading.filter((step) => step !== stepNumber))
    }
  }

  return {
    changeCurrentStep,
    getStartData,
    stepsLoading,
    sessionLoading,
    getSecondStepData,
    getThirdStepData,
    getPaymentStepData,
    getSignatureStepAgreements,
    getSixthStepData,
    getSessionData,
  }
}
