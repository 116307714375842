import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types/storeTypes'

const initialState: IStore['closeFormModal'] | null = false

export const closeFormModalSlice = createSlice({
  name: 'closeFormModal',
  initialState,
  reducers: {
    showCloseFormModal: () => true,
    hideCloseFormModal: () => false,
  },
})

export const { showCloseFormModal, hideCloseFormModal } = closeFormModalSlice.actions

export default closeFormModalSlice.reducer
