import React from 'react'
import { useTranslation } from 'react-i18next'

export const FormClosed = () => {
  const { t } = useTranslation()
  return (
    <div className=' w-full h-[80vh] flex justify-center items-center'>
      <div className='text-zinc-700 font-serif font-medium text-3xl'>{t('Purchase form closed')}</div>
    </div>
  )
}
