export const ApiRoutes = {
  formStart: '/purchase-form/start',
  firsStepSave: '/purchase-form/client',
  lockForm: '/purchase-form/lock',
  unlockForm: '/purchase-form/unlock',
  saveForm: '/purchase-form/save-tmp',
  secondStep: '/purchase-form/offer',
  checkReport: '/v1/cert-info',
  thirdStep: '/purchase-form/decision',
  internalNote: '/purchase-form/internal-note',
  print: '/purchase-form/print',
  printItems: '/purchase-form/print-items',
  fourthStep: '/purchase-form/payment',
  agreements: '/purchase-form/agreements',
  upload: '/purchase-form/upload',
  formFinish: '/purchase-form/finish',
  sixthStep: '/purchase-form/confirmation',
  email: '/purchase-form/email',
  paymentQR: '/purchase-form/payment/qr',
  plaidData: '/purchase-form/plaid/data',
  plaidToken: '/purchase-form/plaid/access-token',
  intermediateSave: '/purchase-form/intermediate-save',
  printContract: '/purchase-form/contract',
  gdpr: '/purchase-form/gdpr',

  relatedClient: '/purchase-form/related-client',
  relatedClientSearch: '/purchase-form/related-client/search',
  relatedClientGdpr: '/purchase-form/related-client/gdpr',
  relatedClientBind: '/purchase-form/related-client/bind',
}
