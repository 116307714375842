import React from 'react'

export const IconEmail = ({ classname = 'text-zinc-500' }) => {
  return (
    <svg
      className={`${classname}`}
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.5 15C3.0875 15 2.73438 14.8531 2.44063 14.5594C2.14687 14.2656 2 13.9125 2 13.5V4.5C2 4.0875 2.14687 3.73438 2.44063 3.44063C2.73438 3.14687 3.0875 3 3.5 3H15.5C15.9125 3 16.2656 3.14687 16.5594 3.44063C16.8531 3.73438 17 4.0875 17 4.5V13.5C17 13.9125 16.8531 14.2656 16.5594 14.5594C16.2656 14.8531 15.9125 15 15.5 15H3.5ZM15.5 6L9.89375 9.50625C9.83125 9.54375 9.76563 9.57188 9.69688 9.59063C9.62813 9.60938 9.5625 9.61875 9.5 9.61875C9.4375 9.61875 9.37188 9.60938 9.30313 9.59063C9.23438 9.57188 9.16875 9.54375 9.10625 9.50625L3.5 6V13.5H15.5V6ZM9.5 8.25L15.5 4.5H3.5L9.5 8.25ZM3.5 6.1875V5.08125V5.1V5.09063V6.1875Z'
        fill='currentColor'
      />
    </svg>
  )
}
