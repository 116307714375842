import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types/storeTypes'

const initialState: IStore['paymentInfo'] | null = {
  amounts: null,
  buyer_id: 0,
  payment_data: null,
  turnover_id: 0,
  paymentInfoUploaded: false,
}

export const paymentInfoSlice = createSlice({
  name: 'paymentInfo',
  initialState,
  reducers: {
    setPaymentInfo: (state, action) => {
      return action.payload
    },
    setTurnoverId: (state, action) => {
      return { ...state, turnover_id: action.payload }
    },
    setGlobalPaymentData: (state, action) => {
      return { ...state, payment_data: action.payload }
    },
  },
})

export const { setPaymentInfo, setTurnoverId, setGlobalPaymentData } = paymentInfoSlice.actions

export default paymentInfoSlice.reducer
