import React from 'react'

export const IconNotesFilled = ({ classname = 'text-zinc-500', secondColor = '#3B82F6' }) => {
  return (
    <svg
      className={`${classname}`}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 18L3.7 20.3C3.38333 20.6167 3.02083 20.6875 2.6125 20.5125C2.20417 20.3375 2 20.025 2 19.575V4C2 3.45 2.19583 2.97917 2.5875 2.5875C2.97917 2.19583 3.45 2 4 2H13C13.2833 2 13.5208 2.09583 13.7125 2.2875C13.9042 2.47917 14 2.71667 14 3C14 3.28333 13.9042 3.52083 13.7125 3.7125C13.5208 3.90417 13.2833 4 13 4H4V17.125L5.15 16H20V9C20 8.71667 20.0958 8.47917 20.2875 8.2875C20.4792 8.09583 20.7167 8 21 8C21.2833 8 21.5208 8.09583 21.7125 8.2875C21.9042 8.47917 22 8.71667 22 9V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H6Z'
        fill='currentColor'
      />
      <path
        d='M13 14H7C6.71667 14 6.47917 13.9042 6.2875 13.7125C6.09583 13.5208 6 13.2833 6 13C6 12.7167 6.09583 12.4792 6.2875 12.2875C6.47917 12.0958 6.71667 12 7 12H13C13.2833 12 13.5208 12.0958 13.7125 12.2875C13.9042 12.4792 14 12.7167 14 13C14 13.2833 13.9042 13.5208 13.7125 13.7125C13.5208 13.9042 13.2833 14 13 14Z'
        fill={secondColor}
      />
      <path
        d='M17 11H7C6.71667 11 6.47917 10.9042 6.2875 10.7125C6.09583 10.5208 6 10.2833 6 10C6 9.71667 6.09583 9.47917 6.2875 9.2875C6.47917 9.09583 6.71667 9 7 9H17C17.2833 9 17.5208 9.09583 17.7125 9.2875C17.9042 9.47917 18 9.71667 18 10C18 10.2833 17.9042 10.5208 17.7125 10.7125C17.5208 10.9042 17.2833 11 17 11Z'
        fill={secondColor}
      />
      <path
        d='M15 8H7C6.71667 8 6.47917 7.90417 6.2875 7.7125C6.09583 7.52083 6 7.28333 6 7C6 6.71667 6.09583 6.47917 6.2875 6.2875C6.47917 6.09583 6.71667 6 7 6H15C15.2833 6 15.5208 6.09583 15.7125 6.2875C15.9042 6.47917 16 6.71667 16 7C16 7.28333 15.9042 7.52083 15.7125 7.7125C15.5208 7.90417 15.2833 8 15 8Z'
        fill={secondColor}
      />
      <path
        d='M16.875 5.125C17.4583 5.70833 18.1667 6 19 6C19.8333 6 20.5417 5.70833 21.125 5.125C21.7083 4.54167 22 3.83333 22 3C22 2.16667 21.7083 1.45833 21.125 0.875C20.5417 0.291667 19.8333 0 19 0C18.1667 0 17.4583 0.291667 16.875 0.875C16.2917 1.45833 16 2.16667 16 3C16 3.83333 16.2917 4.54167 16.875 5.125Z'
        fill={secondColor}
      />
    </svg>
  )
}
