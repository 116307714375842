export const hideSymbols = (value: string, showSymbols: number) => {
  let res = ''

  for (let i = 0; i < value.length; i++) {
    if (value.length - i < showSymbols + 1) res += value[i]
    else res += '*'
  }

  return res
}
